import { useState, useEffect, Fragment, useContext } from 'react';

import {
  Drawer,
  Input,
  Button,
  Uploader,
  Grid,
  Row,
  Col,
  toaster,
  Message,
  Loader,
  IconButton,
  Whisper,
  Tooltip,
  Checkbox,
  Form,
  ButtonToolbar,
  Table,
  Modal,
} from 'rsuite';

import { ApplicationContext, ResponsiveTable } from 'shared';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient, gql } from '@apollo/client';
import startCase from 'lodash/startCase';
import loadImage from 'blueimp-load-image';
import { isMobileOnly } from 'react-device-detect';
import { FORMAT, ROLE } from 'lib/env';
import axios from 'axios';
import { IS_ASSINIBOINE } from 'lib/tenant';
import useApi from 'lib/api';
import { useLogger } from 'lib/logger';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import ResponsiveNav from '@rsuite/responsive-nav';
import IconButtonWrapper from 'shared/IconButtonWrapper';
import { format, parseISO } from 'date-fns';
import store from 'lib/storage';
import { MdAdd, MdCameraAlt, MdDelete, MdDownload, MdRefresh, MdSearch } from 'react-icons/md';
import Icon from '@rsuite/icons/lib/Icon';

interface IPhotoUploader {
  show: boolean,
  title: string,
  jobGuid: string,
  emails: string[],
  handleHideDrawer: () => void,
  onChange: (data: any) => void
}

interface IPhoto {
  guid: string,
  photoBefore: string,
  photoAfter: string,
  descriptionBefore: string,
  descriptionAfter: string,
  fileSizeBefore: number,
  fileSizeAfter: number
}

interface IPhotoUploadComponent {
  guid: string,
  jobGuid: string,
  photo: IPhoto,
  onPhotoChange: (photo: IPhoto) => void,
  onPhotoPreview: (photo: string | undefined) => void
}

const { Column, HeaderCell, Cell } = Table;

const GET_EMAIL_ACTIVITY = gql`
  query emailActivities($limit: Int!, $offset: Int!, $where: EmailActivitiesWhere, $sort: EmailActivitiesSort) {
    emailActivities(filter: {
      limit: $limit,
      offset: $offset,
      where: $where,
      sort: $sort
    }) {
      edges {
        node {
          guid
          msgId
          customerId
          email
          event
          subject
          createdAt
          displayName
          userId
          reference
          referenceGuid
        }
      },
      totalCount
    }
  }
`;

const GET_EMAIL_DETAIL_ACTIVITY = gql`
  query emailActivity($guid: ID!) {
    emailActivity(guid: $guid) {
      guid
      msgId
      customerId
      email
      event
      subject
      createdAt
      displayName
      userId
      reference
      referenceGuid
      resourceHistory
    }
  }
`;

const orientationMatrix = [1, 6, 3, 8];

const PhotoUploadComponent = ({
  guid,
  jobGuid,
  photo,
  onPhotoChange,
  onPhotoPreview
}: IPhotoUploadComponent) => {
  const logger = useLogger();
  const [rowPhoto, setRowPhoto] = useState<any>({});
  const { showMessage } = useContext(ApplicationContext);
  const [loading, setLoading] = useState<Array<string>>([]);
  const api = useApi();

  useEffect(() => {
    setRowPhoto(photo);
  }, [photo]);

  useEffect(() => {
    const dragPhoto = (e: any) => {
      if (e && (e as any).target.className !== 'rs-uploader-trigger-btn') {
        e.preventDefault();
        e.stopPropagation();
        (e as any).dataTransfer.effectAllowed = 'none';
        (e as any).dataTransfer.dropEffect = 'none';
      }
    };

    const dropPhoto = (e: any) => {
      if (e && (e as any).target.className !== 'rs-uploader-trigger-btn') {
        e.preventDefault();
        e.stopPropagation();
        (e as any).dataTransfer.effectAllowed = 'none';
        (e as any).dataTransfer.dropEffect = 'none';
      }
    };

    window.addEventListener('dragover', dragPhoto);
    window.addEventListener('drop', dropPhoto);

    return () => {
      window.removeEventListener('dragover', dragPhoto);
      window.removeEventListener('drop', dropPhoto);
    }
  }, []);

  useEffect(() => {
    onPhotoChange(rowPhoto);
  }, [rowPhoto])

  const handlePhotoAdd = async (guid: string, photo: any, state: string) => {
    const old: Array<string> = ([] as Array<string>).concat(loading);
    const key = guid + state;
    old.push(key);
    setLoading(old);

    try {
      const file: any = await readFile(photo.blobFile, state);
      const result = await api.post(`/photos/job/${jobGuid}`, { ...file, guid, state });

      if (result.success) {
        toaster.push(<Message type="success" showIcon closable>{result.message}</Message>);
        file[`photo${startCase(state)}`] = result.result.photoUrl;
        setRowPhoto(file);
      } else {
        toaster.push(<Message type="error" showIcon closable>{result.message}</Message>);
      }
    } catch (err: any) {
      let formData = new FormData();
      formData.append('file', photo.blobFile, photo.name);
      formData.append('jobGuid', jobGuid);
      formData.append('error', err.toString());

      const response: any = await axios({ method: 'post', url: api.baseUrl + '/photos/error', data: formData, headers: { 'Content-Type': 'multipart/form-data' } });
      showMessage(`Media type cannot be processed, ${response.data.filename}: ${err.toString()}`, 'Invalid Media Format');
      logger.error(err);
    }

    const tmp: Array<string> = ([] as Array<string>).concat(loading);
    tmp.splice(tmp.indexOf(key), 1);
    setLoading([]);
  };

  const handlePhotoRotate = async (guid: string, state: string) => {
    const photoKey = 'photo' + startCase(state);
    const orientationKey = 'orientation' + startCase(state);
    const photo: any = { ...rowPhoto };

    const old: Array<string> = ([] as Array<string>).concat(loading);
    const key = guid + state;
    old.push(key);
    setLoading(old);

    if (photo[photoKey]) {
      let file = photo[photoKey];

      if (photo[photoKey].indexOf('https://') > -1) {
        file = await api.postJpgBlob({ photo: photo[photoKey] });
        photo[orientationKey] = 1;
      }

      const fileType = photo['fileType' + startCase(state)] || 'image/jpeg';
      const orientationIndex = orientationMatrix.indexOf(photo[orientationKey]);
      const newOrientationIndex = (orientationMatrix[orientationIndex + 1]) ? orientationMatrix[orientationIndex + 1] : 0;
      const data: any = await loadImage(file, { meta: true, maxWidth: 1000, maxHeight: 1000, canvas: true, orientation: newOrientationIndex });

      const result = await api.post(`/photos/job/${jobGuid}`, {
        ...data,
        [photoKey]: data.image.toDataURL(fileType).toString(),
        jobGuid,
        guid,
        state
      });

      if (result.success) {
        photo[`photo${startCase(state)}`] = result.result.photoUrl;
        setRowPhoto(photo);
        toaster.push(<Message type="success" showIcon closable>{result.message}</Message>);
      } else {
        toaster.push(<Message type="error" showIcon closable>{result.message}</Message>);
      }
    }

    const tmp: Array<string> = ([] as Array<string>).concat(loading);
    tmp.splice(tmp.indexOf(key), 1);
    setLoading([]);
  }

  const readFile = async (file: any, state: string) => {
    if (file.type.toLowerCase() === 'image/heic') {
      let formData = new FormData();
      formData.append('file', file);
      file = await api.postConvertHeic(formData);
    }

    const data: any = await loadImage(file, { meta: true, maxWidth: 1000, maxHeight: 1000, canvas: true, orientation: true });

    if (data.image && typeof (data.image.toDataURL) === 'function') {
      return {
        ...rowPhoto,
        type: file.type,
        name: file.name,
        [`photo${startCase(state)}`]: data.image.toDataURL(file.type).toString(),
        [`fileSize${startCase(state)}`]: file.size,
        [`fileType${startCase(state)}`]: file.type,
        [`file${startCase(state)}`]: file,
        [`orientation${startCase(state)}`]: 1,
      }
    } else {
      throw Error('Media type cannot be processed');
    }
  }

  return (
    <Row>
      <Col md={12}>
        <p className="pl-10 pr-10">
          Before<span> </span>
          <Whisper placement="bottom" speaker={<Tooltip>Rotate</Tooltip>}>
            <IconButton appearance="link" disabled={!rowPhoto.photoBefore} icon={<MdRefresh />}
              onClick={() => handlePhotoRotate(rowPhoto.guid, 'before')} />
          </Whisper>
          <Whisper placement="bottom" speaker={<Tooltip>Preview</Tooltip>}>
            <IconButton disabled={!rowPhoto.photoBefore} appearance="link" icon={<MdSearch />} onClick={() => {
              onPhotoPreview(rowPhoto.photoBefore);
            }} />
          </Whisper>
          <Whisper placement="bottom" speaker={<Tooltip>Download</Tooltip>}>
            <IconButton disabled={!rowPhoto.photoBefore} appearance="link" icon={<MdDownload />} onClick={() => {
              window.location.href = `${api.baseUrl}/photos/${rowPhoto.guid}/before`;
            }} />
          </Whisper>
        </p>
        <div>
          <Uploader
            // rsuite5
            action='/'
            classPrefix={'job-photo-uploader'}
            multiple
            autoUpload={false}
            draggable={!isMobileOnly}
            listType="picture"
            accept="image/*"
            onChange={(val: any) => handlePhotoAdd(rowPhoto.guid, val[val.length - 1], 'before')}
            fileListVisible={false}
          >
            <button
              style={{
                backgroundImage: rowPhoto.photoBefore ? `url(${rowPhoto.photoBefore})` : 'none',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                margin: 0
              }}>
              {loading.indexOf(photo.guid + 'before') > -1
                ? <Loader />
                : <MdCameraAlt
                  style={{
                    fontSize: "1.3333em"
                  }} />
              }
            </button>
          </Uploader>
        </div>
        <Input
          style={{ width: '100%', minWidth: '100%', marginTop: '5px' }}
          placeholder='Description'
          value={rowPhoto.descriptionBefore || ''}
          onChange={(val) => setRowPhoto({ ...rowPhoto, descriptionBefore: val })}
        />
      </Col>
      <Col md={12}>
        <p className="pl-10 pr-10">
          After<span> </span>
          <Whisper placement="bottom" speaker={<Tooltip>Rotate</Tooltip>}>
            <IconButton appearance="link" disabled={!rowPhoto.photoAfter} icon={<MdRefresh />} onClick={() => handlePhotoRotate(rowPhoto.guid, 'after')} />
          </Whisper>
          <Whisper placement="bottom" speaker={<Tooltip>Preview</Tooltip>}>
            <IconButton disabled={!rowPhoto.photoAfter} appearance="link" icon={<MdSearch />} onClick={() => {
              onPhotoPreview(rowPhoto.photoAfter);
            }} />
          </Whisper>
          <Whisper placement="bottom" speaker={<Tooltip>Download</Tooltip>}>
            <IconButton disabled={!rowPhoto.photoAfter} appearance="link" icon={<MdDownload />} onClick={() => {
              window.location.href = `${api.baseUrl}/photos/${rowPhoto.guid}/after`;
            }} />
          </Whisper>
        </p>
        <div>
          <Uploader
            // rsuite5
            action='/'
            classPrefix={'job-photo-uploader'}
            multiple
            autoUpload={false}
            draggable={!isMobileOnly}
            listType="picture"
            accept="image/*"
            onChange={(val: any) => handlePhotoAdd(rowPhoto.guid, val[val.length - 1], 'after')}
            fileListVisible={false}
          >
            <button
              style={{
                backgroundImage: rowPhoto.photoAfter ? `url(${rowPhoto.photoAfter})` : 'none',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                margin: 0
              }}>
              {loading.indexOf(photo.guid + 'after') > -1
                ? <Loader />
                : <MdCameraAlt
                  style={{
                    fontSize: "1.3333em"
                  }} />
              }
            </button>
          </Uploader>
        </div>
        <Input
          style={{ width: '100%', minWidth: '100%', marginTop: '5px' }}
          placeholder='Description'
          value={rowPhoto.descriptionAfter || ''}
          onChange={(val) => setRowPhoto({ ...rowPhoto, descriptionAfter: val })}
        />
      </Col>
    </Row>
  );
};

const defaultPhoto = {
  guid: '',
  photoBefore: '',
  photoAfter: '',
  descriptionBefore: '',
  descriptionAfter: '',
  fileSizeBefore: 0,
  fileSizeAfter: 0
};

const PhotoUploader = ({
  show,
  title,
  jobGuid,
  emails,
  handleHideDrawer,
  onChange
}: IPhotoUploader) => {
  const client = useApolloClient();
  const api = useApi();
  const { isRole } = usePrairieAuth();
  const { showError, showConfirmation, showMessage } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<any>('send');
  const [saving, setSaving] = useState(false);
  const [photoPreview, setPhotoPreview] = useState<string | undefined>(undefined);
  const [photos, setPhotos] = useState<IPhoto[]>([{ ...defaultPhoto, guid: uuidv4() }]);
  const [photoModifiedAt, setPhotoModifiedAt] = useState(new Date().toISOString());
  const [selectedEmails, setSelectedEmails] = useState<string[]>(emails.filter((e) => e.trim().length));
  const [hasOther, setHasOther] = useState(false);
  const [otherEmails, setOtherEmails] = useState('');
  const [templateKey, setTemplateKey] = useState<string | undefined>(undefined)
  const [emailActivity, setEmailActivity] = useState<Array<any> | undefined>(undefined);
  const [lookupGuid, setLookupGuid] = useState<string | undefined>(undefined);
  const [emailOriginal, setEmailOriginal] = useState<any>(undefined);

  useEffect(() => {
    setActiveTab('send');
    setSelectedEmails(emails.filter((e: string) => e.trim().length > 0));
    setOtherEmails('');
    setHasOther(false);

    for (var i = 0, len = localStorage.length; i < len; ++i) {
      if (localStorage.key(i) && (localStorage.key(i) || '').indexOf(`continuous-snowfall-${new Date().toISOString().substring(0, 10)}`) > -1) {
        setTemplateKey('work_order_photos_continuous_snowfall');
      }
    }
  }, [emails]);

  useEffect(() => {
    (async function getWorkOrders() {
      if (jobGuid) {
        try {
          setLoading(true);
          const data = await client.query({
            query: gql`
              query job($guid: ID) {
                job(guid: $guid) {
                  guid,
                  photos {
                    guid,
                    photoBefore,
                    photoAfter,
                    descriptionBefore,
                    descriptionAfter
                  }
                }
              }
            `,
            variables: {
              guid: jobGuid
            },
            fetchPolicy: 'no-cache'
          });

          const history = await client.query({
            query: GET_EMAIL_ACTIVITY,
            variables: {
              offset: 0,
              limit: 50,
              where: {
                AND: [
                  { referenceGuid: { is: jobGuid } }
                ]
              },
              sort: {
                createdAt: 'DESC'
              }
            },
            fetchPolicy: 'no-cache'
          });

          setEmailActivity(history.data.emailActivities.edges.node);

          if (data.data.job.photos.length === 0) {
            setPhotos([{ ...defaultPhoto, guid: uuidv4() }]);
          } else {
            setPhotos(data.data.job.photos);
          }
        } catch (err) {
          console.log(err);
        }

        setLoading(false)
      }
    })();
  }, [jobGuid]);

  const onPhotoAdd = () => {
    setPhotos(photos.concat({ ...defaultPhoto, guid: uuidv4() }));
  }

  const onPhotoRemove = (guid: string) => {
    showConfirmation('Are you sure you want to remove this photo?', 'Remove photo', async () => {
      const response = await client.mutate({
        mutation: gql`mutation deletePhoto($guid: ID!) { deletePhoto(guid: $guid) { success, code, message }}`,
        variables: { guid }
      });

      const newPhotos = [...photos];
      const removeIndex = newPhotos.findIndex((p) => p.guid === guid);
      newPhotos.splice(removeIndex, 1);

      if (newPhotos.length === 0) {
        newPhotos.push({ ...defaultPhoto, guid: uuidv4() });
      }

      setPhotos(newPhotos);
    });
  }

  const onPhotoChange = (photo: IPhoto) => {
    const newPhotos = photos.map((p: any) => (p.guid === photo.guid) ? photo : p);
    setPhotos(newPhotos);
  }

  const handleSubmit = async (email: boolean = false) => {
    setSaving(true);
    let emailResult: any;
    const result = await api.post(`/photos/job/${jobGuid}`, {
      photos,
      selectedEmails,
      otherEmails: (hasOther ? otherEmails.split(',') : [])
    });

    if (email) {
      emailResult = await api.post(`/emails/send/photos/${jobGuid}`, {
        selectedEmails,
        otherEmails: (hasOther ? otherEmails.split(',').map((e: string) => e.trim()) : []),
        guid: jobGuid,
        templateKey
      });
    }

    if (result.success) {
      onChange(result);
      toaster.push(
        <Message type="success" showIcon closable>{emailResult?.message || result.message}</Message>
      );
      handleHideDrawer();
    } else {
      showError(result);
    }
    setSaving(false);
  }

  const handleEmailSelect = (val: any) => {
    if (selectedEmails.includes(val)) {
      const tmp = [...selectedEmails];
      tmp.splice(selectedEmails.indexOf(val), 1);
      setSelectedEmails(tmp);
    } else {
      setSelectedEmails([...selectedEmails, val]);
    }
  }

  const getOriginal = async (guid: string) => {
    const data: any = await client.query({
      query: gql`query emailActivity($guid: ID) { emailActivity(guid: $guid) {
        message
        payload
        resourceHistory
      } }`,
      variables: {
        guid
      },
      fetchPolicy: 'no-cache'
    });

    if ((data.data.emailActivity?.resourceHistory?.events || []).length > 0) {
      const message = data.data.emailActivity?.message;
      setEmailOriginal({ ...message, messageId: guid });
    }
  }

  return (
    <Drawer
      open={show}
      onClose={handleHideDrawer}
      size={'sm'}
      full={isMobileOnly}
    >
      <Drawer.Header>
        <Drawer.Title>{`${title} - Upload Photos`}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="scrollable">
        {loading
          ? <Loader content="Loading..." />
          : <Fragment>
            {!IS_ASSINIBOINE &&
              <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab}>
                <ResponsiveNav.Item eventKey="send">Send</ResponsiveNav.Item>
                <ResponsiveNav.Item eventKey="history">History</ResponsiveNav.Item>
              </ResponsiveNav>
            }

            {activeTab === 'send' &&
              <>
                {!IS_ASSINIBOINE &&
                  <div className="mb-6 mt-6">
                    <h6>Email Template:</h6>
                    <Checkbox
                      value={'work_order_photos_continuous_snowfall'}
                      onChange={(val: any) => {
                        for (var i = 0, len = localStorage.length; i < len; ++i) {
                          if (localStorage.key(i) && (localStorage.key(i) || '').indexOf('continuous-snowfall') > -1) {
                            store.remove(localStorage.key(i) || '');
                          }
                        }

                        if (templateKey === undefined) {
                          store.set(`continuous-snowfall-${new Date().toISOString().substring(0, 10)}`, true);
                        }

                        setTemplateKey(templateKey === undefined ? val : undefined)
                      }} defaultChecked={templateKey !== undefined}> Work Order Photos - Continuous Snowfall</Checkbox>
                  </div>
                }

                {(isRole(ROLE.MANAGER, ROLE.ADMIN) && !IS_ASSINIBOINE) &&
                  <div className="mb-6">
                    <h6>Select recipients:</h6>
                    {emails.filter((e) => e.trim().length).map((e: string, index: number) =>
                      <div key={`email-${index}`}>
                        <Checkbox value={e} onChange={handleEmailSelect} defaultChecked={selectedEmails.includes(e)}> {e}</Checkbox>
                      </div>
                    )}

                    <div>
                      <Checkbox onChange={() => setHasOther(!hasOther)}> Other:</Checkbox>
                      {hasOther &&
                        <div>
                          <Input type="text" onChange={setOtherEmails} value={otherEmails} />
                          <Form.HelpText>Separate multiple emails via comma</Form.HelpText>
                        </div>
                      }
                    </div>
                  </div>
                }
                <div key={photoModifiedAt}>
                  <h6 className='mb-8'>Add photos:</h6>
                  {photos.map((photo) =>
                    <div
                      className='job-photo-group mb-12'
                      key={`${photo.guid}-${photo.fileSizeAfter}-${photo.fileSizeBefore}`}>
                      <Grid fluid>
                        <PhotoUploadComponent
                          guid={photo.guid}
                          jobGuid={jobGuid}
                          photo={photo}
                          onPhotoChange={onPhotoChange}
                          onPhotoPreview={(data: string | undefined) => setPhotoPreview(data)}
                        />
                        <Row>
                          <Col md={24}>
                            <ul className='list-inline mt-10' style={{ marginBottom: 0 }}>
                              <li>
                                <IconButton size="xs" appearance="link" onClick={() => onPhotoAdd()} icon={<Icon as={MdAdd} />}>Add</IconButton>
                              </li>
                              <li>
                                <IconButton size="xs" appearance="link" onClick={() => onPhotoRemove(photo.guid)} icon={<Icon as={MdDelete} />}>Remove</IconButton>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Grid>
                    </div>
                  )}
                </div>
              </>
            }

            {(activeTab === 'history') &&
              <div>
                {loading
                  ? <Loader content="Loading..." />
                  : <Fragment>
                    {(!emailActivity || emailActivity.length === 0)
                      ? <p>No email activity found. Older emails do not have this feature.</p>
                      : <ResponsiveTable
                        data={emailActivity}
                        autoHeight
                        height={40}
                        wordWrap={false}
                        loading={loading}>
                        <Column flexGrow={1}>
                          <HeaderCell>Email</HeaderCell>
                          <Cell dataKey="email" />
                        </Column>
                        <Column flexGrow={1}>
                          <HeaderCell>Subject</HeaderCell>
                          <Cell>{(row: any) => startCase(row.event) + ' - ' + startCase(row.subject)}</Cell>
                        </Column>
                        <Column flexGrow={1}>
                          <HeaderCell>Date</HeaderCell>
                          <Cell>{(row: any) => format(parseISO(row.createdAt), FORMAT.SHORT_DAY_MONTH_TIME)}</Cell>
                        </Column>
                        <Column width={85}>
                          <HeaderCell>&nbsp;</HeaderCell>
                          <Cell className="link-group text-right">
                            {(row: any) => <><Whisper placement="bottom" trigger="hover" speaker={<Tooltip>View</Tooltip>}>
                              <IconButtonWrapper
                                onClick={async () => {
                                  setLookupGuid(row.guid);
                                  const data = await client.query({
                                    query: GET_EMAIL_DETAIL_ACTIVITY,
                                    variables: {
                                      guid: row.guid
                                    },
                                    fetchPolicy: 'no-cache'
                                  });

                                  if ((data.data.emailActivity?.resourceHistory?.events || []).length > 0) {
                                    const message = JSON.parse(data.data.emailActivity?.resourceHistory?.events[0].message);
                                    showMessage(<div dangerouslySetInnerHTML={{ __html: message.html }} />, 'Email Preview', 'lg');
                                  } else {
                                    showMessage(<div>Preview unavailable. This feature is available on emails sent after Nov 3rd.</div>, 'Email Preview');
                                  }
                                  setLookupGuid(undefined);
                                }}
                                appearance="link"
                                icon={'view'}
                              />
                            </Whisper>
                              <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Original</Tooltip>}>
                                <IconButtonWrapper
                                  as={Button}
                                  onClick={() => getOriginal(row.guid)}
                                  appearance="link"
                                  icon={'edit'}
                                />
                              </Whisper>
                            </>}
                          </Cell>
                        </Column>
                      </ResponsiveTable>
                    }
                  </Fragment>
                }
              </div>
            }

            <Modal open={photoPreview !== undefined}>
              <Modal.Body style={{ marginTop: 0 }}>
                <img src={photoPreview} style={{ width: '100%', minHeight: '270px' }} />
              </Modal.Body>
              <Modal.Footer>
                <Button appearance='default' onClick={() => setPhotoPreview(undefined)}>Close</Button>
              </Modal.Footer>
            </Modal>

            {emailOriginal &&
              <Modal open onClose={() => setEmailOriginal(undefined)} size={"md"} full={isMobileOnly}>
                <Modal.Header>
                  <Modal.Title>Email Log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <pre>
                    {JSON.stringify(emailOriginal, null, 2)}
                  </pre>
                </Modal.Body>
                <Modal.Footer>
                  <Button appearance='primary' onClick={async () => {
                    const response = await api.get(`/emails/delete-supression-destination/${emailOriginal.messageId}`);
                    if (response.success) {
                      toaster.push(<Message type="success" showIcon closable>{response.message}</Message>);
                    } else {
                      toaster.push(<Message type="error" showIcon closable>{response.message}</Message>);
                    }
                  }}>Remove From Supression List</Button>
                  <Button onClick={() => setEmailOriginal(undefined)} appearance="subtle">Close</Button>
                </Modal.Footer>
              </Modal>
            }
          </Fragment>
        }
      </Drawer.Body>
      <Drawer.Footer>
        <ButtonToolbar>
          <Button size="sm" onClick={() => handleSubmit(false)} appearance="primary" loading={saving}>Save</Button>
          <Button size="sm" onClick={() => handleSubmit(true)} appearance="primary" loading={saving}>Email Photos</Button>
          <Button size="sm" onClick={handleHideDrawer} appearance="subtle" loading={saving}>Close</Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </Drawer>
  );
}

export default PhotoUploader;
