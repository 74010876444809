import { useEffect, useState } from 'react';
import { Form, Grid, Row, Col, CheckPicker, SelectPicker, DatePicker } from 'rsuite';
import { FORMAT, getEnv, ROLE } from 'lib/env';
import { addDays, addMinutes, addMonths, endOfMonth, format, isAfter, isBefore, parseISO } from 'date-fns';
import { workOrders as model } from 'lib/validations';
import INTL from 'tenant/intl';
import AvailabilityList from '../AvailabilityList';
import objectHash from 'object-hash';
import { addHours, intervalToDuration } from 'date-fns';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';

interface IWorkOrderFormFields {
  container: any,
  drawer: boolean | undefined,
  formValue: any,
  formIndex: string,
  applicationGroup: string,
  workOrderBookAs: string,
  customer: any,
  onJobAdd: () => void,
  onJobRemove: (val: any) => void,
  onJobDuplicate: (val: any) => void,
  onJobChange: (guid: string, job: string) => void,
  onJobUpdate: (id: string, data: any) => void,
  handleSetRef: (index: string, ref: any) => void
}

let timePickerData: any = [];

while (timePickerData.length < 48) {
  if (timePickerData.length === 0) {
    timePickerData.push({
      date: new Date(new Date().setHours(0, 0, 0, 0)),
      value: format(new Date(new Date().setHours(0, 0, 0, 0)), 'h:mmaaa'),
      label: format(new Date(new Date().setHours(0, 0, 0, 0)), 'h:mmaaa'),
    });
  } else {
    timePickerData.push({
      date: addMinutes(timePickerData[timePickerData.length - 1].date, 30),
      value: format(addMinutes(timePickerData[timePickerData.length - 1].date, 30), 'h:mmaaa'),
      label: format(addMinutes(timePickerData[timePickerData.length - 1].date, 30), 'h:mmaaa')
    });
  }
}

const WorkOrderFields = ({
  customer,
  container,
  formValue,
  formIndex,
  applicationGroup,
  handleSetRef,
  onJobUpdate,
}: IWorkOrderFormFields) => {
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const workTypes = filterFieldsByParentKey(state.fields, 'work_types');
  const [defaultFormValue, setDefaultFormValue] = useState(formValue);
  const [defaultFormError, setDefaultFormError] = useState<any>({});
  const leaseEndDate = customer?.props?.customLeaseType === 'end_date'
    ? parseISO(customer?.props?.customLeaseEndsAt)
    : endOfMonth(addMonths(new Date(), 1));

  useEffect(() => {
    onJobUpdate(formIndex, defaultFormValue);
  }, [defaultFormValue]);

  // key={objectHash(defaultFormValue)}
      
  return (
    <Form
      ref={(ref: any) => handleSetRef(formIndex, ref)}
      onChange={setDefaultFormValue}
      onCheck={setDefaultFormError}
      formError={defaultFormError}
      formValue={defaultFormValue}
      model={model[applicationGroup] ? model[applicationGroup] : model.default}
    >
      <Grid fluid>
        {getEnv() === 'dev' &&
          <Row className="mb-12">
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>UUID:</Form.ControlLabel>
                <p>{defaultFormValue?.guid}</p>
              </Form.Group>
            </Col>
          </Row>
        }

        <Row>
          <Col md={3} xs={12}>
            {/* {JSON.stringify(defaultFormValue.start_end_date[0])} */}
            <Form.ControlLabel className="required">Start Date:</Form.ControlLabel>
            <Form.Control
              name="startDate"
              accepter={DatePicker}
              container={() => container && container.current}
              cleanable={false}
              oneTap
              block
              ranges={[]}
              renderValue={(date: any) => format(date, FORMAT.DAY_MONTH_DATE)}
              value={defaultFormValue.startEndDate[0]}
              shouldDisableDate={(date: any) => customer?.props?.customLeaseType && isRole(ROLE.CLIENT, ROLE.CLEANER) && isAfter(date, leaseEndDate)}
              onChange={(val: any) => {
                const startDate = new Date(new Date(+val));
                const endDate = startDate >= defaultFormValue.startEndDate[0] ? new Date(addMinutes(new Date(+startDate), 60)) : defaultFormValue.startEndDate[1];
                setDefaultFormValue({ ...defaultFormValue, startEndDate: [val, endDate], modifiedStartEndDate: new Date().toISOString() })
              }} />

          </Col>
          <Col md={3} lg={2} xs={12}>
            <Form.ControlLabel className="required">Time:</Form.ControlLabel>
            <Form.Control
              name="startDateTime"
              accepter={SelectPicker}
              searchable={false}
              cleanable={false}
              container={() => container && container.current}
              block
              data={timePickerData}
              value={format(new Date(defaultFormValue.startEndDate[0]), 'h:mmaaa')}
              onChange={(value: any) => {
                let date = timePickerData.find((t: any) => t.value === value).date;
                const startDate = new Date(new Date(+defaultFormValue.startEndDate[0]).setHours(date.getHours(), date.getMinutes(), 0, 0));
                const endDate = startDate >= defaultFormValue.startEndDate[0] ? new Date(addMinutes(new Date(+startDate), 60)) : defaultFormValue.startEndDate[1];

                setDefaultFormValue({ ...defaultFormValue, startEndDate: [startDate, endDate] });
              }} />
          </Col>

          <Col md={1} xsHidden style={{ padding: 0, textAlign: 'center', paddingTop: '32px' }}>to</Col>

          <Col md={2} lg={2} xs={12}>
            <Form.ControlLabel className="required">End Time:</Form.ControlLabel>
            <Form.Control
              name="endDateTime"
              accepter={SelectPicker}
              searchable={false}
              cleanable={false}
              container={() => container && container.current}
              block
              data={timePickerData}
              value={format(defaultFormValue.startEndDate[1], 'h:mmaaa')}
              errorMessage={defaultFormError.startEndDate}
              onChange={(value: any) => {
                let date = timePickerData.find((t: any) => t.value === value).date;
                setDefaultFormValue({
                  ...defaultFormValue,
                  startEndDate: [
                    defaultFormValue.startEndDate[0],
                    new Date(new Date(+defaultFormValue.startEndDate[1]).setHours(date.getHours(), date.getMinutes(), 0, 0))
                  ]
                });
              }} />
          </Col>
          <Col md={3} xs={12}>
            {/* {JSON.stringify(defaultFormValue.start_end_date[1])} */}
            <Form.ControlLabel className="required">Date:</Form.ControlLabel>
            <Form.Control
              name="endDate"
              accepter={DatePicker}
              container={() => container && container.current}
              cleanable={false}
              oneTap
              block
              renderValue={(date: any) => format(date, FORMAT.DAY_MONTH_DATE)}
              ranges={[]}
              value={defaultFormValue.startEndDate[1]}
              shouldDisableDate={(date: any) => customer?.props?.customLeaseType && isRole(ROLE.CLIENT, ROLE.CLEANER) && (isAfter(date, leaseEndDate) || isAfter(date, addDays(defaultFormValue.startEndDate[0], 2)))}
              onChange={(val: any) => {
                setDefaultFormValue({
                  ...defaultFormValue,
                  modifiedStartEndDate: new Date().toISOString(),
                  startEndDate: [defaultFormValue.startEndDate[0], val],
                  endingDate: val
                })
              }} />
          </Col>

          <Col md={5} xs={24}>
            {/* Job Type */}
            <Form.ControlLabel className="required">{INTL.WORK_TYPE}:</Form.ControlLabel>
            <Form.Control
              container={() => container && container.current}
              name="workType"
              accepter={SelectPicker}
              block
              searchable={false}
              data={workTypes.filter((o: any) => o.applicationGroup === applicationGroup && o.visibleOnBooking)
                .filter((o: any) => isRole(ROLE.ADMIN, ROLE.MANAGER) ? true : !o.key.includes('overbooked'))
                .map((o: any) => ({
                  value: o.key,
                  label: o.title + ' ' + ((o.defaults || []).length > 0 ? '(+' + (o.defaults || []).length + ' Others)' : '')
                }))
              } />
            <Form.HelpText>Scroll below for map of kitchens</Form.HelpText>
          </Col>

          <Col md={5}>
            <Form.ControlLabel>Recurrence:</Form.ControlLabel>
            <div>
              <Form.Control
                name="recurrence"
                accepter={SelectPicker}
                block
                container={() => container && container.current}
                searchable={false}
                cleanable={false}
                data={[
                  { label: 'One Time', value: 'onetime' },
                  { label: 'Daily', value: 'daily' },
                  { label: 'Custom', value: 'weekly' }
                ]} />
            </div>

            {(defaultFormValue && ['weekly', 'biweekly'].includes(defaultFormValue.recurrence)) &&
              <div className="mt-5">
                <Form.ControlLabel>Repeat On:</Form.ControlLabel>
                <div>
                  <Form.Control
                    name="daysWorking"
                    accepter={CheckPicker}
                    block
                    container={() => container && container.current}
                    searchable={false}
                    cleanable={false}
                    data={[
                      { label: 'Mon', value: 'mon' },
                      { label: 'Tue', value: 'tue' },
                      { label: 'Wed', value: 'wed' },
                      { label: 'Thu', value: 'thu' },
                      { label: 'Fri', value: 'fri' },
                      { label: 'Sat', value: 'sat' },
                      { label: 'Sun', value: 'sun' }
                    ]} />
                </div>
              </div>
            }
          </Col>

          {defaultFormValue.recurrence !== 'onetime' &&
            <Col md={3}>
              <Form.ControlLabel>Ending:</Form.ControlLabel>
              <Form.Control
                name="endingDate"
                placement="bottomEnd"
                accepter={DatePicker}
                container={() => container && container.current}
                oneTap
                block
                ranges={[]}
                renderValue={(date: any) => format(date, FORMAT.MONTH_DATE)}
                shouldDisableDate={(date: any) => (isBefore(date, defaultFormValue.startEndDate[1]) || (customer?.props?.customLeaseType && isRole(ROLE.CLIENT, ROLE.CLEANER) && isAfter(date, leaseEndDate))) } />
            </Col>
          }
        </Row>

        <Row>
          <Col md={24} sm={24}>
            {/* {(defaultFormValue.startEndDate && defaultFormValue.endingDate) && <>
              {JSON.stringify(intervalToDuration({ 
                start: typeof (defaultFormValue.startEndDate[0]) === 'string' ? new Date(defaultFormValue.startEndDate[0]) : defaultFormValue.startEndDate[0], 
                end: typeof (defaultFormValue.endingDate) === 'string' ? new Date(defaultFormValue.endingDate) : defaultFormValue.endingDate 
              }))}
            </>
            } */}

            {/* {JSON.stringify(defaultFormValue)} */}

            <AvailabilityList
              recurrence={defaultFormValue.recurrence}
              applicationGroup={applicationGroup}
              days={defaultFormValue.daysWorking}
              times={timePickerData}
              startEndDate={defaultFormValue.startEndDate}
              workType={defaultFormValue.workType}
              endingDate={defaultFormValue.endingDate ? defaultFormValue.endingDate : addDays(defaultFormValue.startEndDate[0], 1)}
              rooms={workTypes.filter((o: any) => o.applicationGroup === applicationGroup && o.visibleOnBooking)}
              onSelect={(data: any) => {
                setDefaultFormValue({ ...defaultFormValue, startEndDate: [data.time, addHours(data.time, 1)], workType: data.room });
              }}
            />
          </Col>
          <Form.HelpText>&nbsp;&nbsp;Select dates and times for your booking to toggle availability</Form.HelpText>
        </Row>
      </Grid>
    </Form >
  );
}

export {
  WorkOrderFields
}
