import { gql, useApolloClient, useQuery } from '@apollo/client';
import startCase from 'lodash/startCase';
import { cloneElement, useEffect, useRef, useState, } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import {
  Form,
  Panel,
  Grid,
  Row,
  Col,
  SelectPicker,
  ButtonToolbar,
  Button,
  CheckPicker,
  toaster,
  Message,
  DatePicker,
  Table,
  Schema,
  Input,
  Checkbox,
  CheckboxGroup,
  InputPicker,
} from 'rsuite';
import { v4 as uuidv4 } from 'uuid';
import CustomerFormContactDetailLineItem from './components/CustomerFormContactDetailLineItem';
import { QUERY_CUSTOMERS, queryCustomer, queryCustomers } from '../../gql/customer';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { FORMAT, getEnv, hasQuickBooksDesktopIntegration, hasXeroIntegration, ROLE } from 'lib/env';
import AttachmentForm from 'components/attachment/AttachmentForm';
import CustomerFormAddressDetailLineItem from './components/CustomerFormAddressDetailLineItem';
import { CONFIG_APPLICATION_FIELDS } from 'tenant/config';
import { IS_ASSINIBOINE, IS_BUGABOO, IS_GREYROCK, IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { parseISO } from 'lib/date';
import ResponsiveNav from '@rsuite/responsive-nav';
import INTL from 'tenant/intl';
import { ApplicationContext, DrawerFormWrapper, ResponsiveTable } from 'shared';
import { format } from 'date-fns';
import { orderBy, pick } from 'lodash';
import { useContext } from 'react';
import update from 'immutability-helper';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import FormGroup from 'rsuite/esm/FormGroup';
import { getCustomFields, setCustomFields } from 'lib/form';
import { arrayRange } from 'lib/helpers/customer';
import { getFilter } from 'lib/helpers/filter';

const model = Schema.Model({
  customerName: Schema.Types.StringType().isRequired('This field is required')
});

const SortableItem = SortableElement((props: any) =>
  <Grid fluid>
    <CustomerFormContactDetailLineItem {...props} />
  </Grid>
);

const SortableList = SortableContainer((children: any) => {
  return <div>{children.children}</div>;
});

const { Column, HeaderCell, Cell } = Table;
const initialContactDetails = { guid: uuidv4(), name: '', email: '', phone: '', ext: '', primary: true, sortOrder: 0, status: [] };
const initialBillingDetails = { guid: uuidv4(), updated: true, dataKey: '', maskedPan: '', expMonth: '', expYear: '', cvd: '', notes: '', primary: true };
const initialAddressDetails = { guid: uuidv4(), unit: '', address: '', city: '', province: 'Alberta', postalCode: '', notes: '', primary: true, label: 'site' };

interface ICustomerForm {
  action?: string,
  guid?: string,
  customerId?: number,
  show?: boolean,
  drawer?: boolean,
  startEndDate?: [Date, Date] | undefined,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const CustomerForm = ({
  guid,
  customerId,
  action,
  show,
  drawer,
  onHide,
  onUpdate
}: ICustomerForm) => {
  let form: any = useRef();
  const history = useHistory();
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isRole } = usePrairieAuth();
  const { state } = useViewport();
  const params: any = queryString.parse(useLocation().search);
  const { showError, showNotice } = useContext(ApplicationContext);
  const [formValue, setFormValue] = useState<any>({
    guid: uuidv4(),
    status: [],
    customerName: '',
    displayName: '',
    propertyManager: '',
    comments: '',
    label: '',
    customerAddress: '',
    workAddress: '',
    city: '',
    province: 'Alberta',
    postalCode: '',
    contactDetails: [{ ...initialContactDetails, guid: uuidv4() }],
    billingDetails: [{ ...initialBillingDetails, guid: uuidv4() }],
    addressDetails: [{ ...initialAddressDetails, guid: uuidv4() }],
    attachments: [],
    parentId: (['add', 'clone', 'copy'].includes(action || match?.params?.action) && customerId) ? customerId : null,
    customLeaseHours: 0,
    customLeaseExtraHours: 0,
    customLeaseType: '',
    password: '',
    rePassword: '',
    username: '',
    accessGroup: [],
    quickbooksCode: '',
    xeroCustomerId: ''
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(new Date().toISOString());
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [customer, setCustomer] = useState<any>(undefined);
  const [activeTab, setActiveTab] = useState<any>('form');
  const [customerSearchQuery, setCustomerSearchQuery] = useState();
  const searchCustomers = useQuery(QUERY_CUSTOMERS, {
    variables: {
      offset: 0,
      limit: 10,
      where: {
        AND: [getFilter('customers', { textFilter: customerSearchQuery }), { parentId: { is: null } }]
      }
    }
  });

  const workGroups = filterFieldsByParentKey(state.fields, 'work_groups');

  guid = match.params.guid || params?.edit || guid;
  action = match?.params?.action || action || (params?.edit && 'edit') || 'add';

  useEffect(() => {
    if ((action === 'edit' || action === 'clone' || action === 'copy') && guid) {
      (async function getCustomer() {
        setLoading(true);
        const data = await client.query({ query: queryCustomer, variables: { guid } });
        setCustomer(data.data.customer);

        if (data.data.customer) {
          const status = [];
          const nextContactDetails: any = [];
          const nextBillingDetails: any = [];
          const nextAddressDetails: any = [];
          const { doNotContact, doNotService, isActive, contactDetails, addressDetails, email, billing, attachments, customerAddress } = data.data.customer;

          if (doNotContact) {
            status.push('do_not_contact');
          }

          if (doNotService) {
            status.push('do_not_service');
          }

          if (+isActive === 0) {
            status.push('inactive');
          }

          if (!contactDetails || contactDetails.length === 0) {
            let emails = [];

            if (email) {
              emails = email.split(';');
              nextContactDetails.push({
                ...initialContactDetails,
                name: !data.data.customer.businessName ? 'Default' : data.data.customer.customerName,
                guid: uuidv4(),
                phone: data.data.customer.phone,
                email: emails.length > 0 ? emails[0] : '',
                status: []
              });
            } else {
              nextContactDetails.push({
                ...initialContactDetails,
                name: !data.data.customer.businessName ? 'Default' : data.data.customer.customerName,
                guid: uuidv4(),
                phone: data.data.customer.phone,
                status: []
              });
            }

            if (data.data.customer.alternatePhone) {
              nextContactDetails.push({
                ...initialContactDetails,
                guid: uuidv4(),
                name: 'Alternate',
                phone: data.data.customer.alternatePhone.trim(),
                primary: false,
                status: []
              });
            }

            if (data.data.customer.faxNumber) {
              nextContactDetails.push({
                ...initialContactDetails,
                guid: uuidv4(),
                name: 'Fax number',
                phone: data.data.customer.faxNumber.trim(),
                primary: false,
                status: []
              });
            }

            if (emails.length > 1) {
              emails.forEach((email: string, index: number) => {
                if (index > 0 && email.replace(/\s+/g, '').length > 0) {
                  nextContactDetails.push({
                    ...initialContactDetails,
                    guid: uuidv4(),
                    email,
                    primary: false,
                    status: []
                  });
                }
              })
            }
          }

          if (billing && billing.length > 0) {
            orderBy(billing, 'createdAt', 'asc').forEach((b: any) => {
              nextBillingDetails.push({
                id: b.id,
                guid: b.guid,
                dataKey: b.dataKey,
                maskedPan: b.maskedPan,
                expMonth: b.expMonth,
                expYear: b.expYear,
                cvd: b.cvd,
                notes: b.notes,
                primary: b.isDefault,
                updated: false
              });
            });
          } else {
            nextBillingDetails.push({ ...initialBillingDetails, guid: uuidv4() });
          }

          // use regular customer address as site or otherwise work address where present. all bugaboo will end up as customer_address
          nextAddressDetails.push({ label: 'site', guid: uuidv4(), primary: false, address: customerAddress, city: data.data.customer.city, province: data.data.customer.province, postalCode: data.data.customer.postalCode, unit: data.data.customer.addressUnit });

          if (IS_GREYROCK || IS_ASSINIBOINE) {
            nextAddressDetails.push({ label: 'work', guid: uuidv4(), primary: false, address: data.data.customer.workAddress, city: data.data.customer.workCity, province: data.data.customer.workProvince, postalCode: data.data.customer.workPostalCode, unit: data.data.customer.addressUnit });
          }

          setFormValue({
            ...formValue,
            ...setCustomFields(data.data.customer?.customFields || {}),
            guid: (action === 'clone' || action === 'copy') ? uuidv4() : data.data.customer.guid,
            status,
            customerName: data.data.customer.displayName,
            quickbooksCode: data.data.customer.quickbooksCode,
            propertyManager: data.data.customer.propertyManager || '',
            label: data.data.customer.label,
            comments: data.data.customer.comments,
            contactDetails: nextContactDetails.length > 0 ? nextContactDetails : contactDetails,
            billingDetails: nextBillingDetails,
            addressDetails: nextAddressDetails.length > 0 ? nextAddressDetails : addressDetails,
            attachments,
            parentId: (action === 'clone' || action === 'copy') ? customerId : data.data.customer?.parentId,
            customerId: (action === 'clone' || action === 'copy') ? null : data.data.customer.id,
            customLeaseHours: data.data.customer.props?.customLeaseHours || 0,
            customLeaseExtraHours: data.data.customer.props?.customLeaseExtraHours || 0,
            customLeaseEndsAt: data.data.customer.props?.customLeaseEndsAt ? parseISO(data.data.customer.props?.customLeaseEndsAt) : undefined,
            customLeaseType: data.data.customer.props?.customLeaseType ? data.data.customer.props?.customLeaseType : undefined,
            username: data.data.customer.user ? data.data.customer.user.username : '',
            accessGroup: data.data.customer.user?.accessGroup || [],
            squareFootage: data.data.customer?.squareFootage || '',
            xeroCustomerId: data.data.customer.xeroCustomerId || ''
          });
        }

        setLoading(false);
        setCustomerSearchQuery(data.data.customer?.parent?.displayName);
      })();
    } else {
      if (!IS_BUGABOO && !IS_KALADI_KITCHENS) {
        const nextFormValue = JSON.parse(JSON.stringify(formValue));
        nextFormValue.addressDetails.push({ ...initialAddressDetails, label: 'work', guid: uuidv4() });
        setFormValue({
          ...nextFormValue,
          guid: uuidv4(),
          parentId: (action || match?.params?.action === 'add' && customerId) ? customerId : null
        });
      } else {
        setFormValue({
          guid: uuidv4(),
          status: [],
          customerName: '',
          displayName: '',
          propertyManager: '',
          comments: '',
          label: '',
          customerAddress: '',
          workAddress: '',
          city: '',
          province: 'Alberta',
          postalCode: '',
          contactDetails: [{ ...initialContactDetails, guid: uuidv4() }],
          billingDetails: [{ ...initialBillingDetails, guid: uuidv4() }],
          addressDetails: [{ ...initialAddressDetails, guid: uuidv4() }],
          attachments: [],
          parentId: (action || match?.params?.action === 'add' && customerId) ? customerId : null,
          customLeaseHours: 0,
          customLeaseExtraHours: 0,
          customLeaseType: '',
          password: '',
          rePassword: '',
          username: '',
          accessGroup: [],
          quickbooksCode: ''
        });
      }
    }
  }, [match, modifiedAt]);

  const handleAddAddressDetail = () => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    nextFormValue.addressDetails.push({ ...initialAddressDetails, guid: uuidv4(), primary: false });
    setFormValue(nextFormValue);
  }

  const handleAddBillingDetail = () => {
    const newFormValue: any = update(formValue, {
      billingDetails: { $push: [{ ...initialBillingDetails, guid: uuidv4(), primary: false, updated: true }] }
    });
    setFormValue(newFormValue);
  }

  const handleAddContactDetail = () => {
    const newFormValue: any = update(formValue, {
      contactDetails: { $push: [{ ...initialContactDetails, guid: uuidv4(), primary: false }] }
    });
    setFormValue(newFormValue);
  }

  const handleRemoveAddressDetail = (guid: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const keyIndex = nextFormValue.addressDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.addressDetails.splice(keyIndex, 1);

    if (nextFormValue.addressDetails.length === 0) {
      nextFormValue.addressDetails.push({ ...initialAddressDetails, guid: uuidv4() });
    }

    const hasPrimary = nextFormValue.addressDetails.filter((f: any) => f.primary).length;
    if (!hasPrimary) {
      nextFormValue.addressDetails[0].primary = true;
      setUpdatedAt(new Date().toISOString());
    }

    setFormValue(nextFormValue);
  }

  const handleRemoveBillingDetail = (guid: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const keyIndex = nextFormValue.billingDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.billingDetails.splice(keyIndex, 1);

    if (nextFormValue.billingDetails.length === 0) {
      nextFormValue.billingDetails.push({ ...initialBillingDetails, guid: uuidv4() });
    }

    const hasPrimary = nextFormValue.billingDetails.filter((f: any) => f.primary).length;
    if (!hasPrimary) {
      nextFormValue.billingDetails[0].primary = true;
      setUpdatedAt(new Date().toISOString());
    }

    setFormValue(nextFormValue);
  }

  const handleRemoveContactDetail = (guid: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const keyIndex = nextFormValue.contactDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.contactDetails.splice(keyIndex, 1);

    if (nextFormValue.contactDetails.length === 0) {
      nextFormValue.contactDetails.push({ ...initialContactDetails, guid: uuidv4() });
    }

    const hasPrimary = nextFormValue.contactDetails.filter((f: any) => f.primary).length;
    if (!hasPrimary) {
      nextFormValue.contactDetails[0].primary = true;
      setUpdatedAt(new Date().toISOString());
    }

    setFormValue(nextFormValue);
  }

  const handleUpdateAddressDetail = (guid: string, address: string, city: string, postalCode: string, province: string, unit: string, googlePlacesResult: any) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const keyIndex = nextFormValue.addressDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.addressDetails[keyIndex] = { ...nextFormValue.addressDetails[keyIndex], address, city, postalCode, province, unit, googlePlacesResult };
    setFormValue(nextFormValue);
  }

  const handleUpdateBillingDetail = (guid: string, dataKey: string, maskedPan: string, expMonth: string, expYear: string, cvd: string, notes: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const keyIndex = nextFormValue.billingDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.billingDetails[keyIndex] = { ...nextFormValue.billingDetails[keyIndex], dataKey, maskedPan, expMonth, expYear, cvd, notes, updated: true };
    setFormValue(nextFormValue);
  }

  const handleUpdateContactDetail = (guid: string, name: string, email: string, phone: string, ext: string, status: string[]) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    const keyIndex = nextFormValue.contactDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.contactDetails[keyIndex] = { ...nextFormValue.contactDetails[keyIndex], name, email, phone, ext, status };
    setFormValue(nextFormValue);
  }

  const handleSetPrimaryAddressDetail = (guid: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    nextFormValue.addressDetails.forEach((c: any) => c.primary = false);
    const keyIndex = nextFormValue.addressDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.addressDetails[keyIndex] = { ...nextFormValue.addressDetails[keyIndex], primary: true };
    setFormValue(nextFormValue);
    setUpdatedAt(new Date().toISOString());
  }

  const handleSetPrimaryBillingDetail = (guid: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    nextFormValue.billingDetails.forEach((c: any) => c.primary = false);
    const keyIndex = nextFormValue.billingDetails.findIndex((g: any) => g.guid === guid);

    nextFormValue.billingDetails[keyIndex] = { ...nextFormValue.billingDetails[keyIndex], primary: true };
    setFormValue(nextFormValue);
    setUpdatedAt(new Date().toISOString());
  }

  const handleSetPrimaryContactDetail = (guid: string) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    nextFormValue.contactDetails.forEach((c: any) => c.primary = false);
    const keyIndex = nextFormValue.contactDetails.findIndex((g: any) => g.guid === guid);
    nextFormValue.contactDetails[keyIndex] = { ...nextFormValue.contactDetails[keyIndex], primary: true };
    setFormValue(nextFormValue);
    setUpdatedAt(new Date().toISOString());
  }

  const handleSubmit = async (stay: boolean) => {
    try {
      setSaving(true);

      const errorMessage = 'Form has errors which need to be corrected';
      const validation = form.check();

      if (!validation) {
        toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
      } else {
        const upsertCustomer = gql`
          mutation upsertCustomer($input: UpsertCustomerInput!) {
            upsertCustomer(input: $input) {
              success
              code
              message
              result
              operation
            }
          }
        `;

        formValue.customLeaseHours = +formValue.customLeaseHours;
        formValue.customLeaseExtraHours = +formValue.customLeaseExtraHours;
        formValue.parentId = formValue.parentId ? +formValue.parentId : null;
        formValue.username = (formValue?.username || '').trim();
        formValue.customFields = getCustomFields(formValue);

        const response: any = await client.mutate({
          mutation: upsertCustomer, variables: {
            // the array is taken from the input list
            input: {
              ...pick(formValue, ['guid', 'accessGroup', 'quickbooksCode', 'customerName', 'businessName', 'customerAddress', 'city', 'province', 'postalCode', 'workAddress', 'workCity', 'workProvince', 'workPostalCode', 'phone', 'alternatePhone', 'email', 'isActive', 'doNotContact', 'doNotService', 'parentId', 'label', 'propertyManager', 'contactDetails', 'comments', 'addressDetails', 'attachments', 'customLeaseHours', 'customLeaseExtraHours', 'customLeaseEndsAt', 'customLeaseType', 'password', 'status', 'username', 'userId', 'customFields', 'squareFootage', 'xeroCustomerId'])
            }
          }
        });

        // if (response.data.upsertCustomer.result.monerisResult.length > 0) {
        //   response.data.upsertCustomer.result.monerisResult.map((r: any) => {
        //     if (r.success) {
        //       toaster.push(
        //         <Message type="success" showIcon closable duration={3000}>{r.message}</Message>
        //       );
        //     } else {
        //       toaster.push(
        //         <Message type="error" showIcon closable duration={3000}>{r.message}</Message>
        //       );
        //     }
        //   })
        // }

        const notice: any = showNotice(response.data, 'upsertCustomer');

        if (notice.operation.success || true) {
          setModifiedAt(new Date().toISOString());
          if (drawer) {
            onUpdate && onUpdate(response.data.upsertCustomer.result);
            onHide && onHide();
          } else if (stay && action === 'add') {
            history.push(`/app/${getEnv()}/customer/edit/${response.data.upsertCustomer.result.guid}`);
          } else if (!stay) {
            history.push(`/app/${getEnv()}/workbook/explorer/${response.data.upsertCustomer.result.parentId > 0
              ? response.data.upsertCustomer.result.parentId : response.data.upsertCustomer.result.id}`);
          } else {
            setSaving(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
      showError(err);
      setSaving(false);
    } finally {
      setSaving(false);
    }
  }

  const handleSetAttachments = (attachments: any) => {
    const nextFormValue = JSON.parse(JSON.stringify(formValue));
    nextFormValue.attachments = [].concat(attachments);
    setFormValue(nextFormValue);
  }

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    const newSort = arrayMove(formValue.contactDetails, oldIndex, newIndex);
    setFormValue({ ...formValue, contactDetails: newSort });
  };

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + INTL.CUSTOMER + ((formValue.parentId > 0 && action === 'add') ? ' Address' : '')}
      guid={guid}
      action={action || 'add'}
      form={`/app/${getEnv()}/customer/${action}/${guid ? guid : ''}?${customerId ? `customer_id=${customerId}` : ''}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return cloneElement(Wrapper, {
    children: <div ref={container}>
      {!drawer &&
        <ButtonToolbar className="mt-5 mb-20">
          <Button appearance={'subtle'} onClick={() => {
            history.push((action === 'add' ? `/app/${getEnv()}/workbook/customers` : `/app/${getEnv()}/workbook/explorer/${formValue.customerId}`));
          }}>{action === 'edit' ? formValue.customerName : INTL.CUSTOMER}</Button>
          <span>/</span>
          <Button appearance={'subtle'}>{startCase(action)} {INTL.CUSTOMER}</Button>
        </ButtonToolbar>
      }

      {(IS_ASSINIBOINE || IS_KALADI_KITCHENS) &&
        <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={setActiveTab} className="mb-8">
          <ResponsiveNav.Item eventKey="form">{startCase(action)}</ResponsiveNav.Item>
          {(IS_ASSINIBOINE && action === 'edit') &&
            <ResponsiveNav.Item eventKey="history">Billing History</ResponsiveNav.Item>
          }
          {IS_KALADI_KITCHENS &&
            <ResponsiveNav.Item eventKey="login">Login Details</ResponsiveNav.Item>
          }
        </ResponsiveNav>
      }

      <Panel className="content">
        {!loading &&
          <Form
            fluid
            className={action === 'view' ? 'readonly' : ''}
            ref={(ref: any) => form = ref}
            formValue={formValue}
            model={model}
            formError={formError}
            onChange={setFormValue}
            onCheck={setFormError}
          >
            {activeTab === 'form' &&
              <Form.Group>
                <Form.ControlLabel className="fieldset">{INTL.CUSTOMER}</Form.ControlLabel>
              </Form.Group>
            }

            {activeTab === 'form' &&
              <Grid fluid className={'p-0'}>
                <Row>
                  <Col md={10}>
                    {(!isRole(ROLE.CLIENT) && IS_KALADI_PROPERTIES) &&
                      <FormGroup>
                        {!formValue?.parentId && <Message style={{ margin: 0 }} type='info'>This is a <strong>general tenant record</strong>. Select Parent to create as subset of an existing {INTL.CLIENT}.</Message>}
                      </FormGroup>
                    }

                    {getEnv() === 'dev' &&
                      <Form.Group>
                        <Form.ControlLabel>UUID:</Form.ControlLabel>
                        <p>{formValue.guid}</p>
                      </Form.Group>
                    }

                    {((!isRole(ROLE.CLIENT)) && IS_KALADI_PROPERTIES) &&
                      <Form.Group>
                        <Form.ControlLabel>Parent:</Form.ControlLabel>
                        <Form.Control
                          block
                          loading={searchCustomers?.loading}
                          readOnly={action === 'view'}
                          name="parentId"
                          accepter={SelectPicker}
                          container={() => container && container.current}
                          data={searchCustomers?.data?.customers?.edges?.node}
                          labelKey='displayName'
                          valueKey='id'
                          onSearch={(val: any) => {
                            if (val.length > 0) {
                              setCustomerSearchQuery(val);
                            }
                          }}
                        />
                      </Form.Group>
                    }

                    {(!isRole(ROLE.CLIENT) && !IS_KALADI_PROPERTIES) &&
                      <Form.Group>
                        <Form.ControlLabel>Status:</Form.ControlLabel>
                        <Form.Control
                          block
                          readOnly={action === 'view'}
                          name="status"
                          accepter={CheckPicker}
                          container={() => container && container.current}
                          data={[
                            // { value: 'inactive', label: 'Inactive' },
                            { value: 'do_not_contact', label: 'Do Not Contact' },
                            { value: 'do_not_service', label: 'Do Not Service' }
                          ]} />
                      </Form.Group>
                    }

                    <Form.Group>
                      <Form.ControlLabel className="required">{INTL.CUSTOMER}/Business/Property Name:</Form.ControlLabel>
                      <Form.Control name="customerName" />
                    </Form.Group>

                    {hasQuickBooksDesktopIntegration(state.companies) &&
                      <Form.Group>
                        <Form.ControlLabel>QuickBooks Code:</Form.ControlLabel>
                        <Form.Control name="quickbooksCode" />
                        <Form.HelpText>Must match the exact value of customer name in QuickBooks</Form.HelpText>
                      </Form.Group>
                    }

                    {CONFIG_APPLICATION_FIELDS.customer.includes('propertyManager') &&
                      <Form.Group>
                        <Form.ControlLabel>Property Management Company:</Form.ControlLabel>
                        <Form.Control name="propertyManager" />
                      </Form.Group>
                    }

                    {!isRole(ROLE.CLIENT) &&
                      <Form.Group>
                        <Form.ControlLabel>Label:</Form.ControlLabel>
                        <Form.Control
                          block
                          readOnly={action === 'view'}
                          name="label"
                          accepter={SelectPicker}
                          container={() => container && container.current}
                          data={filterFieldsByParentKey(state.fields, 'customer_label').map((f: any) => ({
                            label: f.title,
                            value: f.key
                          }))} />
                      </Form.Group>
                    }

                    {(!isRole(ROLE.CLIENT) && !IS_KALADI_PROPERTIES && !IS_KALADI_KITCHENS) &&
                      <Form.Group>
                        <Form.ControlLabel>Square Footage:</Form.ControlLabel>
                        <Form.Control
                          block
                          readOnly={action === 'view'}
                          name="squareFootage"
                          accepter={InputPicker}
                          data={arrayRange(0, 900000, 25000).map((r: any) => ({
                            label: `${r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}-${(r + 25000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} sqft`,
                            value: `${r}-${r + 25000}`
                          }))} />
                      </Form.Group>
                    }

                    {!isRole(ROLE.CLIENT) &&
                      <Form.Group>
                        <Form.ControlLabel>Comments/Info:</Form.ControlLabel>
                        <Input as={TextareaAutosize} value={formValue.comments || ''} onChange={(val: any) => setFormValue({ ...formValue, comments: val })} />
                      </Form.Group>
                    }
                  </Col>
                </Row>
              </Grid>
            }

            {(IS_KALADI_KITCHENS && activeTab === 'login') &&
              <div>
                {(customer && !customer.userId && !isRole(ROLE.CLIENT)) &&
                  <Message type="error" className="mb-24">
                    This client has not been assigned user and will not be able to log in. Type in username and password to create and associate user account.
                  </Message>
                }
                <Grid fluid className={'p-0'}>
                  <Row>
                    <Col md={10}>
                      <Form.Group>
                        <Form.ControlLabel>Username:</Form.ControlLabel>
                        {isRole(ROLE.CLIENT)
                          ? <span>{formValue.username}</span>
                          : <div>
                            <Form.Control name="username" />
                            <Form.HelpText>All lowercase, alphanumeric. Email address can also be used for login.</Form.HelpText>
                          </div>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-10">
                    <Col md={5}>
                      <Form.Group>
                        <Form.ControlLabel className={action === 'add' ? 'required' : ''}>Password:</Form.ControlLabel>
                        <Form.Control type="password" name="password" />
                      </Form.Group>
                    </Col>
                    <Col md={5}>
                      <Form.Group>
                        <Form.ControlLabel className={action === 'add' ? 'required' : ''}>Retype Password:</Form.ControlLabel>
                        <Form.Control type="password" name="repassword" />
                      </Form.Group>
                    </Col>
                  </Row>
                  {action === 'edit' &&
                    <Row>
                      <Col md={10}>
                        <Form.HelpText>Type in a new password only if you wish to change it or set it.</Form.HelpText>
                      </Col>
                    </Row>
                  }
                </Grid>
              </div>
            }

            {activeTab === 'form' &&
              <>
                {IS_KALADI_PROPERTIES && <>
                  {(customer?.parentId === 0 || customer?.parentId === customer?.id) ?
                    <>
                      <Form.Group >
                        <Grid fluid className="p-0" key={`sqftLease-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Sqft Lease:</Form.ControlLabel>
                              <Form.Control
                                name="customField_sqftLease"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group >
                        <Grid fluid className="p-0" key={`yearBuilt-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Year Built:</Form.ControlLabel>
                              <Form.Control
                                name="customField_yearBuilt"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group >
                        <Grid fluid className="p-0" key={`purchaseDate-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Purchase Date:</Form.ControlLabel>
                              <Form.Control
                                name="customField_purchaseDate"
                                accepter={DatePicker}
                                renderValue={(val: Date) => format(val, FORMAT.MONTH_DATE)}
                                block
                                cleanable={false}
                                oneTap
                                size="sm"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group >
                        <Grid fluid className="p-0" key={`plumbingInfo-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Plumbing Info:</Form.ControlLabel>
                              <Form.Control
                                name="customField_plumbingInfo"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group >
                        <Grid fluid className="p-0" key={`electricalInfo-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Electrical Info:</Form.ControlLabel>
                              <Form.Control
                                name="customField_electricalInfo"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group >
                        <Grid fluid className="p-0" key={`structureInfo-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Structure Info:</Form.ControlLabel>
                              <Form.Control
                                name="customField_structureInfo"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>

                      <Form.Group >
                        <Grid fluid className="p-0" key={`roofInfo-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Roof Info:</Form.ControlLabel>
                              <Form.Control
                                name="customField_roofInfo"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </>
                    : <>
                      <Form.Group >
                        <Grid fluid className="p-0" key={`unitNumber-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Unit Number:</Form.ControlLabel>
                              <Form.Control
                                name="customField_unitNumber"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                      <Form.Group >
                        <Grid fluid className="p-0" key={`unitSqft-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Unit Sqft:</Form.ControlLabel>
                              <Form.Control
                                name="customField_unitSqft"
                              />
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                      <Form.Group >
                        <Grid fluid className="p-0" key={`rented-${updatedAt}`}>
                          <Row>
                            <Col xs={24} md={10}>
                              <Form.ControlLabel>Rented:</Form.ControlLabel>
                              <Checkbox checked={formValue.customField_unitRented} onChange={(event: any, val: any) => {
                                setFormValue({ ...formValue, customField_unitRented: val })
                              }}>Yes</Checkbox>
                            </Col>
                          </Row>
                        </Grid>
                      </Form.Group>
                    </>
                  }
                </>}

                {IS_BUGABOO &&
                  <Form.Group >
                    <Grid fluid className="p-0" key={`irrigation-system-${updatedAt}`}>
                      <Row>
                        <Col xs={24} md={10}>
                          <Form.ControlLabel>Irrigation System Onsite:</Form.ControlLabel>
                          <Checkbox checked={formValue.customField_irrigationSystemOnsite} onChange={(event: any, val: any) => {
                            setFormValue({ ...formValue, customField_irrigationSystemOnsite: val })
                          }}>Yes</Checkbox>
                        </Col>
                      </Row>
                    </Grid>
                  </Form.Group>
                }


                <Form.Group className={'mt-24'}>
                  <Form.ControlLabel className="fieldset">Address</Form.ControlLabel>
                  <Grid fluid className="p-0" key={`address-${updatedAt}`}>
                    {formValue.addressDetails && formValue.addressDetails.map((cd: any, row: number) =>
                      <CustomerFormAddressDetailLineItem
                        {...cd}
                        key={cd.guid}
                        row={row}
                        action={action}
                        onAdd={handleAddAddressDetail}
                        onRemove={handleRemoveAddressDetail}
                        onBlur={handleUpdateAddressDetail}
                        onSetPrimary={handleSetPrimaryAddressDetail}
                        container={container}
                      />
                    )}
                  </Grid>
                </Form.Group>

                <Form.Group className={'mt-24'}>
                  <Form.ControlLabel className="fieldset">Contacts</Form.ControlLabel>
                </Form.Group>
                <Grid fluid className={'p-0'} key={`contact-${updatedAt}`}>
                  <Row>
                    <Col md={7}>Name/Label</Col>
                    <Col md={6}>Email</Col>
                    <Col md={4}>Phone</Col>
                    <Col md={2}>Ext</Col>
                    <Col md={2}>Status</Col>
                    x                    <Col md={1}>Primary</Col>
                    <Col md={2}>&nbsp;</Col>
                  </Row>
                  {/* {formValue.contactDetails && formValue.contactDetails.map((cd: any, row: number) =>
                    <CustomerFormContactDetailLineItem
                      {...cd}
                      key={cd.guid}
                      row={row}
                      onAdd={handleAddContactDetail}
                      onRemove={handleRemoveContactDetail}
                      onBlur={handleUpdateContactDetail}
                      onSetPrimary={handleSetPrimaryContactDetail}
                    />
                  )} */}
                </Grid>
                <SortableList onSortEnd={handleSortEnd} helperClass="sortableHelper" useDragHandle>
                  {formValue.contactDetails && formValue.contactDetails.map((s: any, index: number) =>
                    <SortableItem
                      index={index}
                      {...s}
                      key={s.guid}
                      row={index}
                      onAdd={handleAddContactDetail}
                      onRemove={handleRemoveContactDetail}
                      onBlur={handleUpdateContactDetail}
                      onSetPrimary={handleSetPrimaryContactDetail}
                    />
                  )}
                </SortableList>

                {/* {(hasMonerisIntegration(state.companies) || hasStripeIntegration(state.companies)) &&
                  <Fragment>
                    <Form.Group className={'mt-24'}>
                      <Form.ControlLabel className="fieldset">Billing</Form.ControlLabel>
                    </Form.Group>
                    <Grid fluid className={'p-0'} key={`billing-${updatedAt}`}>
                      <Row>
                        <Col md={5}>Credit Card</Col>
                        <Col md={3}>Month</Col>
                        <Col md={2}>Year</Col>
                        <Col md={2}>CVD</Col>
                        <Col md={6}>Notes</Col>
                        <Col md={1}>Primary</Col>
                        <Col md={3}>Data Key</Col>
                        <Col md={2}>&nbsp;</Col>
                      </Row>
                      {formValue.billingDetails && formValue.billingDetails.map((cd: any, row: number) =>
                        <CustomerFormBillingDetailLineItem
                          {...cd}
                          key={cd.guid}
                          row={row}
                          onAdd={handleAddBillingDetail}
                          onRemove={handleRemoveBillingDetail}
                          onBlur={handleUpdateBillingDetail}
                          onSetPrimary={handleSetPrimaryBillingDetail}
                          container={container}
                        />
                      )}
                    </Grid>
                  </Fragment>
                } */}

                {(IS_KALADI_KITCHENS && !isRole(ROLE.CLIENT)) &&
                  <fieldset className={'mt-24'}>
                    <legend>Lease Details</legend>
                    <Grid fluid className={'p-0'}>
                      {!isRole(ROLE.CLIENT) &&
                        <Row>
                          <Col md={7} xs={24}>
                            <Form.Group className="mb-24">
                              <Form.ControlLabel className="required">Location:</Form.ControlLabel>
                              <Form.Control
                                searchable={false}
                                block
                                cleanable={false}
                                name="accessGroup"
                                accepter={CheckPicker}
                                data={workGroups}
                                labelKey='title'
                                valueKey='key' />
                            </Form.Group>
                          </Col>
                        </Row>
                      }
                      <Row>
                        <Col md={2} xs={24}>
                          <Form.Group>
                            <Form.ControlLabel>Monthly Hours:</Form.ControlLabel>
                            <Form.Control name="customLeaseHours" />
                            <Form.HelpText>0 unlimited hours</Form.HelpText>
                          </Form.Group>
                        </Col>
                        <Col md={2} xs={24}>
                          <Form.Group>
                            <Form.ControlLabel>Extra Hours:</Form.ControlLabel>
                            <Form.Control name="customLeaseExtraHours" />
                          </Form.Group>
                        </Col>
                        <Col md={3} xs={24}>
                          <Form.Group>
                            <Form.ControlLabel>Lease Type:</Form.ControlLabel>
                            <Form.Control
                              block
                              accepter={SelectPicker}
                              container={() => container && container.current}
                              cleanable={false}
                              searchable={false}
                              name="customLeaseType"
                              data={[
                                { value: 'month_to_month', label: 'Month to Month' },
                                { value: 'end_date', label: 'End Date' }
                              ]} />
                          </Form.Group>
                        </Col>
                        {formValue.customLeaseType === 'end_date' &&
                          <Col md={3} xs={24}>
                            <Form.Group>
                              <Form.ControlLabel>Lease End Date:</Form.ControlLabel>
                              <DatePicker
                                block
                                container={() => container && container.current}
                                cleanable={false}
                                renderValue={(val: Date) => format(val, FORMAT.MONTH_DATE)}
                                onChange={(val: any) => setFormValue({ ...formValue, customLeaseEndsAt: val })}
                                value={typeof (formValue.customLeaseEndsAt || undefined) === 'string' ? parseISO(formValue.customLeaseEndsAt) : formValue.customLeaseEndsAt}
                                placement="bottomEnd"
                              />
                            </Form.Group>
                          </Col>
                        }
                      </Row>
                    </Grid>
                  </fieldset>
                }

                <Form.Group className={'mt-24'}>
                  <Form.ControlLabel className="fieldset">Attachments</Form.ControlLabel>
                </Form.Group>
                <AttachmentForm
                  referenceGuid={formValue.guid}
                  attachments={formValue.attachments}
                  onChange={handleSetAttachments}
                  showVisibility={!IS_KALADI_KITCHENS}
                  showType={IS_KALADI_KITCHENS}
                />

                {hasXeroIntegration(state.companies) &&
                  <Grid fluid className={'p-0'}>
                    <Row>
                      <Col md={10}>
                        <Form.Group className={'mt-24'}>
                          <Form.ControlLabel className="fieldset">Integrations</Form.ControlLabel>
                          <Form.Group>
                            <Form.ControlLabel>Xero Contact Id:</Form.ControlLabel>
                            <Form.Control name="xeroCustomerId" />
                          </Form.Group>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Grid>
                }
              </>
            }

            {activeTab === 'history' &&
              <div>
                {(customer.billingHistory || []).length === 0
                  ? <div>No transaction history found</div>
                  : <ResponsiveTable html5 data={customer.billingHistory || []}>
                    {getEnv() === 'dev' &&
                      <Column width={40}>
                        <HeaderCell>UUID</HeaderCell>
                        <Cell dataKey="guid" />
                      </Column>
                    }
                    <Column width={20}>
                      <HeaderCell>Created At</HeaderCell>
                      <Cell>
                        {(row: any) => {
                          return (
                            <span>{format(parseISO(row.createdAt), FORMAT.DAY_MONTH_TIME)}</span>
                          )
                        }}
                      </Cell>
                    </Column>
                    <Column width={10}>
                      <HeaderCell>Code</HeaderCell>
                      <Cell>
                        {(row: any) => {
                          const response = JSON.parse(row.response);
                          return <span>{(response.response_code || response.ResponseCode).toString()}</span>;
                        }}
                      </Cell>
                    </Column>
                    <Column flexGrow={3}>
                      <HeaderCell>Notes</HeaderCell>
                      <Cell>
                        {(row: any) => {
                          const response = JSON.parse(row.response);
                          return <span>{response.message || response.Message || response.error || response.Error}</span>;
                        }}
                      </Cell>
                    </Column>
                  </ResponsiveTable>
                }
              </div>
            }

            {!drawer &&
              <ButtonToolbar className="mt-24">
                <Button appearance="primary" onClick={() => handleSubmit(false)} loading={saving}>Save</Button>
                <Button appearance="ghost" onClick={() => handleSubmit(true)} loading={saving}>Save &amp; Stay</Button>
              </ButtonToolbar>
            }
          </Form>
        }
      </Panel>
    </div >
  });
}

export {
  CustomerForm
}
