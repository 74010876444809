import { useContext, useRef } from 'react';
import { gql } from '@apollo/client';
import useAppApolloClient from 'lib/client';
import { COLOR, FORMAT, ROLE, getEnv, setHeaderTitle } from 'lib/env';
import { orderBy, startCase } from 'lodash';
import { cloneElement, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Message, Col, DatePicker, Divider, Grid, Panel, Row, Table, Input, IconButton, Breadcrumb, SelectPicker, CheckPicker } from 'rsuite';
import { Pagination, DrawerFormWrapper, Filter, ResponsiveTable, ApplicationContext } from 'shared';
import { useFilteredParams } from 'shared/FilterProvider';
import { useViewport } from 'shared/ViewportProvider';
import { parseISO, format, setDate } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { MdAdd, MdCheckBoxOutlineBlank, MdClose, MdOutlineCheckBox } from 'react-icons/md';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { IS_KALADI_KITCHENS } from 'lib/tenant';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import Icon from '@rsuite/icons/lib/Icon';
import store from 'lib/storage';
import INTL from 'tenant/intl';
import { Data } from '@react-google-maps/api';

const { Column, HeaderCell, Cell } = Table;

interface IClockInOut {
  show?: boolean,
  drawer?: boolean,
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const ClockInOut = ({ show, drawer, onHide, onUpdate }: IClockInOut) => {
  setHeaderTitle('Clock In/Out');
  const client = useAppApolloClient();
  const { isRole } = usePrairieAuth();
  const { showConfirmation, showSuccess, showError } = useContext(ApplicationContext);
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { state } = useViewport();
  const { params } = useFilteredParams('clockinout');
  const [filter, setFilter] = useState(params);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [employees, setEmployees] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [clockInTimes, setClockInTimes] = useState<any>({});
  const [clockOutTimes, setClockOutTimes] = useState<any>({});
  const [dateSelected, setDateSelected] = useState(new Date(params.date) || new Date());
  const [recordsPerPage, setRecordsPerPage] = useLocalStorage('recordsPerPage', 20);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {
    if (format(params?.date, FORMAT.ISO_DATE) !== format(dateSelected, FORMAT.ISO_DATE)) {
      setDateSelected(params?.date);
    }
  }, [params?.date]);

  useEffect(() => {
    (async function getEmployees() {
      setLoading(true);

      const whereFilter: any = {
        clockInOutDate: drawer ? dateSelected : params.date
      };

      if (filter.text.length > 0) {
        if (!whereFilter.AND) {
          whereFilter.AND = [];
        }

        whereFilter.AND.push({ employeeName: { contains: filter.text } });
      }

      if (filter.crew.length > 0) {
        if (!whereFilter.AND) {
          whereFilter.AND = [];
        }

        whereFilter.AND.push({ userId: { in: filter.crew } });
      }

      if (!whereFilter.AND) {
        whereFilter.AND = [];
      }

      if (IS_KALADI_KITCHENS) {
        whereFilter.AND.push({ type: { in: ['admin', 'manager', 'cleaner'] } });
      } else {
        whereFilter.AND.push({ type: { in: ['employee', 'franchise', 'worker', 'manager', 'cleaner'] } });
        whereFilter.AND.push({ deletedAt: { is: null } });
      }

      if (showCompleted) {
        whereFilter.AND.push({ payrollEntriesCount: { gt: 0 } });
      }

      const data: any = await client.query({
        query: gql`
          query employees($limit: Int!, $offset: Int!, $where: EmployeesWhere, $order: [EmployeesSort]) {
            employees(filter: {
                limit: $limit
                offset: $offset
                where: $where
                order: $order
              }){
              edges {
                node {
                  guid
                  employeeGuid
                  employeeName
                  employeeType
                  hourlyPay
                  userId
                  type
                  payrollEntriesCount
                  workGroup
                  payroll {
                    guid
                    clockIn
                    clockOut
                    comments
                    services
                    userId
                  }
                }
              },
              totalCount
            }
          }`,
        variables: {
          offset,
          limit: recordsPerPage,
          where: whereFilter,
          sort: [{ employeeName: 'ASC' }]
        }
      });

      let employeeList = data.data.employees.edges.node.map((e: any) => {
        if (e.payroll.length === 0) {
          e.payroll = [{
            guid: uuidv4(),
            clockIn: undefined,
            clockOut: undefined,
            description: undefined,
            userId: undefined
          }];
        }

        return e;
      });

      if (IS_KALADI_KITCHENS && isRole(ROLE.MANAGER)) {
        employeeList = employeeList.filter((e: any) => e.guid === state.profile.guid);
      }

      if (drawer) {
        setEmployees(orderBy(data.data.employees.edges.node, 'clockIn', 'asc'));
      } else {
        setEmployees(employeeList);
      }

      setTotalCount(data.data.employees.totalCount);
      setLoading(false);
    })();
  }, [modifiedAt, state.modifiedAt, filter, dateSelected, recordsPerPage, offset, showCompleted]);

  const clockInOut = async (data: {
    guid: string,
    employeeId?: string,
    clockInDateTime?: Date,
    clockOutDateTime?: Date,
    comments?: string,
    services?: any,
    userId?: any
  }) => {
    if (data.clockInDateTime) {
      data.clockInDateTime.setDate(dateSelected.getDate());
      data.clockInDateTime.setMonth(dateSelected.getMonth());
      data.clockInDateTime.setFullYear(dateSelected.getFullYear());
      const tmpTimes = { ...clockInTimes };
      tmpTimes[data.guid] = data.clockInDateTime;
      setClockInTimes(tmpTimes);
    }

    if (data.clockOutDateTime) {
      data.clockOutDateTime.setDate(dateSelected.getDate());
      data.clockOutDateTime.setMonth(dateSelected.getMonth());
      data.clockOutDateTime.setFullYear(dateSelected.getFullYear());
      const tmpTimes = { ...clockOutTimes };
      tmpTimes[data.guid] = data.clockOutDateTime;
      setClockInTimes(tmpTimes);
    }

    if (data.userId) {
      data.userId = data.userId;
    }

    const response: any = await client.mutate({
      mutation: gql`
        mutation upsertClockInOut($guid: ID!, $employeeId: ID, $userId: Int, $comments: String, $clockInDateTime: DateTime, $clockOutDateTime: DateTime, $services: [String]) {
          upsertClockInOut(guid: $guid, employeeId: $employeeId, userId: $userId, comments: $comments, clockInDateTime: $clockInDateTime, clockOutDateTime: $clockOutDateTime, services: $services) {
            success
            code
            message
            result
          }
        }`, variables: data
    });

    showSuccess(response.data.upsertClockInOut.message);
    setEmployees(employees.map((e: any) => {
      if (e.employeeGuid === response.data.upsertClockInOut?.result?.[0]?.employeeGuid) {
        e.payroll = response.data.upsertClockInOut.result;
      }

      return e;
    }));
  }

  const handleAddClockIn = (userGuid: string) => {
    setEmployees(employees.map((e: any) => {
      if (e.employeeGuid === userGuid) {
        e.payroll.push({
          guid: uuidv4(),
          clockIn: undefined,
          clockOut: undefined,
          comments: '',
          userId: undefined
        })
      }

      return e;
    }));
  }

  const handleRemoveClockIn = async (guid: string, userGuid: string) => {
    const response: any = await client.mutate({
      mutation: gql`
        mutation deleteClockIn($guid: ID!, $userGuid: ID!) {
          deleteClockIn(guid: $guid, userGuid: $userGuid) {
            success
            code
            message
            result
          }
        }`,
      variables: { guid, userGuid }
    });

    if (response.data.deleteClockIn.success) {
      showSuccess(response.data.deleteClockIn.message);

      setEmployees(employees.map((e: any) => {
        if (e.employeeGuid === userGuid) {
          e.payroll = response.data.deleteClockIn.result;
        }

        return e;
      }));
    } else {
      showError(response.data.deleteClockIn.message);
    }
  }

  const getJobServices = (workGroup: [string]) => {
    const jobServices: any = [];

    (workGroup || []).forEach((w: any) => {
      const services = state.fields.filter((f: any) => f.applicationGroup === w);

      services.forEach((s: any) => {
        jobServices.push({
          label: s.title,
          value: s.key,
          workGroup: w
        });
      });
    });

    return jobServices;
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      show={show}
      title={'Clock In/Out'}
      action={'view'}
      onHide={onHide}
      size="md"
    />;

  return cloneElement(Wrapper, {
    children: <div ref={container}>
      {drawer ?
        <div className='mb-6'>
          <DatePicker
            container={() => container && container.current}
            value={dateSelected}
            onChange={(val: any) => {
              setDateSelected(val);
            }}
            data-lpignore="true"
            cleanable={false}
            oneTap
          />
        </div>
        : <>
          <Breadcrumb className='p-0 mb-8' separator=' | '>
            <Breadcrumb.Item>Clock In/Out</Breadcrumb.Item>
            <Breadcrumb.Item>
              <IconButton id='customers__button_show_inactive' onClick={() => setShowCompleted(!showCompleted)} size="xs" icon={<Icon as={showCompleted ? MdOutlineCheckBox : MdCheckBoxOutlineBlank} />}>
                Show Completed <span></span>
              </IconButton>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Filter
            id='clockinout'
            onChange={setFilter}
            showDateFilter={true}
            showDateRangeFilter={false}
            crewFilterCleanable={true}
            showMultipleCrewFilter={true}
            showGroupFilter={false}
            showJobStatus={false}
            dateRangeCleanable={true}
            showUserRoleFilter={true}
          />
        </>
      }

      <div className={['content', isMobileOnly ? 'reset' : ''].join(' ')}>
        {(isRole(ROLE.FRANCHISE, ROLE.WORKER) && ((format(params.date, FORMAT.ISO_DATE) !== format(new Date(), FORMAT.ISO_DATE)))) &&
          <Message className="mb-10" type="error">
            Dates selected outside of current date. If you are completing clock in/out ensure that the date of entry is correct, <a href={`/app/${getEnv()}/resourcing/clock-in-out}`} onClick={(e: any) => {
              e.preventDefault();
              store.session.remove('clockinout-date-filter');
              window.location.href = `/app/${getEnv()}/resourcing/clock-in-out`;
            }}>click here</a> to go to current date.
          </Message>
        }

        {isMobileOnly
          ? <Grid fluid className='mt-12'>
            {employees.map((row: any) =>
              <Row key={`employee-${row.guid}`}>
                <Col xs={12}>
                  <strong>
                    {row.employeeName}<br />
                    <>
                      {startCase(row.type === 'franchise' ? 'crew' : row.type) || ''}
                      {row.type === 'employee' && <> - {startCase(row?.employeeType || 'contract')}</>}
                    </>
                  </strong>
                </Col>
                <Col xs={12}>
                  <div className='text-left mb-4'>
                    {row.payroll.map((p: any) =>
                      <div key={`clockIn-${p.guid}`}>
                        Clock In: <DatePicker
                          container={() => container && container.current}
                          size="xs"
                          cleanable={false}
                          format={'HH:mm'}
                          onChange={(time: any) => clockInOut({ guid: p.guid, employeeId: row.guid, clockInDateTime: time, userId: row?.userId?.[0] })}
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date(),
                              closeOverlay: true
                            }
                          ]}
                          placement="bottomEnd"
                          value={typeof (p.clockIn || undefined) === 'string' ? parseISO(p.clockIn) : row.clockIn}
                        />
                      </div>)}
                  </div>
                  <div className='text-left'>
                    {row.payroll.map((p: any, index: any) =>
                      <div key={`clockOut-${p.guid}`}>
                        User: {p.clockIn
                          ? <SelectPicker
                            block
                            searchable={false}
                            style={{ marginTop: index === 0 ? 0 : '4px' }}
                            size="sm"
                            cleanable={false}
                            value={p.userId}
                            data={(state.users || []).filter((su: any) => (row?.userId || []).includes(su.id))}
                            placement="bottomEnd"
                            onChange={(val) => clockInOut({ guid: p.guid, userId: val })}
                          />
                          : <div className='text-danger'>Clock in required</div>}
                      </div>)}
                  </div>
                  <div className='text-left'>
                    {row.payroll.map((p: any) =>
                      <div key={`clockOut-${p.guid}`}>
                        Clock Out: {p.clockIn ? <DatePicker
                          container={() => container && container.current}
                          size="xs"
                          cleanable={false}
                          format={'HH:mm'}
                          onChange={(time: any) => clockInOut({ guid: p.guid, clockOutDateTime: time })}
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date(),
                              closeOverlay: true
                            }
                          ]}
                          placement="bottomEnd"
                          value={typeof (p.clockOut || undefined) === 'string' ? parseISO(p.clockOut) : row.clockOut}
                        /> : <div className='text-danger'>Clock in required</div>}
                      </div>)}
                  </div>
                  <div className='text-left'>
                    {row.payroll.map((p: any) =>
                      <div key={`comments-${p.guid}`}>
                        Comments: {p.clockIn
                          ? <Input
                            key={p.guid}
                            size="sm"
                            defaultValue={p.comments || ''}
                            onBlur={(e: any) => clockInOut({ guid: p.guid, comments: e.target.value })}
                          />
                          : <div className='text-danger'>Clock in required</div>}
                      </div>)}
                  </div>
                </Col>
                <Col xs={24}><Divider /></Col>
              </Row>
            )}
          </Grid>
          : <ResponsiveTable data={employees} autoHeight={true} html5 loading={loading}>
            <Column>
              <HeaderCell>Employee Name</HeaderCell>
              <Cell>
                {(row: any) => row.employeeName || ''}
              </Cell>
            </Column>
            <Column>
              <HeaderCell>Type</HeaderCell>
              <Cell>
                {(row: any) => <>
                  {startCase(row.type === 'franchise' ? 'crew' : row.type) || ''}
                  {row.type === 'employee' && <> - {startCase(row?.employeeType || 'contract')}</>}
                </>
                }
              </Cell>
            </Column>
            {/* {!drawer &&
              <Column flexGrow={2}>
                <HeaderCell>Crew</HeaderCell>
                <Cell>
                  {(row: any) => row.operatorName}
                </Cell>
              </Column>
            } */}
            <Column width={40}>
              <HeaderCell>Clock In</HeaderCell>
              <Cell>
                {(row: any) => <>
                  {row.payroll.map((p: any, index: number) =>
                    <DatePicker
                      block
                      style={{ marginTop: index === 0 ? 0 : '4px' }}
                      key={`clock-in-${p.guid}`}
                      size="sm"
                      cleanable={false}
                      format={'HH:mm'}
                      calendarDefaultDate={dateSelected}
                      onChange={(time: any) => clockInOut({ guid: p.guid, employeeId: row.guid, clockInDateTime: time, userId: row?.userId?.[0] })}
                      container={drawer ? () => (container && container.current) : undefined}
                      ranges={[
                        {
                          label: 'Now',
                          value: new Date(),
                          closeOverlay: true
                        }
                      ]}
                      value={typeof (p.clockIn || undefined) === 'string' ? parseISO(p.clockIn) : p.clockIn}
                      placement="bottomEnd"
                    />
                  )}
                </>}
              </Cell>
            </Column>
            <Column width={40}>
              <HeaderCell>User</HeaderCell>
              <Cell>
                {(row: any) => <>
                  {row.payroll.map((p: any, index: number) =>
                    <div key={`user-${p.guid}`}>
                      {p.clockIn
                        ? <SelectPicker
                          block
                          searchable={false}
                          style={{ marginTop: index === 0 ? 0 : '4px' }}
                          size="sm"
                          cleanable={false}
                          value={p.userId}
                          data={(state.users || []).filter((su: any) => (row?.userId || []).includes(su.id))}
                          placement="bottomEnd"
                          onChange={(val) => clockInOut({ guid: p.guid, userId: val })}
                        />
                        : <div className='p-4 pl-0 text-danger'>Clock in required</div>}
                    </div>
                  )}
                </>}
              </Cell>
            </Column>
            <Column width={40}>
              <HeaderCell>Clock Out</HeaderCell>
              <Cell>
                {(row: any) => <>
                  {row.payroll.map((p: any, index: number) =>
                    <div key={`clock-out-${p.guid}`}>
                      {p.clockIn
                        ? <DatePicker
                          style={{ marginTop: index === 0 ? 0 : '4px' }}
                          block
                          calendarDefaultDate={dateSelected}
                          container={drawer ? () => (container && container.current) : undefined}
                          size="sm"
                          cleanable={false}
                          format={'HH:mm'}
                          onChange={(time: any) => clockInOut({ guid: p.guid, clockOutDateTime: time })}
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date(),
                              closeOverlay: true
                            }
                          ]}
                          placement="bottomEnd"
                          value={typeof (p.clockOut || undefined) === 'string' ? parseISO(p.clockOut) : p.clockOut}
                        />
                        : <div className='p-4 pl-0 text-danger'>Clock in required</div>
                      }
                    </div>
                  )}
                </>}
              </Cell>
            </Column>
            {(IS_KALADI_KITCHENS && isRole(ROLE.CLEANER)) &&
              <Column>
                <HeaderCell>{INTL.WORK_TYPE}</HeaderCell>
                <Cell>
                  {(row: any) => <>
                    {row.payroll.map((p: any, index: number) =>
                      <CheckPicker
                        defaultValue={p.services}
                        key={`services-${p.guid}`}
                        block
                        size="sm"
                        data={getJobServices(row.workGroup)}
                        groupBy='workGroup'
                        onSelect={async (value: any) => {
                          await clockInOut({ guid: p.guid, services: value });
                        }}
                      />
                    )}
                  </>}
                </Cell>
              </Column>
            }
            <Column flexGrow={1}>
              <HeaderCell>Comments</HeaderCell>
              <Cell>
                {(row: any) => <>
                  {row.payroll.map((p: any, index: number) =>
                    <Grid key={`comment-${p.guid}`} fluid style={{ marginTop: index === 0 ? 0 : '4px' }}>
                      <Row>
                        <Col xs={20}>
                          <div key={`comments-${p.guid}`}>
                            {p.clockIn
                              ? <Input
                                key={p.guid}
                                size="sm"
                                defaultValue={p.comments || ''}
                                onBlur={(e: any) => clockInOut({ guid: p.guid, comments: e.target.value })}
                              />
                              : <div className='p-4 pl-0 text-danger'>Clock in required</div>
                            }
                          </div>
                        </Col>
                        <Col xs={4}>
                          {index === 0
                            ? <IconButton icon={<MdAdd />} onClick={() => handleAddClockIn(row.guid)} size="sm" appearance="link" style={{ paddingRight: 0, paddingLeft: 8 }} />
                            : <IconButton icon={<MdClose />} size="sm" onClick={() => {
                              showConfirmation(`Are you sure you want to remove follow time entry${p.clockIn ? ' ' + format(parseISO(p.clockIn), 'HH:mm') : ''}? This action cannot be undone`,
                                'Remove Time Entry',
                                () => handleRemoveClockIn(p.guid, row.guid));
                            }} appearance="link" style={{ paddingRight: 0, paddingLeft: 8, color: COLOR.DANGER }} />
                          }
                        </Col>
                      </Row>
                    </Grid>
                  )}
                </>}
              </Cell>
            </Column>
          </ResponsiveTable>
        }

        {!loading &&
          <Pagination
            offset={offset < 0 ? 0 : offset}
            totalCount={totalCount}
            recordsPerPage={recordsPerPage}
            onChangeOffset={setOffset}
            onChangeLength={setRecordsPerPage}
          />
        }
      </div>
    </div>
  });
}

export default ClockInOut;
