import { Fragment, useContext, useEffect, useState } from 'react';
import { Loader, Grid, Row, Col, DOMHelper, Badge, Dropdown, toaster, SelectPicker, DatePicker, IconButton, Whisper, Tooltip, 
  Message, Nav, Modal, Button, ButtonToolbar } from 'rsuite';
import LegacyCloseCircleIcon from "@rsuite/icons/legacy/CloseCircle";
import LegacyOkCircleIcon from "@rsuite/icons/legacy/OkCircle";
import LegacyExclamationCircleIcon from "@rsuite/icons/legacy/ExclamationCircle";
import LegacyCloneIcon from "@rsuite/icons/legacy/Clone";
import LegacyTrashIcon from "@rsuite/icons/legacy/Trash";
import LegacyChevronLeftIcon from "@rsuite/icons/legacy/ChevronLeft";
import LegacyChevronRightIcon from "@rsuite/icons/legacy/ChevronRight";
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DroppableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import { gql, useApolloClient } from '@apollo/client';
import { ApplicationContext, DateRangeFilter } from 'shared';
import { DRAWER, FORMAT, getFieldPrintable, parseNumber, ROLE } from 'lib/env';
import { orderBy, startCase } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { queryWorkOrders, updateWorkOrders } from 'gql/workOrders';
import { createJobs, deleteJobs, queryJobs } from 'gql/jobs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { isAfter } from 'date-fns/esm';
import { addDays, format } from 'date-fns';
import { parseISO } from 'lib/date';
import { IS_APP, IS_BUGABOO, moneyFormatter } from 'lib/tenant';
import { isBetween } from 'lib/helpers';
import { useViewport } from 'shared/ViewportProvider';
import arrayMove from 'array-move';
import { getSort } from 'lib/helpers/sort';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { RRule } from 'rrule';
import { MdFolder, MdMoreVert } from 'react-icons/md';
import useSessionStorage from 'lib/hooks/useSessionStorage';

const getItemStyle = (draggableStyle: any, isDragging: boolean): {} => ({
  userSelect: 'none',
  padding: '8px',
  margin: `0 0 8px 0`,
  background: isDragging ? '#edf8ff' : '#f9f9f9',
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): {} => ({
  background: isDraggingOver ? '#eee' : '#fff',
  border: 'solid 1px #eee',
  padding: '8px',
  width: '100%'
});

const JobsSchedule = () => {
  const client = useApolloClient();
  const { showError, showConfirmation, showMessage, showDrawer } = useContext(ApplicationContext);
  const { state } = useViewport();
  const { isRole } = usePrairieAuth();
  const [assigned, setAssigned] = useState<any>([]);
  const [backloged, setBackloged] = useState<any>([]);
  const [backlogDateRange, setBacklogDateRange] = useSessionStorage<any>('jobs-schedule-backlog-range-filter', [new Date(), new Date()]);
  const [assignedDateRange, setAssignedDateRange] = useSessionStorage<any>('jobs-schedule-assigned-range-filter', [new Date(), new Date()]);
  const [crewBacklog, setCrewBacklog] = useState(isRole(ROLE.FRANCHISE) ? +state.profile.id : null);
  const [assignedLoading, setAssignedLoading] = useState(false);
  const [backlogedLoading, setBacklogedLoading] = useState(false);
  const [assignAllLoading, setAssignAllLoading] = useState(false);
  const [assignedViewMode, setAssignedViewMode] = useSessionStorage('jobs-schedule-assigned-view-type', 'work_orders');
  const [timesOff, setTimesOff] = useState<any>([]);
  const [assignedModifiedAt, setAssignedModifiedAt] = useState(new Date().toISOString());
  const [mobileDisplay, setMobileDisplay] = useState<string>('backlog');
  const [showAssignAllModal, setShowAssignAllModal] = useState<boolean>(false);
  const [assignAllDate, setAssignAllDate] = useState<any>(new Date());
  const [assignGuid, setAssignGuid] = useState<string | undefined>(undefined);
  const [crews, setCrews] = useState([]);
  const [applicationGroup, setApplicationGroup] = useSessionStorage<any>('jobs-schedule-application-group', state.fields.filter((s: any) => s.parentKey === 'work_groups')?.[0]?.key);

  useEffect(() => {
    setCrews(state.users.filter((u: any) => IS_BUGABOO ? (u.userlevel === 'franchise' || u.userlevel === 'manager') : (u.userlevel === 'franchise')));
    // reset date range for user to use today on load
    if (isRole(ROLE.FRANCHISE)) {
      setBacklogDateRange([new Date(), new Date()]);
      setAssignedDateRange([new Date(), new Date()]);
    }
  }, []);

  useEffect(() => {
    // console.log(assignedDateRange);

    if (typeof (assignedDateRange) === 'string') {
      setAssignedDateRange([parseISO(assignedDateRange), parseISO(assignedDateRange)]);
    } else if (Array.isArray(assignedDateRange) && typeof (assignedDateRange[0]) === 'string') {
      setAssignedDateRange([parseISO(assignedDateRange[0]), parseISO(assignedDateRange[1])]);
    } else if (assignedDateRange === null) {
      setAssignedDateRange([new Date(), new Date()]);
    }
  }, [assignedDateRange])

  useEffect(() => {
    if (typeof (backlogDateRange) === 'string') {
      setBacklogDateRange([parseISO(backlogDateRange), parseISO(backlogDateRange)]);
    } else if (Array.isArray(backlogDateRange) && typeof (backlogDateRange[0]) === 'string') {
      setBacklogDateRange([parseISO(backlogDateRange[0]), parseISO(backlogDateRange[1])]);
    } else if (backlogDateRange === null) {
      setBacklogDateRange([new Date(), new Date()]);
    }
  }, [backlogDateRange])

  useEffect(() => {
    if (Array.isArray(backlogDateRange) && typeof (backlogDateRange[0]) !== 'string') {
      (async function getBacklogedWorkOrders() {
        try {
          setBacklogedLoading(true);
          setBackloged([]);

          // const whereFilter: any = getFilter('workOrders', filter, state.profile);

          const whereFilter = {
            AND: [
              { childrenCount: { is: 0 } },
              { status: { not_in: ['inactive', 'canceled'] } },
              {
                startDate: {
                  overlaps: {
                    with: 'end_date',
                    start: new Date(new Date(+backlogDateRange[0]).setHours(0, 0, 0)),
                    end: new Date(new Date(+backlogDateRange[1]).setHours(23, 59, 0))
                  }
                }
              },
              { applicationGroup: { is: applicationGroup } }
            ]
          };

          whereFilter.AND.push({ userId: { is: crewBacklog } } as any);


          const data = await client.query({
            query: queryWorkOrders,
            variables: {
              limit: 999,
              offset: 0,
              where: whereFilter,
              order: getSort('work-orders-schedule')
            },
            fetchPolicy: 'no-cache'
          });

          // maintenance for users should only show on demand
          if (applicationGroup === 'maintenance' && isRole(ROLE.FRANCHISE)) {
            setBackloged(data.data.workOrders.edges.node.filter((w: any) => w.recurrence === 'ondemand').map((e: any, sortOrder: number) => ({ ...e, sortOrder: sortOrder + 1 })));
          } else {
            setBackloged(data.data.workOrders.edges.node.map((e: any, sortOrder: number) => ({ ...e, sortOrder: sortOrder + 1 })));
          }


          DOMHelper.scrollTop(window as any, 0);
        } catch (err) {
          showError(err);
        }

        setBacklogedLoading(false)
      })();
    }
  }, [backlogDateRange, applicationGroup, crewBacklog]);

  useEffect(() => {
    (async function getTimesOff() {
      const data: any = await client.query({
        query: gql`
          query timesOff($limit: Int!, $offset: Int!) {
            timesOff(filter: {
              limit: $limit,
              offset: $offset
            }) {
              edges {
                node {
                  userId
                  startDate
                  endDate
                  notes
                  rrule
                }
              },
              totalCount
            }
          }`,
        variables: {
          offset: 0,
          limit: 999
        }
      });

      const tempTimesOff: any = [];
      orderBy(data.data.timesOff.edges.node, 'id', 'desc').forEach((t: any) => {
        if (!t.rrule) {
          tempTimesOff.push({ ...t, orgStartDate: t.startDate, orgEndDate: t.endDate });
        } else {
          const rule = RRule.fromString(t.rrule);
          const ruleDates = rule.all();

          ruleDates.forEach((r: any) => {
            tempTimesOff.push({ 
              ...t, 
              startDate: r.toISOString(), 
              endDate: ruleDates.length > 1 ? r.toISOString() : rule.options.until, 
              orgStartDate: 
              t.startDate, 
              orgEndDate: t.endDate 
            });
          })
        }
      });

      setTimesOff(tempTimesOff);
    })();

    if (Array.isArray(assignedDateRange) && typeof (assignedDateRange[0]) !== 'string') {
      (async function getAssignedWorkOrders() {
        try {
          setAssignedLoading(true);
          setAssigned([]);

          if (assignedViewMode === 'jobs' || crewBacklog || (['snow_removal', 'maintenance'].includes(applicationGroup)
            && isRole(ROLE.FRANCHISE, ROLE.WORKER))) {
            const whereFilter: any = {
              AND: [
                { userId: { not: null } },
                { workOrderStatus: { not_in: ['inactive', 'canceled'] } },
                { applicationGroup: { is: applicationGroup } },
                {
                  startDate: {
                    overlaps: {
                      with: 'end_date',
                      start: new Date(new Date(+assignedDateRange[0]).setHours(0, 0, 0, 0)),
                      end: new Date(new Date(+assignedDateRange[1]).setHours(23, 59, 59, 0))
                    }
                  }
                }
              ]
            };

            // only snow removal should force jobs view on crews
            if (['snow_removal'].includes(applicationGroup)) {
              whereFilter.AND.push({ userId: { is: crewBacklog || +state.profile.id } });
            }

            const data = await client.query({
              query: queryJobs,
              variables: {
                limit: 999,
                offset: 0,
                where: whereFilter,
                order: getSort('jobs')
              },
              fetchPolicy: 'no-cache'
            });

            setAssigned(data.data.jobs.edges.node.map((e: any, sortOrder: number) => ({ ...e, sortOrder: sortOrder + 1 })));
          } else {
            const whereFilter: any = {
              AND: [
                { userId: { not: null } },
                { status: { not_in: ['inactive', 'canceled'] } },
                { applicationGroup: { is: applicationGroup } },
                { endDate: { gte: new Date(new Date(+assignedDateRange[0]).setHours(0, 0, 0, 0)) } },
                { startDate: { lte: new Date(new Date(+assignedDateRange[1]).setHours(23, 59, 59, 0)) } }
              ]
            };

            const data = await client.query({
              query: queryWorkOrders,
              variables: {
                limit: 999,
                offset: 0,
                where: whereFilter,
                order: getSort('work-orders-schedule')
              },
              fetchPolicy: 'no-cache'
            });

            setAssigned(data.data.workOrders.edges.node.map((e: any, sortOrder: number) => ({ ...e, sortOrder: sortOrder + 1 })));
          }

          DOMHelper.scrollTop(window as any, 0);
        } catch (err) {
          showError(err);
        }

        setAssignedLoading(false)
      })();
    }
  }, [assignedDateRange, applicationGroup, crewBacklog, assignedModifiedAt, assignedViewMode]);

  const assignJob = async () => {
    const res = await client.mutate({
      mutation: createJobs,
      variables: {
        jobs: [
          {
            workId: backloged.find((b: any) => b.guid === assignGuid).id,
            workOrderGuid: assignGuid,
            startDate: assignAllDate
          }
        ]
      }
    });

    toaster.push(
      <Message type="success" showIcon closable>{res.data.createJobs.message}</Message>
    );
    setShowAssignAllModal(false);
  }

  const onDragEnd = async (result: DropResult) => {
    const { draggableId, source, destination }: any = result;
    const workOrder = backloged.find((w: any) => w.guid === draggableId);
    let newOrder: any = [];

    if (!destination || destination?.droppableId === source.droppableId) {
      if (destination?.droppableId === 'backlog') {
        newOrder = arrayMove(backloged, source.index, destination.index).map((a: any, sortOrder: number) => ({ ...a, sortOrder }));
        setBackloged(newOrder);
      } else {
        const userId = +(destination?.droppableId || '0-0').split('-')[1];
        const startOrder = Math.min(...assigned.filter((a: any) => a.userId === userId).map((a: any) => a.sortOrder));
        const userAssigned: any = arrayMove(assigned.filter((a: any) => a.userId === userId), source.index, destination.index)
          .map((a: any, index: number) => ({ ...a, sortOrder: startOrder + index }));
        const sortingGuids = userAssigned.map((e: any) => e.guid);
        newOrder = assigned
          .map((a: any) => {
            if (sortingGuids.includes(a.guid)) {
              return userAssigned.find((u: any) => u.guid === a.guid);
            }
            return a;
          })
          .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
          .map((a: any, sortOrder: number) => ({ ...a, sortOrder }));
        setAssigned(newOrder);
      }

      const response: any = await client.mutate({
        mutation: gql`
          mutation updateWorkOrders(
            $input: [UpsertWorkOrderInput]
          ) {
            updateWorkOrders(
              input: $input
            ) {
              success
              code
              message
            }
          }
        `,
        variables: { input: newOrder.map((a: any) => ({ guid: a.guid, sortOrder: a.sortOrder })) }
      });

      if (response.data.updateWorkOrders.success) {
        toaster.push(
          <Message type="success" showIcon closable>{response.data.updateWorkOrders.message}</Message>
        );
      } else {
        toaster.push(
          <Message type="error" showIcon closable>{response.data.updateWorkOrders.message}</Message>
        );
      }
      return;
    }

    if (destination?.droppableId === 'backlog') {
      // assigned -> backlog
      setAssigned(assigned.map((w: any) => ({ ...w, userId: (w.guid === draggableId ? null : w.userId) })));

      const item = assigned.find((b: any) => b.guid === draggableId);
      const tmp = [...backloged];

      tmp.splice(destination.index, 0, { ...item, userId: null });
      setBackloged(tmp);

      try {
        // snow removal deletes a job
        if (
          applicationGroup === 'snow_removal'
          || (applicationGroup === 'maintenance' && workOrder?.recurrence === 'ondemand' && workOrder?.userId !== null)
        ) {
          const res = await client.mutate({
            mutation: deleteJobs,
            variables: {
              jobs: [
                {
                  guid: draggableId
                }
              ]
            }
          });

          toaster.push(
            <Message type="success" showIcon closable>{res.data.deleteJobs.message}</Message>
          );
          setAssignedModifiedAt(new Date().toISOString());
        } else {
          const res = await client.mutate({
            mutation: updateWorkOrders,
            variables: {
              input: [{
                guid: draggableId,
                userId: null
              }]
            }
          });

          toaster.push(
            <Message type="success" showIcon closable>{res.data.updateWorkOrders.message}</Message>
          );
        }
      } catch (err) {
        showError(err);
      }
    } else {
      // backlog -> assigned
      const userId = +destination.droppableId.split('-')[1];
      const isAssigned = assigned.findIndex((a: any) => a.guid === draggableId);

      // only non snow work orders will have this as greater than 1 in which case just set the user
      if (isAssigned > -1) {
        const item = backloged.find((b: any) => b.guid === draggableId);
        setAssigned(assigned.map((w: any) => ({ ...w, userId: (w.guid === draggableId ? userId : w.userId) })));
      } else {
        if (['snow_removal', 'maintenance'].includes(applicationGroup)) {
          setBackloged(backloged.map((w: any) => ({ ...w, userId: (w.guid === draggableId ? null : w.userId) })));
        } else {
          setBackloged(backloged.map((w: any) => ({ ...w, userId: (w.guid === draggableId ? userId : w.userId) })));
        }

        const item = backloged.find((b: any) => b.guid === draggableId);

        if (isAfter(new Date(assignedDateRange[0].getFullYear(), assignedDateRange[0].getMonth(), assignedDateRange[0].getDate()), new Date(item.endDate))) {
          showMessage('Cannot assign job to a date outside of contract range.', 'Date Out Of Range');
          return;
        } else {
          const tmp = [...assigned];
          tmp.splice(destination.index, 0, { ...item, userId });
          setAssigned(tmp);
        }
      }

      try {
        // snow removal/maintenance on demand creates a new job
        if (
          applicationGroup === 'snow_removal'
          || (applicationGroup === 'maintenance' && workOrder?.recurrence === 'ondemand' && workOrder?.userId !== null)
        ) {
          const res = await client.mutate({
            mutation: createJobs,
            variables: {
              jobs: [
                {
                  workId: backloged.find((b: any) => b.guid === draggableId).id,
                  workOrderGuid: draggableId,
                  startDate: assignedDateRange[0]
                }
              ]
            }
          });

          if (res.data.createJobs.success) {
            toaster.push(
              <Message type="success" showIcon closable>{res.data.createJobs.message}</Message>
            );
          } else {
            toaster.push(
              <Message type="error" showIcon closable>{res.data.createJobs.message}</Message>
            );
          }
        } else {
          const item = assigned.find((b: any) => b.guid === draggableId);

          const res = await client.mutate({
            mutation: updateWorkOrders,
            variables: {
              input: [{
                guid: item?.workOrderGuid || draggableId,
                userId
              }]
            }
          });

          if (res.data.updateWorkOrders.success) {
            toaster.push(
              <Message type="success" showIcon closable>{res.data.updateWorkOrders.message}</Message>
            );
            setBackloged(backloged.filter((b: any) => b.guid !== draggableId));
          } else {
            toaster.push(
              <Message type="error" showIcon closable>{res.data.updateWorkOrders.message}</Message>
            );
          }
        }
      } catch (err) {
        showError(err);
      }
    }
  }

  const estimatedCost = (list: any) => {
    return list.reduce((prev: any, next: any) => {
      return prev + parseNumber(next.workOrderTotalCost || next.totalCost || next.perCostTypeCost || next.workOrderPerCostTypeCost);
    }, 0);
  }

  const getTimeOff = (userId: number) => {
    if (!timesOff || timesOff.length === 0) {
      return;
    }

    const timeOff = timesOff.filter((t: any) => t.userId === userId).map((t: any) => {
      if (Array.isArray(assignedDateRange)
        && (isBetween(parseISO(t.startDate), assignedDateRange[0], assignedDateRange[1])
          || isBetween(parseISO(t.endDate), assignedDateRange[0], assignedDateRange[1])
          || isBetween(assignedDateRange[0], parseISO(t.startDate), parseISO(t.endDate))
          || isBetween(assignedDateRange[0], parseISO(t.startDate), parseISO(t.endDate))
          || format(assignedDateRange[0], FORMAT.ISO_DATE) === format(parseISO(t.startDate), FORMAT.ISO_DATE)
          || format(assignedDateRange[1], FORMAT.ISO_DATE) === format(parseISO(t.endDate), FORMAT.ISO_DATE)
        )
      ) {
        return t;
      }
    }).filter((t: any) => t);

    return timeOff.length > 0 ? timeOff[0] : undefined;
  }

  const setApplication = (val: string) => {
    setApplicationGroup(val);

    if (!isRole(ROLE.FRANCHISE)) {
      setCrewBacklog(null);
    }
  }

  const assignAll = async (userId: number) => {
    const backloggedUserJobs = backloged.filter((item: any) => item.userId === userId && !isAfter(new Date(assignAllDate.getFullYear(), assignAllDate.getMonth(), assignAllDate.getDate()), new Date(item.endDate)));
    const backloggedUserJobsOutOfRange = backloged.filter((item: any) => isAfter(new Date(assignAllDate.getFullYear(), assignAllDate.getMonth(), assignAllDate.getDate()), new Date(item.endDate)));

    setAssignAllLoading(true);
    const res = await client.mutate({
      mutation: createJobs,
      variables: {
        jobs: backloggedUserJobs.map((j: any) => ({
          workId: j.workOrderId,
          workOrderGuid: j.guid,
          startDate: assignAllDate
        }))
      }
    });

    toaster.push(
      <Message type="success" showIcon closable>{res.data.createJobs.message}</Message>
    );
    setAssignedModifiedAt(new Date().toISOString())
    setShowAssignAllModal(false);
    setAssignAllLoading(false);

    if (backloggedUserJobsOutOfRange.length > 0) {
      showMessage(backloggedUserJobsOutOfRange.length.toString() + (backloggedUserJobsOutOfRange.length === 1 ? ' job was ' : ' jobs were ') + 'not assigned because the date falls outside of contract range.', 'Date Out Of Range');
    }
  }

  const clearAll = (userId: number) => {
    showConfirmation('Are you sure you want to clear assigned jobs?', 'Clear all jobs', async () => {
      const res = await client.mutate({
        mutation: deleteJobs,
        variables: {
          jobs: assigned.map((a: any) => ({ guid: a.guid }))
        }
      });

      toaster.push(
        <Message type="success" showIcon closable>{res.data.deleteJobs.message}</Message>
      );
      setAssignedModifiedAt(new Date().toISOString());
    });
  }

  const BookingDate = ({ startDate, endDate, startTime }: any) => {
    if (startDate && endDate && startTime) {
      let dates = <span>{format(parseISO(startDate), FORMAT.DAY_DATE) + ' to ' + format(parseISO(endDate), FORMAT.DAY_DATE)}</span>;
      if (format(parseISO(startDate), FORMAT.ISO_DATE) === format(parseISO(endDate), FORMAT.ISO_DATE)) {
        dates = <span>{format(parseISO(startDate), FORMAT.DAY_DATE)}</span>
      }

      if (startTime) {
        dates = <span>{dates} at <strong>{format(parseISO(startTime), FORMAT.TIME)}</strong></span>;
      }

      return dates;
    } else if (startDate) {
      return format(parseISO(startDate), FORMAT.DAY_DATE);
    }

    return '';
  }

  const CompletionStatus = ({ status }: any) => {
    switch (status) {
      case null:
      case 'not_completed':
        return <LegacyCloseCircleIcon style={{ color: '#d9534f' }} />;
      case 'completed':
        return <LegacyOkCircleIcon style={{ color: '#5cb85c' }} />;
      case 'canceled':
        return <LegacyExclamationCircleIcon style={{ color: '#f0ad4e' }} />;
    }

    return <span></span>;
  }

  const ItemRow = ({ data }: any) => (
    <div data-job-guid={data.guid}>
      <CompletionStatus status={data.status} />
      <span> &nbsp; </span>
      <a style={{ cursor: 'pointer' }} onClick={(e: any) => {
        e.preventDefault();
        showDrawer(DRAWER.CUSTOMER_VIEW, { customerId: data.customerId, workOrderGuid: data.workOrderParentGuid || data.workOrderGuid });
      }}>{data.customer.displayName}</a>
      <span> - </span>
      <a href={`/app/dev/workorder/edit/${data.parentGuid}`} onClick={(e: any) => {
        e.preventDefault();
        showDrawer(DRAWER.WORK_ORDER_FORM, { workOrderGuid: data.parentGuid, action: 'edit' });
      }}>
        <strong>{getFieldPrintable(state.fields, 'work_types', (data.workType || '').split(';'), data.otherServices, ', ')}</strong>
        <span>{(+data.occurrence > 0 && data.applicationGroup === 'maintenance') && <strong> x {data.occurrence}</strong>}</span>
      </a>
      <span> </span>{data.preferredCrew && <strong> / Preferred Crew </strong>}
      <Whisper placement="bottom" speaker={<Tooltip>Time Instructions: {data.timeInstructions ?? 'NA'}</Tooltip>}><span>
        booked for <a href="/" onClick={(e: any) => {
          e.preventDefault();
          showDrawer(DRAWER.JOB_LIST, { workOrderGuid: data.guid });
        }}>{BookingDate(data)}</a></span></Whisper>
      <span> at </span>
      <CopyToClipboard text={`${data.customer.displayAddress}, ${data.customer.city}, ${data.customer.postalCode}`} onCopy={() => toaster.push(<Message type="info" showIcon closable>Address copied</Message>)}>
        <span style={{ color: '#337ab7' }}>{data.customer.displayAddress}</span>
      </CopyToClipboard>
      {(data.props?.start_time && data.applicationGroup !== 'snow_removal') &&
        <>
          <span> </span>
          <Badge content={format(parseISO(data.startDate), FORMAT.TIME24)} />
        </>
      }
      {data.props?.preferred_crew === 1 &&
        <>
          <span> </span>
          <Badge content={'Preferred Crew'} />
        </>
      }

      {isMobileOnly && <strong> - <a href="/" onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setAssignGuid(() => data.guid);
        setTimeout(() => setShowAssignAllModal(true));
      }}>Assign</a></strong>}
    </div>
  );

  const TimeOff = ({ userId }: any) => {
    const timeOff = getTimeOff(userId);

    return timeOff
      ? <Message className="mb-5" style={{ padding: 0 }} type="error">{`Time off booked for
        ${format(parseISO(timeOff.orgStartDate), FORMAT.ISO_DATE) === format(parseISO(timeOff.orgEndDate), FORMAT.ISO_DATE)
          ? format(parseISO(timeOff.orgStartDate), FORMAT.DAY_MONTH_DATE)
          : format(parseISO(timeOff.orgStartDate), FORMAT.DAY_MONTH_DATE) + ' to ' + format(parseISO(timeOff.orgEndDate), FORMAT.DAY_MONTH_DATE)
        } ${timeOff.notes && ' - ' + timeOff.notes}`}</Message>
      : <span></span>
  }

  return <>
    {isMobileOnly &&
      <Nav className="mb-5" activeKey={mobileDisplay} onSelect={(val: string) => setMobileDisplay(val)} appearance="subtle" justified>
        <Nav.Item eventKey="backlog">Backlog</Nav.Item>
        <Nav.Item eventKey="assigned">Assigned</Nav.Item>
      </Nav>
    }

    <DragDropContext onDragEnd={onDragEnd}>
      <Grid fluid>
        <Row>
          <Col xs={24} md={12} xsHidden={!isMobileOnly || (isMobileOnly && mobileDisplay !== 'backlog')}>
            <Grid fluid>
              <Row>
                <Col xs={24} md={12}>
                  {!IS_APP &&
                    <>
                      {isRole(ROLE.FRANCHISE)
                        ? <div>
                          <Dropdown title={`${startCase(applicationGroup)} Backlog`} appearance='subtle'>
                            <Dropdown.Item onSelect={() => setApplication('maintenance')}>Maintenance</Dropdown.Item>
                            <Dropdown.Item onSelect={() => setApplication('snow_removal')}>Snow Removal</Dropdown.Item>
                          </Dropdown>
                          <span> </span>
                          <IconButton appearance="link" icon={<LegacyCloneIcon />} onClick={() => {
                            setAssignGuid(undefined);
                            setShowAssignAllModal(true);
                          }}>Assign all</IconButton>
                        </div>
                        : <Dropdown 
                            icon={crewBacklog ? <MdFolder /> : undefined}
                            appearance='subtle'
                            title={`${state.fields.filter((s: any) => s.parentKey === 'work_groups').find((a: any) =>a .key === applicationGroup)?.title || startCase(applicationGroup)} Backlog`}
                          >
                          {state.fields.filter((s: any) => s.parentKey === 'work_groups').map((a: any) => 
                            <Dropdown.Item key={a.key} onSelect={() => setApplication(a.key)}>{a.title}</Dropdown.Item>
                          )}
                        </Dropdown>
                      }
                    </>
                  }

                  {!isRole(ROLE.FRANCHISE, ROLE.WORKER) &&
                    <>
                      <SelectPicker
                        value={crewBacklog}
                        onChange={setCrewBacklog}
                        appearance="subtle"
                        data={crews.filter((c: any) => (c.workGroup || []).includes(applicationGroup))}
                        placeholder="Crew"
                        valueKey="id"
                        labelKey="operatorName"
                      />

                      <Dropdown appearance='subtle' icon={<MdMoreVert />}>
                        <Dropdown.Item onSelect={() => {
                          showDrawer(DRAWER.EMAIL_FORM, {
                            resources: backloged,
                            group: 'work_order',
                            type: 'work_order'
                          }, () => { });
                        }}>Email List</Dropdown.Item>
                      </Dropdown>
                    </>
                  }

                  {(crewBacklog && !isRole(ROLE.FRANCHISE)) &&
                    <Whisper placement="bottom" speaker={<Tooltip>Assign all</Tooltip>}>
                      <IconButton appearance="link" icon={<LegacyCloneIcon />} onClick={() => {
                        setAssignGuid(undefined);
                        setShowAssignAllModal(true);
                      }} />
                    </Whisper>
                  }
                </Col>
                <Col xs={24} md={11}>
                  {(Array.isArray(backlogDateRange) && typeof (backlogDateRange[0]) !== 'string') &&
                    <DateRangeFilter
                      dateRange={backlogDateRange as any}
                      cleanable={false}
                      showOneCalendar={isMobileOnly}
                      handleDateRangeChange={setBacklogDateRange}
                      handleFilterToggle={() => { }}
                      showFilter={false}
                      handleDateRangeColumnChange={() => { }}
                      appearance='subtle'
                    />
                  }
                </Col>
              </Row>
            </Grid>
            <div style={{ width: '97%' }}>
              {backlogedLoading
                ? <Loader content="Loading..." />
                : <Droppable droppableId="backlog" isDropDisabled={isMobileOnly}>
                  {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                      className={isMobileOnly ? '' : 'scrollable'}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        ...getListStyle(snapshot.isDraggingOver),
                        maxHeight: isMobileOnly ? 'auto' : '80vh',
                        minHeight: isMobileOnly ? 'auto' : '80vh',
                        overflow: isMobileOnly ? 'auto' : 'hidden',
                        overflowY: isMobileOnly ? 'auto' : 'scroll'
                      }}
                    >
                      {isMobileOnly && <Message type="info" className="mb-10">Click job to assign single job</Message>}
                      {backloged
                        .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
                        .map((row: any, index: number) => (
                          <Draggable key={row.guid} draggableId={row.guid} index={index} isDragDisabled={isMobileOnly}>
                            {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                              <div
                                className="hover"
                                ref={providedDraggable.innerRef}
                                {...providedDraggable.draggableProps}
                                {...providedDraggable.dragHandleProps}
                                style={getItemStyle(
                                  providedDraggable.draggableProps.style,
                                  snapshotDraggable.isDragging
                                )}
                              >
                                <ItemRow data={row} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              }
            </div>
          </Col>
          <Col xs={24} md={12} xsHidden={!isMobileOnly || (isMobileOnly && mobileDisplay !== 'assigned')}>
            <Grid fluid className="p-0">
              <Row>
                <Col xs={12} md={13}>
                  <div style={{ color: '#8e8e93' }}>
                    Assigned
                    {(applicationGroup === 'maintenance' && isRole(ROLE.ADMIN, ROLE.MANAGER)) &&
                      <Fragment>
                        <span> </span>
                        <Dropdown title={startCase(assignedViewMode)} appearance='subtle'>
                          <Dropdown.Item onSelect={() => setAssignedViewMode('work_orders')}>Work Orders</Dropdown.Item>
                          <Dropdown.Item onSelect={() => setAssignedViewMode('jobs')}>Jobs</Dropdown.Item>
                        </Dropdown>
                      </Fragment>
                    }
                    <span> </span>
                    {(crewBacklog && applicationGroup === 'snow_removal') &&
                      <IconButton appearance="link" icon={<LegacyTrashIcon />} onClick={() => clearAll(+state.profile.id)}>Clear all</IconButton>
                    }
                  </div>
                </Col>
                {(Array.isArray(assignedDateRange) && typeof (assignedDateRange[0]) !== 'string') &&
                  <Col xs={12} md={11}>
                    {(['snow_removal', 'maintenance'].includes(applicationGroup) && crewBacklog !== null)
                      ? <div style={{ textAlign: 'right' }}>
                        <IconButton appearance="link" onClick={() => setAssignedDateRange([addDays(assignedDateRange[0], -1), addDays(assignedDateRange[0], -1)])} icon={<LegacyChevronLeftIcon />} />
                        <DatePicker
                          cleanable={false}
                          appearance="subtle"
                          value={assignedDateRange[0]}
                          onChange={(val) => setAssignedDateRange([val, val])}
                          placement='bottomEnd'
                          oneTap={true}
                        />
                        <IconButton appearance="link" onClick={() => setAssignedDateRange([addDays(assignedDateRange[0], 1), addDays(assignedDateRange[0], 1)])} icon={<LegacyChevronRightIcon />} />
                      </div>
                      : <DateRangeFilter
                        dateRange={assignedDateRange as any}
                        cleanable={false}
                        showOneCalendar={isMobileOnly}
                        handleDateRangeChange={setAssignedDateRange}
                        handleFilterToggle={(val) => { }}
                        showFilter={false}
                        handleDateRangeColumnChange={(val) => { }}
                        appearance='subtle'
                        placement='bottomEnd'
                      />
                    }
                  </Col>
                }
              </Row>
            </Grid>
            {assignedLoading
              ? <Loader content="Loading..." />
              :
              <div className="scrollable" style={{ minHeight: '80vh', maxHeight: '80vh', overflow: 'hidden', overflowY: 'scroll' }}>
                {/* snow removal is also filtered by selected crew */}
                {crews
                  .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
                  .filter((c: any) => (c.workGroup || []).includes(applicationGroup) && (crewBacklog === null || (crewBacklog !== null && crewBacklog === c.value)))
                  .map((c: any) =>
                    <div key={`user-${c.id}`} className={'mb-6'}>
                      {crewBacklog === null &&
                        <ButtonToolbar>
                          <Button className="no-hover" appearance="subtle">{c.operatorName} {moneyFormatter.format(estimatedCost(assigned.filter((w: any) => w.userId === +c.value)))}</Button>
                          {!isRole(ROLE.FRANCHISE, ROLE.WORKER) &&
                            <Dropdown icon={<MdMoreVert />} appearance='subtle'>
                              <Dropdown.Item onSelect={() => {
                                showDrawer(DRAWER.EMAIL_FORM, {
                                  resources: assigned.filter((w: any) => w.userId === +c.id),
                                  type: 'work_order',
                                  group: 'work_order'
                                }, () => { });
                              }}>Email List</Dropdown.Item>
                            </Dropdown>
                          }
                        </ButtonToolbar>
                      }
                      <div style={{ width: '97%' }}>
                        <Droppable droppableId={`assigned-${c.id}`}>
                          {(providedDroppable2: DroppableProvided, snapshotDroppable2: DroppableStateSnapshot) => (
                            <div
                              ref={providedDroppable2.innerRef}
                              style={getListStyle(snapshotDroppable2.isDraggingOver)}
                            >
                              <TimeOff userId={c.id} />
                              {assigned.filter((w: any) => w.userId === +c.id).map((row: any, index: number) => (
                                <Draggable key={row.guid} draggableId={row.guid} index={index}>
                                  {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                                    <div
                                      ref={providedDraggable.innerRef}
                                      {...providedDraggable.draggableProps}
                                      {...providedDraggable.dragHandleProps}
                                      style={getItemStyle(
                                        providedDraggable.draggableProps.style,
                                        snapshotDraggable.isDragging
                                      )}
                                    >
                                      <ItemRow data={row} />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {providedDroppable2.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  )}
              </div>
            }
          </Col>
        </Row>
      </Grid>
    </DragDropContext>

    <Modal backdrop="static" open={showAssignAllModal} onClose={() => setShowAssignAllModal(false)} size="xs">
      <Modal.Header><Modal.Title>Assign {assignGuid ? 'job' : 'all jobs'}</Modal.Title></Modal.Header>
      <Modal.Body>
        <label>Select date for job assignment:</label>
        <DatePicker
          value={assignAllDate}
          onChange={setAssignAllDate}
          cleanable={false}
          block
          oneTap={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={assignAllLoading}
          onClick={() => setShowAssignAllModal(false)} appearance="subtle">Cancel</Button>
        <Button
          loading={assignAllLoading}
          onClick={() => {
            if (assignGuid) {
              assignJob();
            } else {
              assignAll(crewBacklog || +state.profile.id);
            }
            setAssignedDateRange([assignAllDate, assignAllDate]);
          }} appearance="primary">OK</Button>
      </Modal.Footer>
    </Modal>
  </>;
};

export default JobsSchedule;
