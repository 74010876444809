import { gql } from "@apollo/client";

const queryWorkOrder = gql`
  query workOrder($guid: ID) {
    workOrder(guid:$guid) {
      id
      guid
      workType
      jobType
      services
      otherServices
      applicationGroup
      startDate
      endDate
      startTime
      endingDate
      parentGuid
      customerId
      billingCustomerId
      userId
      workerId
      contractNotes
      contractValue
      contractType
      perCostTypeCost
      costType
      totalCost
      documentId
      documentGuid
      timeInstructions
      workDescription
      recurrence
      jobsScheduled
      customerComments
      occurrence
      preferredCrew
      props
      payoutPercentage
      reviewStatus
      status
      repeatOn
      sortOrder
      visitsIncluded
      servicesInVisit
      specialInstructions
      bookAs
      canceledReason
      deletedReason
      customFields
      attachments {
        guid
        fileName
        filePath
        fileId
        visibility
        type
        description
      }
      children {
        id
        guid
        workType
        jobType
        services
        otherServices
        applicationGroup
        startDate
        endDate
        endingDate
        startTime
        parentGuid
        customerId
        billingCustomerId
        userId
        preferredCrew
        customerComments
        workerId
        contractNotes
        contractValue
        contractType
        perCostTypeCost
        costType
        totalCost
        documentId
        props
        occurrence
        payoutPercentage
        reviewStatus
        isCanceled
        sortOrder
        status
        timeInstructions
        workDescription
        recurrence
        jobsScheduled
        repeatOn
        visitsIncluded
        servicesInVisit
        bookAs
        specialInstructions
        canceledReason
        deletedReason
        customFields
      }
      takedown {
        id
        guid
        workType
        jobType
        applicationGroup
        startDate
        endDate
        endingDate
        parentGuid
        customerId
        billingCustomerId
        userId
        workerId
        contractNotes
        contractType
        perCostTypeCost
        costType
        totalCost
        documentId
        props
        payoutPercentage
        reviewStatus
        isCanceled
        status
        timeInstructions
        workDescription
        specialInstructions
        canceledReason
        deletedReason
        customFields
      }
      user {
        guid
        contactEmail
        operatorName
        color
      }
    }
  }
`;


const queryWorkOrdersGrouped = gql`
  query workOrdersGrouped(
    $limit: Int!
    $offset: Int!
    $where: WorkOrdersWhere
    $sort: WorkOrdersSort
    $order: [WorkOrdersSort]
  ) {
    workOrdersGrouped(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          customerId
          customer {
            guid
            contactDetails
            customerName
            displayName
            displayAddress
            lat
            lng
            email
            city
            postalCode
          }
          workOrders {
            guid
            parentGuid
            applicationGroup
            workType
            services
            totalCost
            perCostTypeCost
            startDate
            endDate
          }
        }
      },
      totalCount
    }
  }
`;

const queryWorkOrders = gql`
  query workOrders(
    $limit: Int!
    $offset: Int!
    $where: WorkOrdersWhere
    $sort: WorkOrdersSort
    $order: [WorkOrdersSort]
  ) {
    workOrders(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          id
          guid
          startDate
          endDate
          endingDate
          startTime
          workOrderId
          parentId
          applicationGroup
          workType
          jobType
          services
          otherServices
          totalCost
          perCostTypeCost
          costType
          createdAt
          modifiedAt
          lastEmail
          parentGuid
          customerName
          customerId
          customerComments
          workerId
          isComplete
          status
          userId
          preferredCrew
          timeInstructions
          workDescription
          contractNotes
          contractValue
          contractType
          recurrence
          jobsScheduled
          jobsCompleted
          occurrence
          repeatOn
          preferredCrew
          props
          payoutPercentage
          reviewStatus
          visitsIncluded
          servicesInVisit
          sortOrder
          bookAs
          totalTimeSpent
          createdByOperatorName
          spmh
          monthsDuration
          customer {
            guid
            contactDetails
            customerName
            displayName
            displayAddress
            lat
            lng
            email
            city
            postalCode
          }
          billing {
            email
          }
          jobs {
            guid
            status
            startDate
            completedByCrewName
          }
          user {
            id
            guid
            contactEmail
            operatorName
            color
          }
          documents {
            title
            guid
            startDate
            endDate
          }
        }
      },
      totalCount
    }
  }
`;

const updateWorkOrders = gql`
  mutation updateWorkOrders(
    $input: [UpsertWorkOrderInput]
  ) {
    updateWorkOrders(
      input: $input
    ) {
      success
      code
      message
      result
    }
  }
`;

const queryWorkLog = gql`
  query workOrder($guid: ID) {
    workOrder(guid:$guid) {
      id
      guid
      workType
      jobType
      services
      otherServices
      applicationGroup
      startDate
      endDate
      parentGuid
      customerId
      billingCustomerId
      userId
      workerId
      contractNotes
      contractValue
      contractType
      perCostTypeCost
      costType
      totalCost
      documentId
      timeInstructions
      workDescription
      props
      preferredCrew
      payoutPercentage
      reviewStatus
      status
      repeatOn
      visitsIncluded
      servicesInVisit
      customer {
        email
        displayName
        displayAddress
        guid
        contactDetails
        customerName
        lat
        lng
      }
      billing {
        email
      }
      user {
        id
        operatorName
        contactNumber
        contactEmail
        businessNumber
        wcbNumber
        percentageOfNet
      }
      attachments {
        guid
        fileName
        filePath
        fileId
        fileGroup
        visibility
        type
        description
      }
      invoices {
        guid
        invoiceNumber
        items {
          guid
          description
          cost
          costGroup
          sortOrder
          deletedAt
        }
      }
      documents {
        guid
        additionalNotes
        customerComments
        services {
          description
          cost
          sortOrder
          isVisible
        }
      }
      revenue {
        guid
        dateOnCalendar
        description
        cost
      }
      expenses {
        guid
        dateOnCalendar
        cost
        description
        whoPaid
        expenseFieldTitle
        expenseFieldKey
        receiptGuid
        receiptFile
      }
      labor {
        guid
        employeeId
        employeeName
        hoursWorked
        hourlyPay
        totalPaid
        dateOnCalendar
      }
      advances {
        guid
        description
        cost
        dateOnCalendar
      }
      parent {
        attachments {
          guid
          fileName
          filePath
          fileId
          fileGroup
          visibility
          type
          description
        }
      }
    }
  }
`;

const queryReportWorkOrders = gql`
  query workOrders($limit: Int!, $offset: Int!, $where: WorkOrdersWhere $sort: WorkOrdersSort, $order: [WorkOrdersSort]) {
    workOrders(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          id
          guid
          startDate
          endDate
          endingDate
          workOrderId
          parentId
          applicationGroup
          workType
          jobType
          services
          otherServices
          totalCost
          perCostTypeCost
          costType
          createdAt
          modifiedAt
          lastEmail
          parentGuid
          customerName
          customerId
          preferredCrew
          workerId
          isComplete
          status
          userId
          timeInstructions
          workDescription
          contractNotes
          contractValue
          contractType
          recurrence
          jobsScheduled
          props
          payoutPercentage
          reviewStatus
          repeatOn
          operatorName
          totalTimeSpent
          monthsDuration
          jobs {
            guid
            startDate
            endDate
            perCostTypeCost
            workOrderPerCostTypeCost
            timeOnSite
            timeFromSite
            peopleOnSite
            props
            totalCost
            services
            otherServices
            status
            timeSpent
            completedServices
            spmh
            timeCard {
              guid
              timeOnSite
              timeFromSite
              timeSpent
              jobGuid
              peopleOnSite
              userGuid
            }
          }
        }
      },
      totalCount
    }
  }
`;

const queryWorkOrderCutDays = gql`
  query workOrderCutDays($customerId: Int!, $date: DateTime!) {
    workOrderCutDays(customerId:$customerId, date:$date) {
      repeatOn
    }
  }
`;

export {
  queryWorkOrder,
  queryWorkOrders,
  queryWorkOrders as GET_WORK_ORDERS,
  queryWorkOrdersGrouped,
  queryWorkLog,
  queryReportWorkOrders,
  updateWorkOrders,
  queryWorkOrderCutDays
}
