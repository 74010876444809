import { useEffect, useState, Fragment, useContext, useRef, useMemo } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useApolloClient, gql, useLazyQuery, useQuery } from '@apollo/client';

import {
  Table,
  Whisper,
  Tooltip,
  IconButton,
  Badge,
  SelectPicker,
  Button,
  toaster,
  Message,
  DOMHelper,
  Panel,
  Animation,
  ButtonToolbar,
  PanelGroup,
  Dropdown,
  Drawer,
  Loader,
  Popover,
  Input,
  Col,
  CheckboxGroup,
  Checkbox,
  Form,
  CheckPicker,
} from 'rsuite';

import { DRAWER, FORMAT, getEnv, getSortType, parseNumber, ROLE, setHeaderTitle } from 'lib/env';
import { Filter, Pagination, DateRangeDisplay, ApplicationContext, ResponsiveTable, PopoverDropdownMenu } from 'shared';
import { useFilteredParams } from 'shared/FilterProvider';
import WorkOrderStatus from './components/Status';
import without from 'lodash/without';
import { getFilter } from 'lib/helpers/filter';
import { flatten, get, orderBy, round, startCase, uniq } from 'lodash';
import { isBrowser, isMobileOnly } from 'react-device-detect';
import IconButtonWrapper from 'shared/IconButtonWrapper';
import { parseISO, format } from 'date-fns';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { IS_BUGABOO, IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES, moneyFormatter } from 'lib/tenant';
import INTL from 'tenant/intl';
import { useViewport } from 'shared/ViewportProvider';
import { queryWorkOrders, updateWorkOrders } from 'gql/workOrders';
import { fieldTitle, filterFieldsByParentKey } from 'lib/helpers/field';
import TextareaAutosize from 'react-autosize-textarea';
import { ExportField, MapToggleField } from 'components/form';
import { getSort } from 'lib/helpers/sort';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import store from 'lib/storage';
import { CONFIG_STATUS } from 'tenant/config';
import { useQueryString } from 'lib/hooks';
import { MdAdd, MdCheckBoxOutlineBlank, MdEco, MdEdit, MdHelpOutline, MdOutlineCheckBox } from 'react-icons/md';
import { ColumnChooserField } from 'components/form/FilterFields';
import Icon from '@rsuite/icons/lib/Icon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getAverageSPMH } from 'lib/financials';
const { Column, HeaderCell, Cell } = Table;

const initialColumnEditing: { column: string | undefined, guid: string | undefined, value: any } = {
  column: undefined,
  guid: undefined,
  value: undefined
};

interface IWorkOrderList {
  headless?: boolean,
  customerId?: number,
  linkedCustomers?: [number],
  showBreadCrumb?: boolean,
  onSelectedRows?: (selectedRows: any) => void,
  groupBy?: string | undefined
}

const WorkOrderList = ({
  headless,
  customerId,
  showBreadCrumb,
  groupBy,
  onSelectedRows,
  linkedCustomers
}: IWorkOrderList) => {
  setHeaderTitle('Work Orders');
  const filterKey = 'workOrders' + (customerId === undefined ? '' : 'Customer');
  const match = useRouteMatch();
  const client = useApolloClient();
  const query: any = useQueryString();
  const { state } = useViewport();
  const { showError, showConfirmation, showDrawer, showMessage, showSuccess } = useContext(ApplicationContext);
  const { params } = useFilteredParams(filterKey);
  const { isRole, can } = usePrairieAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [workOrderEvent, setWorkOrderEvent] = useState<any>({ action: undefined, guid: undefined });
  const [offset, setOffset] = useState(0);
  const [workOrders, setWorkOrders] = useState<any>([]);
  const [activeWorkOrderGuid, setActiveWorkOrderGuid] = useState(null);
  const [filter, setFilter] = useState(params);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedTableRows, setSelectedTableRows] = useState<string[]>([]);
  const [showAssignWorkerModal, setShowAssignWorkerModal] = useState(false);
  const [selectedGlobalWorker, setSelectedGlobalWorker] = useState<any>('');
  const [selectedGlobalCrew, setSelectedGlobalCrew] = useState<any>(undefined);
  const [selectedGlobalStatus, setSelectedGlobalStatus] = useState<any>(undefined);
  const [selectedGlobalCostType, setSelectedGlobalCostType] = useState<any>(undefined);
  const [selectedGlobalServices, setSelectedGlobalServices] = useState<any>(undefined);
  const [recordsPerPage, setRecordsPerPage] = useLocalStorage('recordsPerPage', 20);
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [sort, setSort] = useLocalStorage<any>(`${filterKey}-sort`, { sortColumn: '', sortType: undefined });
  const workers = state.users.filter((u: any) => u.userlevel === 'worker');
  const crews = state.users.filter((u: any) => u.userlevel === 'franchise');
  const fieldServices = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => !w.visibleOnBooking);
  const bookingServices = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnBooking);
  const serviceKeys = filterFieldsByParentKey(state.fields, 'work_types').filter((w: any) => w.visibleOnBooking).map((w: any) => w.key);
  const workGroupTypes = filterFieldsByParentKey(state.fields, 'work_groups');
  const tableDataRef = useRef();
  let selectedGlobalRepeatOn: any = [];
  // const [getWorkOrder, getWorkOrderResult] = useLazyQuery(queryWorkOrders, { fetchPolicy: 'network-only' });

  const workOrdersResult = useQuery(queryWorkOrders, {
    variables: {
      limit: customerId ? 999 : recordsPerPage,
      offset: offset,
      where: getFilter(filterKey, { ...filter, viewPortLinkedCustomers: linkedCustomers, viewPortQueryId: query.id }, state.profile),
      order: sort.sortType !== undefined ? [{ [sort.sortColumn]: sort.sortType.toUpperCase() }] : getSort('work-orders', { groupBy })
    }
  });

  // beta: inline edit
  const [columnEditing, setColumnEditing] = useState(initialColumnEditing);
  showBreadCrumb = typeof (showBreadCrumb) === 'undefined' ? true : false;

  useEffect(() => {
    setWorkOrders(workOrdersResult?.data?.workOrders?.edges?.node || []);
  }, [workOrdersResult?.data?.workOrders?.edges?.node]);

  useEffect(() => {
    window.document.addEventListener('keydown', escFunction, false);

    return () => {
      window.document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (onSelectedRows) {
      onSelectedRows(selectedTableRows);
    }
  }, [selectedTableRows]);

  useEffect(() => {
    // let order = JSON.parse(store.session.get(`${filterKey}-order`, '[]'));
    // if (order.length === 0) {
    //   order = getSort('work-orders');
    // }

    (async function getWorkOrders() {
      try {
        setSelectedTableRows([]);

        // const data: any = await client.query({
        //   query: queryWorkOrders,
        //   variables: {
        //     limit: customerId ? 999 : recordsPerPage,
        //     offset: offset,
        //     where: whereFilter,
        //     order: sort.sortType !== undefined ? [{ [sort.sortColumn]: sort.sortType.toUpperCase() }] : order
        //   },
        //   fetchPolicy: 'no-cache'
        // });
        // getWorkOrder({
        //   variables: {
        //     limit: customerId ? 999 : recordsPerPage,
        //     offset: offset,
        //     where: whereFilter,
        //     order: sort.sortType !== undefined ? [{ [sort.sortColumn]: sort.sortType.toUpperCase() }] : order
        //   }
        // });

        // if (customerId && !sort.sortType) {
        //   setWorkOrders(orderBy(data.data.workOrders.edges.node, 'endDate', 'desc'));
        // } else {
        //   setWorkOrders(data.data.workOrders.edges.node);
        // }

        // setTotalCount(data.data.workOrders.totalCount);
        DOMHelper.scrollTop(window as any, 0);
      } catch (err) {
        showError(err);
      }

      setLoading(false)
    })();
  }, [offset, filter, client, recordsPerPage, modifiedAt, sort, state.modifiedAt]);

  const escFunction = (event: any) => {
    if (event.keyCode === 27) {
      setColumnEditing(initialColumnEditing);
    }
  }

  const handleWorkOrderUpdate = async (workOrderGuid: string, parentGuid: string, data: any) => {
    const update: any = {};

    try {
      if (data.workerId) {
        const worker = workers.find((c: any) => c.id === data.workerId);
        const user = state.users.find((c: any) => c.id === worker.workingUnderId);

        setWorkOrders(workOrders.map((w: any) => {
          if (w.guid === workOrderGuid) {
            // notes
            if (data.workerId) {
              w.userId = user.id;
              w.workerId = data.workerId;

              if (w.user) {
                w.user.operatorName = user.label;
              }

              update.workerId = w.workerId;
              update.userId = w.userId;
            }
          }

          return w;
        }));
      } else {
        setWorkOrders(workOrders.map((w: any) => {
          if (w.guid === workOrderGuid) {
            if (data.hasOwnProperty('workDescription')) {
              w.workDescription = data.workDescription;
              update.workDescription = data.workDescription;
            }

            if (data.hasOwnProperty('contractNotes')) {
              w.contractNotes = data.contractNotes;
              update.contractNotes = data.contractNotes;
            }

            if (data.hasOwnProperty('perCostTypeCost')) {
              w.perCostTypeCost = parseNumber(data.perCostTypeCost);
              update.perCostTypeCost = parseNumber(data.perCostTypeCost);
            }

            if (data.hasOwnProperty('costType')) {
              w.costType = data.costType;
              update.costType = data.costType;
            }

            if (data.hasOwnProperty('contractValue')) {
              w.contractValue = parseNumber(data.contractValue);
              update.contractValue = parseNumber(data.contractValue);
            }

            if (data.hasOwnProperty('totalCost')) {
              w.totalCost = parseNumber(data.totalCost);
              update.totalCost = parseNumber(data.totalCost);
            }

            if (data.hasOwnProperty('repeatOn')) {
              w.repeatOn = data.repeatOn;
              update.repeatOn = data.repeatOn;
            }

            if (data.hasOwnProperty('props.deicerLineItem.pricePerKg')) {
              // TODO: test this out for error reporting
              // w.props.deicerLineItem.pricePerKg = data['props.deicerLineItem.pricePerKg'];
              // update.props.deicerLineItem.pricePerKg = data['props.deicerLineItem.pricePerKg'];
            }

            if (data.hasOwnProperty('userId')) {
              w.user = crews.find((c: any) => c.id === data.userId);
              w.userId = data.userId;
              update.userId = data.userId;

              const workingUnder = workers.filter((worker: any) => +worker.workingUnderId === +data.userId);

              if (!workingUnder.map((wu: any) => wu.id).includes(w.workerId)) {
                w.workerId = null;
                update.workerId = null;
                toaster.push(<Message type='info' showIcon closable>Worker not found under assigned user</Message>);
              }
            }
          }
          return w;
        }));
      }

      const res = await client.mutate({
        mutation: updateWorkOrders,
        variables: {
          input: [{
            ...update,
            parentGuid: parentGuid,
            guid: workOrderGuid,
          }]
        }
      });

      if (res.data.updateWorkOrders.success) {
        toaster.push(
          <Message type="success" showIcon closable>{res.data.updateWorkOrders.message}</Message>
        );

        setWorkOrders(workOrders.map((workOrder: any) => {
          return workOrder;
        }));
      } else {
        showError(new Error(res.data.updateWorkOrders.message));
      }
    } catch (err) {
      showError(err);
    }
  }

  const handleAssignAllWorkerId = async (
    workerId: number | undefined,
    userId: number | undefined,
    selectedGlobalStatus: string | undefined,
    selected: string[],
    selectGlobalCostType: string | undefined,
    selectedGlobalServices: [string] | undefined
  ) => {
    try {
      let res: any = {};
      let user: any = {};

      if (workerId) {
        const worker = workers.find((c: any) => c.id === workerId);
        user = state.users.find((c: any) => c.id === worker.workingUnderId);

        res = await client.mutate({
          mutation: updateWorkOrders,
          variables: {
            input: selected.filter((s: any) => s.indexOf('all') === -1).map((g) => ({
              guid: g,
              workerId,
              userId: user.value,
              status: selectedGlobalStatus,
              costType: selectGlobalCostType,
              services: selectedGlobalServices
            }))
          }
        });
      } else {
        user = state.users.find((c: any) => c.id === userId);

        res = await client.mutate({
          mutation: updateWorkOrders,
          variables: {
            input: selected.filter((s: any) => s.indexOf('all') === -1).map((g) => ({
              guid: g,
              userId: userId ? userId : undefined,
              workerId: null,
              status: selectedGlobalStatus,
              costType: selectGlobalCostType,
              services: selectedGlobalServices
            }))
          }
        });
      }

      showSuccess(res.data.updateWorkOrders.message);
      setShowAssignWorkerModal(false);
      workOrdersResult.refetch();
    } catch (err) {
      showError(err);
    }
  }

  const handleTableSelectRow = (val: any, year: number | undefined = undefined) => {
    let selectedRows = ([] as string[]).concat(selectedTableRows);

    if (val === ('all' + (year ? year : ''))) {
      if (selectedRows.indexOf(val) > -1) {
        selectedRows = [];
      } else {
        selectedRows = workOrders
          .filter((w: any) => year ? +format(parseISO(w[groupBy || 'endDate']), 'y') === year : true)
          .map((w: any) => w.guid)
          .concat('all' + (year ? year : ''));
      }
    } else {
      if (selectedRows.indexOf(val) > -1) {
        selectedRows = without(selectedRows, val);
        selectedRows = without(selectedRows, 'all' + (year ? year : ''));
      } else {
        selectedRows.push(val);
      }
    }

    setSelectedTableRows(selectedRows);
  }

  const handleRepeatWorkOrders = (selected: any) => {
    showDrawer(DRAWER.WORK_ORDER_REPEAT, { jobGuids: selected.filter((s: any) => s.length === 36) }, () => workOrdersResult.refetch());
  }

  const getWorkTypes = () => {
    return [...new Set(workOrders.filter((w: any) => selectedTableRows.includes(w.guid)).map((w: any) => w.applicationGroup))];
  }

  const handleDeleteWorkOrders = async (selected: any) => {
    const response: any = await Promise.all(selected.filter((s: any) => s.indexOf('all') === -1).map(async (guid: any) =>
      client.mutate({
        mutation: gql`mutation deleteWorkOrder($guid: ID!) { deleteWorkOrder(guid: $guid) { success, code, message }}`,
        variables: { guid }
      }))
    );

    if (response.length > 0 && response[0].data.deleteWorkOrder.success) {
      toaster.push(
        <Message type="success" showIcon closable>{response[0].data.deleteWorkOrder.message}</Message>
      );
      setModifiedAt(new Date().toISOString());
      setSelectedTableRows([]);
    }
  }

  const isExpanded = (year: number) => {
    return year === new Date().getFullYear() || year === new Date().getFullYear() - 1 || (year === new Date().getFullYear() + 1 && new Date().getMonth() >= 8);
  }

  const findServiceTemplateGuid = (services: any) => {
    if (services.length > 0) {
      const field = state.fields.find((f: any) => f.key === services[0]);
      return field?.emailTemplateGuid;
    }

    return undefined;
  }

  const handleGroupWorkOrders = (selected: any) => {
    const groups = uniq(workOrders.filter((w: any) => selected.includes(w.guid)).map((s: any) => s.applicationGroup));
    if (groups.length > 1) {
      toaster.push(
        <Message type="error" showIcon closable>Only work orders of same work group can be grouped</Message>
      );
      return;
    }

    showDrawer(DRAWER.WORK_ORDER_REPEAT, { jobGuids: selected.filter((s: any) => s.length === 36), group: true }, () => workOrdersResult.refetch());
  }

  const handleShareWorkOrder = async (guid: string) => {
    try {
      const shareResource = gql`
        mutation shareResource($resource: String!, $guid: ID!) {
          shareResource(resource: $resource, guid: $guid) {
            success
            code
            message
          }
        }`;

      const response: any = await client.mutate({
        mutation: shareResource, variables: { resource: 'work-order', guid }
      });

      const doc = workOrders.find((w: any) => w.guid === guid);

      if (response.data.shareResource.code === 200) {
        showMessage(
          <div>
            <CopyToClipboard text={response.data.shareResource.message} onCopy={() => toaster.push(<Message type="info" showIcon closable>Copied</Message>)}>
              <div>
                Click to copy public share link:<br /><strong>{response.data.shareResource.message}</strong>
              </div>
            </CopyToClipboard>
          </div>, `Share ${doc.customer.displayName} ${doc.title || ''} ${startCase(doc.entryType)}`);
      } else {
        showError(response.data.shareResource.message);
      }
    } catch (error) {
      showError(error);
    }
  }

  const getGroupByFilter = (workOrder: any, groupBy: string) => {
    if (groupBy.toLowerCase().includes('date')) {
      return +format(parseISO(workOrder[groupBy || 'endDate']), 'y');
    }

    return get(workOrder, groupBy);
  }

  const renderTable = (data: any, year: number | undefined = undefined) => {
    return (
      <ResponsiveTable
        key={selectedTableRows.join('_')}
        loading={loading || workOrdersResult?.loading}
        data={data}
        html5
        filterKey={filterKey}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={(sortColumn: string) => setSort({
          ...sort,
          sortColumn: getSortType(sort.sortType) === undefined ? undefined : sortColumn,
          sortType: getSortType(sort.sortType)
        })}
        rowClassName={(data: any) => data?.guid ? (data?.status || 'not_completed') : 'undefined'}
        tableDataRef={tableDataRef}
      >
        {(isBrowser && !isRole(ROLE.CLIENT, ROLE.CLEANER)) &&
          <Column width={40}>
            <HeaderCell>
              <Whisper placement="bottom" speaker={<Tooltip>Select All Visible</Tooltip>}>
                <IconButton
                  onClick={() => {
                    handleTableSelectRow('all' + (year ? year : ''), year);
                  }}
                  icon={selectedTableRows.indexOf('all' + (year ? year : '')) > -1 ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
                  style={{ marginTop: '-6px' }}
                  appearance="link" />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(row: any) => {
                return (
                  <IconButton
                    onClick={() => handleTableSelectRow(row.guid)}
                    icon={selectedTableRows.indexOf(row.guid) > -1 ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
                    style={{ marginTop: '-6px' }}
                    appearance="link" />
                );
              }}
            </Cell>
          </Column>
        }

        <Column>
          <HeaderCell>UUID</HeaderCell>
          <Cell data-column="UUID">
            {(row: any) => row.guid}
          </Cell>
        </Column>

        <Column width={50}>
          <HeaderCell>Work ID</HeaderCell>
          <Cell data-column="Work ID">
            {(row: any) => isRole(ROLE.CLIENT, ROLE.CLEANER)
              ? <span>{row.bookAs && row.bookAs.indexOf('invoice_per_work_order') > -1 ? row.parentId : row.workOrderId}</span>
              : <>

                {/* <button onClick={() => {
                  console.log('click');
                  getWorkOrder({ variables: { limit: 1, offset: 0, where: { guid: { is: 'c8b9558f-2ba6-4662-a174-049a51fee51d' } } } });
                }}>
                  Click me!
                </button> */}

                <Link to={`/app/${getEnv()}/workorder/edit/${row.parentGuid || row.guid}`}
                  onClick={(e: any) => {
                    e.preventDefault();

                    showDrawer(DRAWER.WORK_ORDER_FORM, { action: 'edit', workOrderGuid: row.parentGuid || row.guid }, () => workOrdersResult.refetch());
                  }}>{row.bookAs && row.bookAs.indexOf('invoice_per_work_order') > -1 ? row.parentId : row.workOrderId}</Link>

              </>
            }
          </Cell>
        </Column>

        <Column>
          <HeaderCell>Status</HeaderCell>
          <Cell data-column="Status">
            {(row: any) => <WorkOrderStatus status={row.status || ''} />}
          </Cell>
        </Column>

        <Column flexGrow={1} sortable data-sortable-value={'customerName'} data-sortable-label={'Customer Name'}>
          <HeaderCell>{INTL.CUSTOMER}</HeaderCell>
          <Cell dataKey="customerName" data-column={!customerId ? 'Customer Name' : new Date().toISOString()}>
            {(row: any) => {
              return (
                <Link to={`/app/${getEnv()}/workbook/explorer/${row.customerId}/work-orders`}
                  onClick={(e: any) => { e.preventDefault(); history.push(`/app/${getEnv()}/workbook/explorer/${row.customerId}/work-orders`); }}
                >{row?.customer?.displayName}</Link>
              )
            }}
          </Cell>
        </Column>

        {IS_KALADI_PROPERTIES &&
          <Column flexGrow={1}>
            <HeaderCell>Property</HeaderCell>
            <Cell>
              {(row: any) => <Link to={`/app/${getEnv()}/workbook/explorer/${row.customerId}/profile`}>{row?.customer?.displayName}</Link>}
            </Cell>
          </Column>
        }

        <Column flexGrow={1}>
          <HeaderCell>Address</HeaderCell>
          <Cell data-column={'Customer Address'}>
            {(row: any) => <a href="/" onClick={(e: any) => {
              e.preventDefault();
              showDrawer(DRAWER.CUSTOMER_MAP_VIEW, { customer: row?.customer });
            }}>{row?.customer?.displayAddress}</a>}
          </Cell>
        </Column>

        <Column flexGrow={1} sortable data-sortable-value={'applicationGroup'} data-sortable-label={'Work Group'}>
          <HeaderCell>{INTL.WORK_GROUP}</HeaderCell>
          <Cell data-column='Work Group' dataKey="applicationGroup">
            {(row: any) => {
              return <Fragment>
                {workGroupTypes.find((w: any) => w.key === row.applicationGroup)?.title}
              </Fragment>
            }}
          </Cell>
        </Column>

        <Column flexGrow={1} sortable>
          <HeaderCell>{INTL.WORK_TYPE}</HeaderCell>
          <Cell data-column='Services' dataKey="services">
            {(row: any) => {
              return <span>{fieldTitle((row.services || []).filter((s: any) =>
                serviceKeys.includes(s)), state.fields).join(', ')}{row.otherServices && ': ' + row.otherServices}</span>;
            }}
          </Cell>
        </Column>

        <Column flexGrow={1} sortable>
          <HeaderCell>Document</HeaderCell>
          <Cell data-column="Document">
            {(row: any) => {
              return <Fragment>
                {row.documents.length > 0 &&
                  <Link to={'/'} onClick={(e: any) => {
                    e.preventDefault();
                    showDrawer(DRAWER.DOCUMENT_FORM, { documentGuid: row.documents[0].guid, action: 'edit' }, () => setModifiedAt(new Date().toISOString()));
                  }}>
                    {startCase(row.documents[0]?.title)}<span> </span>
                    {row.documents[0]?.startDate && format(parseISO(row.documents[0]?.startDate), FORMAT.MONTH_DATE)} {row.documents[0]?.endDate && ' to ' + format(parseISO(row.documents[0]?.endDate), FORMAT.MONTH_DATE)}
                  </Link>
                }
              </Fragment>
            }}
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Services Detailed</HeaderCell>
          <Cell data-column='Services Detailed'>
            {(row: any) => {
              let defaultIds: any = [];
              const selected: any = [];
              let shouldBeSelected: any = [];

              bookingServices.forEach((s: any) => {
                if (row.services.includes(s.key)) {
                  defaultIds.push(s.defaults);
                  selected.push(s.key);
                }
              });

              defaultIds = flatten(defaultIds);

              fieldServices.forEach((s: any) => {
                if (defaultIds.includes(s.id)) {
                  selected.push(s.key);
                  shouldBeSelected.push(s.key);
                }
              });

              shouldBeSelected = shouldBeSelected.filter((s: any) => !row.services.includes(s));

              return (
                <CheckboxGroup key={`service-picker-${row.guid}`} defaultValue={row.services || []}
                  onChange={async (services: any) => {
                    const res = await client.mutate({
                      mutation: updateWorkOrders,
                      variables: {
                        input: [{
                          guid: row.guid,
                          services
                        }]
                      }
                    });

                    if (res.data.updateWorkOrders.success) {
                      toaster.push(
                        <Message type="success" showIcon closable>{res.data.updateWorkOrders.message}</Message>
                      );
                    } else {
                      showError(new Error(res.data.updateWorkOrders.message));
                    }
                  }}>
                  {row.services.concat(shouldBeSelected).sort().map((s: string) => <Checkbox key={`service-picker-${row.guid}-${s}`} value={s}>
                    {(serviceKeys.includes(s) || selected.includes(s))
                      ? <strong>{(state.fields || []).find((st: any) => st.key === s)?.title}{(row.services || []).includes(s)
                        ? '' : ' [missing]'}</strong> : <span style={{ color: '#999' }}>{(state.fields || []).find((st: any) => st.key === s)?.title} [removed]</span>}
                  </Checkbox>)}
                </CheckboxGroup>
              );
            }}
          </Cell>
        </Column>

        <Column flexGrow={1} sortable>
          <HeaderCell>Start</HeaderCell>
          <Cell data-column='Start' dataKey="startDate">
            {(row: any) => {
              return <DateRangeDisplay
                handleClick={() => {
                  showDrawer(DRAWER.JOB_LIST, { workOrderGuid: row.guid }, () => setModifiedAt(new Date().toISOString()));
                }}
                date={row.startDate}
                jobs={row.jobs}
              />
            }}
          </Cell>
        </Column>

        <Column flexGrow={1} sortable>
          <HeaderCell>End</HeaderCell>
          <Cell data-column='End' dataKey="endDate">
            {(row: any) => {
              return <DateRangeDisplay
                handleClick={() => {
                  showDrawer(DRAWER.JOB_LIST, { workOrderGuid: row.guid }, () => setModifiedAt(new Date().toISOString()));
                }}
                date={row.endingDate || row.endDate}
                jobs={row.jobs}
              />
            }}
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>{INTL.CREW}</HeaderCell>
          <Cell data-column='Crew'>
            {(row: any) => (columnEditing.column === 'userId' && row.guid === columnEditing.guid)
              ? <SelectPicker
                size={'xs'}
                block
                data={crews}
                defaultValue={columnEditing.value}
                cleanable={false}
                onChange={(val) => {
                  handleWorkOrderUpdate(row.guid, row.parentGuid, { userId: +val });
                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'userId', guid: row.guid, value: row.user?.id })} />
              </Popover>} enterable>
                <div>{row.user?.operatorName}</div>
              </Whisper>
            }
          </Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Worker<span> </span>
            <Whisper placement="top" speaker={<Tooltip>Assigning workers from other crews will update the work order crew</Tooltip>}>
              <span><MdHelpOutline /></span>
            </Whisper>
          </HeaderCell>
          <Cell data-column={!IS_KALADI_KITCHENS ? 'Worker' : new Date().toISOString()}>
            {(row: any) => (columnEditing.column === 'workerId' && row.guid === columnEditing.guid)
              ? <SelectPicker
                placeholder="&nbsp;"
                size={'xs'}
                block
                // rsuite5
                // toggleComponentClass={Button}
                data={orderBy(workers.map((w: any) => ({ ...w, crewGroup: w.workingUnderId === row.userId ? 'Crew Workers' : 'Other Workers' })), 'crewGroup', 'asc')}
                groupBy="crewGroup"
                defaultValue={columnEditing.value}
                onChange={(val) => {
                  handleWorkOrderUpdate(row.guid, row.parentGuid, { workerId: +val });
                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'workerId', guid: row.guid, value: row.workerId })} />
              </Popover>} enterable>
                <div dangerouslySetInnerHTML={{ __html: row.workerId ? state.users.find((u: any) => u.id === +(row.workerId || -1))?.operatorName : '&nbsp;' }} />
              </Whisper>
            }
          </Cell>
        </Column>

        <Column flexGrow={2}>
          <HeaderCell>Work Comments</HeaderCell>
          <Cell data-column='Work Comments'>
            {(row: any) => (columnEditing.column === 'workDescription' && row.guid === columnEditing.guid)
              ? <Input
                as={TextareaAutosize}
                defaultValue={columnEditing.value}
                onBlur={(e: any) => {
                  if (e.target.value !== columnEditing.value) {
                    handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: e.target.value });
                  }

                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'workDescription', guid: row.guid, value: row.workDescription })} />
              </Popover>} enterable>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  dangerouslySetInnerHTML={{ __html: row.workDescription ? row.workDescription.trim().replace(/\r\n|\r|\n/g, '<br />') : '&nbsp;' }} />
              </Whisper>
            }
          </Cell>
        </Column>

        <Column>
          <HeaderCell>Work Comments</HeaderCell>
          <Cell data-column='Work Comments (Preview)'>{(row: any) => <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{row.workDescription || ''}</Tooltip>}>
            <span>{(row.workDescription || '').substr(0, 10) + ((row.workDescription || '').length > 10 ? '...' : '')}</span>
          </Whisper>}
          </Cell>
        </Column>

        <Column>
          <HeaderCell>Recurrence</HeaderCell>
          <Cell data-column="Recurrence">{(row: any) => startCase(row.recurrence)}</Cell>
        </Column>

        <Column sortable>
          <HeaderCell>Included</HeaderCell>
          <Cell data-column="Jobs Included" dataKey='visitsIncluded' />
        </Column>

        <Column sortable>
          <HeaderCell>Completed</HeaderCell>
          <Cell data-column="Jobs Completed" dataKey='jobsCompleted' />
        </Column>

        <Column sortable>
          <HeaderCell>Scheduled</HeaderCell>
          <Cell data-column="Jobs Scheduled" dataKey="jobsScheduled" />
        </Column>

        <Column>
          <HeaderCell>Repeat On</HeaderCell>
          <Cell data-column='Repeat On'>
            {(row: any) => (columnEditing.column === 'repeatOn' && row.guid === columnEditing.guid)
              ? <CheckPicker
                block
                searchable={false}
                cleanable={false}
                data={[
                  { label: 'Mon', value: 'mon' },
                  { label: 'Tue', value: 'tue' },
                  { label: 'Wed', value: 'wed' },
                  { label: 'Thu', value: 'thu' },
                  { label: 'Fri', value: 'fri' },
                  { label: 'Sat', value: 'sat' },
                  { label: 'Sun', value: 'sun' }
                ]}
                defaultValue={columnEditing.value}
                onSelect={(data: any) => {
                  selectedGlobalRepeatOn = data;
                }}
                onClose={() => {
                  if (selectedGlobalRepeatOn.sort().join(',') !== columnEditing.value.sort().join(',')) {
                    handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: selectedGlobalRepeatOn });
                  }

                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'repeatOn', guid: row.guid, value: row.repeatOn })} />
              </Popover>} enterable>
                <div>
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{startCase(row.costType)}</Tooltip>}>
                    <span>{(row.repeatOn || []).map((r: string) => startCase(r)).join(', ')}</span>
                  </Whisper>
                </div>
              </Whisper>
            }
          </Cell>
        </Column>

        <Column>
          <HeaderCell>Cost Type</HeaderCell>
          <Cell data-column='Cost Type'>
            {(row: any) => (columnEditing.column === 'costType' && row.guid === columnEditing.guid)
              ?
              <SelectPicker
                cleanable={false}
                searchable={false}
                data={[
                  { label: 'Per Hour', value: 'per_hour' },
                  { label: 'Per Visit', value: 'per_visit' },
                  { label: 'Per Day', value: 'per_day' },
                  { label: 'Per Week', value: 'per_week' },
                  { label: 'Per Month', value: 'per_month' }
                ]}
                defaultValue={columnEditing.value}
                onSelect={(val: string) => {
                  handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: val });
                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'costType', guid: row.guid, value: row.costType })} />
              </Popover>} enterable>
                <div>
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{startCase(row.costType)}</Tooltip>}>
                    <span>{startCase(row.costType)}</span>
                  </Whisper>
                </div>
              </Whisper>
            }
          </Cell>
        </Column>

        <Column>
          <HeaderCell>
            <div>
              <span>Type Cost</span><br />
              <strong>{moneyFormatter.format(data.reduce((p: any, n: any) => p += n.perCostTypeCost, 0))}</strong>
            </div>
          </HeaderCell>
          <Cell data-column='Type Cost'>
            {(row: any) => (columnEditing.column === 'perCostTypeCost' && row.guid === columnEditing.guid)
              ?
              <Input
                defaultValue={columnEditing.value}
                onBlur={(e: any) => {
                  if (parseNumber(e.target.value) !== columnEditing.value) {
                    handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: e.target.value });
                  }

                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'perCostTypeCost', guid: row.guid, value: row.perCostTypeCost })} />
              </Popover>} enterable>
                <div>
                  <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{startCase(row.costType)}</Tooltip>}>
                    <span>{moneyFormatter.format(parseNumber(row.perCostTypeCost))}</span>
                  </Whisper>
                </div>
              </Whisper>
            }
          </Cell>
        </Column>

        <Column>
          <HeaderCell>
            <div>
              <span>Total Cost</span><br />
              <strong>{moneyFormatter.format(data.reduce((p: any, n: any) => p += n.totalCost, 0))}</strong>
            </div>
          </HeaderCell>
          <Cell data-column='Total Cost'>
            {(row: any) => (columnEditing.column === 'totalCost' && row.guid === columnEditing.guid)
              ? <Input
                defaultValue={columnEditing.value}
                onBlur={(e: any) => {
                  if (parseNumber(e.target.value) !== columnEditing.value) {
                    handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: e.target.value });
                  }

                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'totalCost', guid: row.guid, value: row.totalCost })} />
              </Popover>} enterable>
                <div>{moneyFormatter.format(parseNumber(row.totalCost))}</div>
              </Whisper>
            }
          </Cell>
        </Column>

        <Column flexGrow={2}>
          <HeaderCell>Contract Value</HeaderCell>
          <Cell data-column='Contract Value'>
            {(row: any) => (columnEditing.column === 'contractValue' && row.guid === columnEditing.guid)
              ? <Input
                defaultValue={columnEditing.value}
                onBlur={(e: any) => {
                  if (parseNumber(e.target.value) !== columnEditing.value) {
                    handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: e.target.value });
                  }

                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'contractValue', guid: row.guid, value: row.contractValue })} />
              </Popover>} enterable>
                <div>{moneyFormatter.format(parseNumber(row.contractValue))}</div>
              </Whisper>
            }
          </Cell>
        </Column>

        <Column flexGrow={2}>
          <HeaderCell>Contract Notes</HeaderCell>
          <Cell data-column='Contract Notes'>
            {(row: any) => (columnEditing.column === 'contractNotes' && row.guid === columnEditing.guid)
              ? <Input
                as={TextareaAutosize}
                defaultValue={columnEditing.value}
                onBlur={(e: any) => {
                  if (e.target.value !== columnEditing.value) {
                    handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: e.target.value });
                  }

                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'contractNotes', guid: row.guid, value: row.contractNotes })} />
              </Popover>} enterable>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  dangerouslySetInnerHTML={{ __html: row.contractNotes ? row.contractNotes.trim().replace(/\r\n|\r|\n/g, '<br />') : '&nbsp;' }} />
              </Whisper>
            }
          </Cell>
        </Column>

        <Column sortable>
          <HeaderCell>Contract Type</HeaderCell>
          <Cell data-column='Contract Type' dataKey="contractType">{(row: any) => startCase(row.contractType)}</Cell>
        </Column>

        <Column sortable>
          <HeaderCell>Time Spent (Hrs)</HeaderCell>
          <Cell data-column='Time Spent' dataKey="totalTimeSpent">{(row: any) => (row.totalTimeSpent / 60.0).toFixed(2)}</Cell>
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Deicer</HeaderCell>
          <Cell data-column='Deicer'>
            {(row: any) => <span>
              {startCase(row.props?.deicerLineItem?.status)}
              {row.props?.deicerLineItem?.pricePerKg ? ` - ${moneyFormatter.format(row.props?.deicerLineItem?.pricePerKg || 0)}/kg` : ''}
            </span>
            }

            {/* {(row: any) => (columnEditing.column === 'props.deicerLineItem.pricePerKg' && row.guid === columnEditing.guid)
              ? <Input
                defaultValue={columnEditing.value}
                onBlur={(e: any) => {
                  if (parseNumber(e.target.value) !== columnEditing.value) {
                    handleWorkOrderUpdate(row.guid, row.parentGuid, { [columnEditing.column as string]: e.target.value });
                  }

                  setColumnEditing(initialColumnEditing);
                }}
              />
              : <Whisper placement="rightStart" trigger="hover" speaker={<Popover className="column-edit">
                <IconButton size="xs" appearance="link" icon={<MdEdit />}
                  onClick={() => setColumnEditing({ column: 'props.deicerLineItem.pricePerKg', guid: row.guid, value: row.props?.deicerLineItem?.pricePerKg })} />
              </Popover>} enterable>
                <span>
                  {startCase(row.props?.deicerLineItem?.status)}
                  {row.props?.deicerLineItem?.pricePerKg ? ` - ${moneyFormatter.format(row.props?.deicerLineItem?.pricePerKg || 0)}/kg` : ''}
                </span>
              </Whisper>
            } */}
          </Cell>
        </Column>

        <Column>
          <HeaderCell>
            <div>
              <span>SPMH</span><br />
              <strong>{getAverageSPMH(data)}</strong>
            </div>
          </HeaderCell>
          <Cell data-column='SPMH'>
            {(row: any) =>
              <Whisper placement='bottom' trigger="hover" speaker={<Tooltip>{moneyFormatter.format(row.totalCost)}/month x {row.monthsDuration} months
                <span> / </span>{round(row.totalTimeSpent / 60, 2)}hrs</Tooltip>}>
                <span className='text-whisper'>{moneyFormatter.format(parseNumber(row.spmh))}</span>
              </Whisper>
            }
          </Cell>
        </Column>

        <Column>
          <HeaderCell>Created By</HeaderCell>
          <Cell data-column='Created By' dataKey="createdByOperatorName" />
        </Column>

        <Column sortable>
          <HeaderCell>Created At</HeaderCell>
          <Cell data-column='Created At' dataKey="createdAt">{(row: any) => format(parseISO(row.createdAt), FORMAT.MONTH_DATE)}</Cell>
        </Column>

        <Column sortable>
          <HeaderCell>Modified At</HeaderCell>
          <Cell data-column='Modified At' dataKey="modifiedAt">{(row: any) => format(parseISO(row.modifiedAt), FORMAT.MONTH_DATE)}</Cell>
        </Column>

        {(!isRole(ROLE.CLIENT, ROLE.CLEANER) && !headless) &&
          <Column width={120}>
            <HeaderCell>&nbsp;</HeaderCell>
            <Cell className="link-group text-right">
              {(row: any) => {
                let event = `onclick="PrairieHQ.emailConfim(event, 'Are you sure you want to email out this work order?', '/workorder/email/${row.applicationGroup}/${row.workOrderId}')"`;
                event = !row.username || row.username.length === 0 ? `onclick="PrairieHQ.prompt('Cannot send email without crew assigned.'); return false;"` : event;
                const lastEmail = row.lastEmail;
                const lastEmailText = lastEmail
                  ? `Emails Sent: ${lastEmail.length} ${lastEmail.length > 0 ? `<br />Last Email: <br />${lastEmail.map((date: any) => {
                    if (new Date(date) < new Date('2021-01-04')) {
                      return format(new Date(date), FORMAT.DATE_TIME);
                    }

                    return format(parseISO(date), FORMAT.DATE_TIME).replace(/\s(A|P)M/, "$1M");
                  }).slice(-1).join('<br />')}` : ''}`
                  : 'Email';

                return (
                  <Fragment>
                    <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Edit</Tooltip>}>
                      <IconButtonWrapper
                        onClick={() => {
                          showDrawer(DRAWER.WORK_ORDER_FORM, { action: 'edit', workOrderGuid: row.parentGuid || row.guid }, () => workOrdersResult.refetch());
                        }}
                        appearance="link"
                        icon={'edit'}
                      />
                    </Whisper>
                    {!isRole(ROLE.CLIENT, ROLE.CLEANER) &&
                      <Badge content={lastEmail.length === 0 ? false : lastEmail.length} className="email-badge">
                        <Whisper placement="bottom" trigger="hover" speaker={<Tooltip><div className="text-left" dangerouslySetInnerHTML={{ __html: lastEmailText }}></div></Tooltip>}>
                          <IconButtonWrapper
                            onClick={() => {
                              showDrawer(DRAWER.EMAIL_FORM, {
                                resources: [row],
                                resourceGuid: row.guid,
                                group: row.applicationGroup,
                                type: 'work_order',
                                templateId: findServiceTemplateGuid(row.services),
                                users: (row?.users || []).map((u: any) => u.id)
                              }, () => setModifiedAt(new Date().toISOString()));
                            }}
                            appearance="link"
                            icon={'email'}
                          />
                        </Whisper>
                      </Badge>
                    }

                    {can('worker:update', 'work-order:create', 'work-order:delete') &&
                      <PopoverDropdownMenu dataKey="id" onMenuItemSelected={(val: any) => {
                        if (val) {
                          window.location.href = val;
                        }
                      }}>
                        {['roofing__59634106__1710128534', 'construction'].includes(row.applicationGroup) && <Dropdown.Item eventKey={`/app/${getEnv()}/worklog/edit/${row.guid}`}>Work Log</Dropdown.Item>}
                        {can('work-order:create') &&
                          <Dropdown.Item onSelect={() => {
                            showDrawer(DRAWER.WORK_ORDER_FORM, { action: 'repeat', workOrderGuid: row.parentGuid }, () => workOrdersResult.refetch());
                            // setWorkOrderEvent({ action: 'repeat', guid: row.parentGuid || row.guid });
                          }}>Repeat</Dropdown.Item>
                        }

                        {/* <Dropdown.Item onSelect={() => showDrawer(DRAWER.WORK_ORDER_PHOTOS)}>Photos</Dropdown.Item> */}

                        {isRole(ROLE.ADMIN, ROLE.MANAGER) && <Dropdown.Item eventKey={null} onClick={() => {
                          showDrawer(DRAWER.RESOURCE_HISTORY, {
                            resourceGuid: row.guid,
                            resource: 'workOrder'
                          });
                        }}>History</Dropdown.Item>}

                        <Dropdown.Item eventKey={null} onClick={() => handleShareWorkOrder(row.guid)}>Share</Dropdown.Item>

                        {can('work-order:delete') && <Dropdown.Item onSelect={() => {
                          showConfirmation(
                            <p>Are you sure you want to remove this work order?</p>,
                            'Remove Work Order',
                            async () => {
                              const response = await client.mutate({
                                mutation: gql`mutation deleteWorkOrder($guid: ID!) { deleteWorkOrder(guid: $guid) { success, code, message }}`,
                                variables: { guid: row.guid }
                              });

                              if (response.data.deleteWorkOrder.success) {
                                toaster.push(
                                  <Message type="success" showIcon closable>{response.data.deleteWorkOrder.message}</Message>
                                );
                                setSelectedTableRows([]);
                                setModifiedAt(new Date().toISOString());
                              }
                            }
                          );
                        }}>Delete</Dropdown.Item>}
                      </PopoverDropdownMenu>
                    }
                  </Fragment>
                );
              }}
            </Cell>
          </Column>
        }
      </ResponsiveTable>
    );
  }

  return (
    <div>
      {!isRole(ROLE.CLIENT, ROLE.CLEANER) &&
        <Filter
          id={filterKey}
          showJobStatus={true}
          showMultipleCrewFilter={true}
          includeFilterByUnassignedCrew={true}
          showGroupFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER)}
          showCustomerLabelFilter={!isRole(ROLE.CLIENT, ROLE.CLEANER) && IS_BUGABOO}
          onChange={(val: any) => {
            setFilter(val);
            setOffset(0);
          }}
          dateRangeColumns={[
            { startDate: 'Start Date' },
            { endDate: 'End Date' },
            { createdAt: 'Created At' },
            { modifiedAt: 'Modified At' },
            { startEndInclusive: 'Overlaps Start/End' }
          ]}
          dateRangeCleanable={customerId !== undefined}
          showRecurrenceFilter
          tableDataRef={tableDataRef}
          showSquareFootageFilter={!isRole(ROLE.CLIENT) && !IS_KALADI_KITCHENS && !IS_KALADI_PROPERTIES}
        >
          <Col md={3} xs={24} style={{ float: 'right', textAlign: 'right' }}>
            <MapToggleField />
            <ColumnChooserField filterKey={filterKey} />
            <ExportField />
          </Col>
        </Filter>
      }


      <Panel className={['content', isMobileOnly ? 'reset' : 'list'].join(' ')}>
        {(customerId)
          ? <Fragment>
            {!loading && !workOrdersResult.loading && workOrders.length === 0 &&
              <Panel>
                <div className="help-message-box">
                  <div className="icon"><MdEco
                    style={{
                      color: '#ccc',
                      fontSize: "4em"
                    }} /></div>
                  <h6>No data found</h6>
                  <div>Start by creating a {INTL.WORK_ORDER}.<br />{INTL.JOBS} will be created once the {INTL.WORK_ORDER.toLowerCase()} is created.</div>
                  <div className="cta">
                    <IconButton
                      icon={<Icon as={MdAdd} />}
                      appearance="primary"
                      size="sm"
                      onClick={() => showDrawer(DRAWER.WORK_ORDER_FORM, { action: 'add', customerId }, () => workOrdersResult.refetch())}>Add {INTL.WORK_ORDER}</IconButton>
                  </div>
                </div>
              </Panel>
            }

            {(loading || workOrdersResult.loading) && <Loader content="Loading..." />}

            <PanelGroup accordion>
              <Animation.Collapse in={selectedTableRows.length > 0 && !headless}>
                <div>
                  <ButtonToolbar className='mt-8 mb-8'>
                    <Button size="xs" disabled={selectedTableRows.length === 0} appearance="ghost"
                      onClick={() => {
                        showDrawer(DRAWER.EMAIL_FORM, {
                          resources: workOrders.filter((d: any) => selectedTableRows.includes(d.guid)),
                          group: 'work_order',
                          type: 'work_order'
                        }, () => setModifiedAt(new Date().toISOString()));
                      }}>Email</Button>
                    <Button size="xs" appearance="ghost" onClick={() => handleRepeatWorkOrders(selectedTableRows)}>Repeat</Button>
                    {!IS_KALADI_KITCHENS &&
                      <Button size="xs" appearance="ghost" onClick={() => handleGroupWorkOrders(selectedTableRows)}>Repeat &amp; Group</Button>
                    }
                    {!IS_KALADI_KITCHENS &&
                      <Button size="xs" disabled={selectedTableRows.length === 0} appearance="ghost"
                        onClick={() => setShowAssignWorkerModal(true)}>Edit</Button>
                    }
                    <Button size="xs" appearance="ghost" onClick={() =>
                      showConfirmation(`Are you sure you want to delete selected ${INTL.WORK_ORDERS.toLowerCase()}?`, `Delete ${INTL.WORK_ORDERS}`,
                        () => handleDeleteWorkOrders(selectedTableRows))}>Delete</Button>
                  </ButtonToolbar>
                </div>
              </Animation.Collapse>
              {uniq(workOrders.map((d: any) => getGroupByFilter(d, groupBy || 'endDate')).sort().reverse())
                .map((filterVal: any) =>
                  <Panel key={filterVal} header={filterVal} collapsible defaultExpanded={(groupBy || 'endDate').toLowerCase().includes('date') ? isExpanded(filterVal) : true}>
                    {renderTable(workOrders.filter((d: any) => (groupBy || 'endDate').toLowerCase().includes('date') ? +format(parseISO(d[groupBy || 'endDate']), 'y') === filterVal : get(d, groupBy || 'endDate') === filterVal), filterVal)}
                  </Panel>
                )}
            </PanelGroup>
          </Fragment>
          : <Fragment>
            <Animation.Collapse in={selectedTableRows.length > 0}>
              <div>
                <ButtonToolbar className='mt-8 mb-8'>
                  <Button size="xs" disabled={selectedTableRows.length === 0} appearance="ghost"
                    onClick={() => showDrawer(DRAWER.EMAIL_FORM, {
                      resources: workOrders.filter((w: any) => selectedTableRows.includes(w.guid)),
                      type: 'work_order'
                    })}>Email</Button>
                  <Button size="xs" disabled={selectedTableRows.length === 0} appearance="ghost"
                    onClick={() => setShowAssignWorkerModal(true)}>Edit</Button>
                </ButtonToolbar>
              </div>
            </Animation.Collapse>
            {renderTable(workOrders)}
          </Fragment>
        }
      </Panel>

      {!customerId &&
        <div>
          <Pagination
            offset={offset}
            totalCount={workOrdersResult?.data?.workOrders?.totalCount}
            recordsPerPage={recordsPerPage}
            onChangeOffset={setOffset}
            onChangeLength={setRecordsPerPage}
          />
        </div>
      }

      <Drawer backdrop="static" open={showAssignWorkerModal}
        onClose={() => setShowAssignWorkerModal(!showAssignWorkerModal)} size="xs">
        <Drawer.Header><Drawer.Title>Edit</Drawer.Title></Drawer.Header>
        <Drawer.Body>
          <Form>
            <Form.Group>
              <Form.ControlLabel>Status:</Form.ControlLabel>
              <SelectPicker
                name="status"
                searchable={false}
                data={CONFIG_STATUS}
                style={{ width: '100%' }}
                value={selectedGlobalStatus}
                onChange={setSelectedGlobalStatus}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Cost Type:</Form.ControlLabel>
              <SelectPicker
                cleanable={false}
                searchable={false}
                block
                data={[
                  { label: 'Per Hour', value: 'per_hour' },
                  { label: 'Per Visit', value: 'per_visit' },
                  { label: 'Per Day', value: 'per_day' },
                  { label: 'Per Week', value: 'per_week' },
                  { label: 'Per Month', value: 'per_month' }
                ]}
                value={selectedGlobalCostType}
                onChange={setSelectedGlobalCostType}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Worker:</Form.ControlLabel>
              <SelectPicker
                placeholder="Select worker to assign"
                block
                data={workers}
                valueKey="id"
                labelKey="operatorName"
                value={selectedGlobalWorker}
                onChange={setSelectedGlobalWorker}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>{INTL.CREW}:</Form.ControlLabel>
              <SelectPicker
                placeholder={`Select ${INTL.CREW.toLowerCase()} to assign`}
                block
                data={crews}
                valueKey="id"
                labelKey="operatorName"
                value={selectedGlobalCrew}
                onChange={setSelectedGlobalCrew}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Services:</Form.ControlLabel>
              <CheckPicker
                disabled={getWorkTypes().length > 1}
                placeholder={`Select services`}
                block
                data={state.fields.filter((f: any) => f.applicationGroup === getWorkTypes()[0] && f.visibleOnBooking && f.isVisible)}
                valueKey="key"
                labelKey="title"
                value={selectedGlobalServices}
                onChange={setSelectedGlobalServices}
              />
              {getWorkTypes().length > 1 &&
                <Form.HelpText className='text-danger'>Services cannot be changed when multiple work groups are selected, <strong>{getWorkTypes().map((w: any) => state.services[w]).join(', ')}</strong>. Use the Work Group filter to select single group.</Form.HelpText>
              }
            </Form.Group>
          </Form>
        </Drawer.Body>
        <Drawer.Actions>
          <Button
            size="sm"
            disabled={
              (selectedGlobalWorker === undefined || selectedGlobalWorker.length === 0)
              && (selectedGlobalCrew === undefined || selectedGlobalCrew.length === 0)
              && (selectedGlobalStatus === undefined || selectedGlobalStatus.length === 0)
              && (selectedGlobalCostType === undefined || selectedGlobalCostType.length === 0)
              && (selectedGlobalServices === undefined || selectedGlobalServices.length === 0)
            }
            onClick={() => {
              setShowAssignWorkerModal(!showAssignWorkerModal);
              handleAssignAllWorkerId(
                +selectedGlobalWorker,
                +selectedGlobalCrew,
                selectedGlobalStatus,
                selectedTableRows,
                selectedGlobalCostType,
                selectedGlobalServices
              );
              setSelectedGlobalWorker(undefined);
              setSelectedGlobalCrew(undefined);
              setSelectedGlobalCostType(undefined);
              setSelectedGlobalServices(undefined);
            }}
            appearance="primary">Save</Button>
          <Button size="sm" onClick={() => {
            setShowAssignWorkerModal(!showAssignWorkerModal)
          }} appearance="subtle">Cancel</Button>
        </Drawer.Actions>
      </Drawer>

      {/* <WorkOrderForm
        drawer={true}
        show={workOrderEvent.action !== undefined}
        action={workOrderEvent.action}
        guid={workOrderEvent.guid}
        onUpdate={() => {
          // getWorkOrder({ variables: { where: { guid: { is: workOrderEvent.guid }} }});
          setModifiedAt(new Date().toISOString());
        }}
        onHide={() => {
          setWorkOrderEvent({ action: undefined, guid: undefined });
        }}
      /> */}
    </div>
  );
};

export default WorkOrderList;
