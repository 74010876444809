import { gql } from '@apollo/client';

const queryJobDates = gql`
  query jobs(
    $limit: Int!
    $offset: Int!
    $where: JobsWhere
    $sort: JobsSort
    $order: [JobsSort]
  ) {
    jobs(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          guid
          workOrderGuid
          startDate
          endDate
          workType
          services
        }
      }
      totalCount
    }
  }
`;

const queryJobs = gql`
  query jobs(
    $limit: Int!
    $offset: Int!
    $where: JobsWhere
    $sort: JobsSort
    $store: DataStore
    $order: [JobsSort]
  ) {
    jobs(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort,
      store: $store
      order: $order
    }) {
      edges {
        node {
          id
          guid
          parentGuid
          applicationGroup
          startDate
          endDate
          startTime
          props
          custom
          customerId
          customerName
          displayAddress
          displayCity
          displayPostalCode
          customerAddress
          workAddress
          workCity
          workPostalCode
          city
          postalCode
          customerId
          userId
          userGuid
          userColor
          workerUsername
          workerOperatorName
          workType
          services
          otherServices
          operatorName
          workOrderId
          workOrderGuid
          workDescription
          timeInstructions
          jobId
          invoiceId
          notes
          siteChecks
          photoCount
          attachmentCount
          emailCount
          perCostTypeCost
          totalCost
          workOrderPerCostTypeCost
          workOrderTotalCost
          status
          jobStatus
          completedServices
          completedServicesCount
          costType
          paymentCollected
          invoiceGuid
          previousInvoiceGuid
          timeOnSite
          timeFromSite
          peopleOnSite
          timeSpent
          sortOrder
          lastPhotoEmail
          recurrence
          completedAt
          customFields
          specialInstructions
          customerComments
          completedByCrewName
          contractNotes
          trackingFlag
          spmh
          canceledReason
          customer {
            workAddress
            workCity
            workPostalCode
            customerAddress
            customerName
            city
            postalCode
            displayName
            displayAddress
            displayCity
            displayPostalCode
            lat
            lng
            phone
            alternatePhone
            email
            contactDetails
            guid
            googlePlacesUrl
            id
          }
          attachmentsParent {
            guid
            fileName
            filePath
            fileId
            applicationGroup
          }
          attachments {
            guid
            fileName
            filePath
            fileId
            applicationGroup
          }
          timeCard {
            guid
            timeOnSite
            timeFromSite
            timeSpent
            jobGuid
            peopleOnSite
            userGuid
          }
        }
      }
      totalCount
    }
  }
`;

const GET_JOB = gql`
  query job($guid: ID) {
    job(guid: $guid) {
      guid
      timeOnSite
      timeFromSite
      userId
      operatorName
      workerOperatorName
      applicationGroup
      customer {
        displayName
      }
      timeCard {
        guid
        timeOnSite
        timeFromSite
        userGuid
      }
    }
  }
`;

const queryJobsCalendar = gql`
  query jobs(
    $limit: Int!
    $offset: Int!
    $where: JobsWhere
    $sort: JobsSort
    $order: [JobsSort]
  ) {
    jobs(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      edges {
        node {
          id
          guid
          parentGuid
          applicationGroup
          startDate
          endDate
          startTime
          props
          custom
          customerId
          customerName
          displayAddress
          displayCity
          displayPostalCode
          customerAddress
          workAddress
          workCity
          workPostalCode
          city
          postalCode
          customerId
          userId
          userColor
          workerUsername
          workerOperatorName
          workType
          services
          otherServices
          operatorName
          workOrderId
          workOrderGuid
          workDescription
          timeInstructions
          jobId
          invoiceId
          notes
          siteChecks
          photoCount
          attachmentCount
          perCostTypeCost
          totalCost
          workOrderPerCostTypeCost
          workOrderTotalCost
          status
          jobStatus
          completedServices
          costType
          paymentCollected
          timeOnSite
          timeFromSite
          peopleOnSite
          sortOrder
          notifiedAt
          customer {
            id
            guid
            workAddress
            workCity
            workPostalCode
            customerAddress
            customerName
            city
            postalCode
            displayName
            displayAddress
            displayCity
            displayPostalCode
            lat
            lng
            phone
            alternatePhone
            email
            googlePlacesUrl
          }
        }
      }
      totalCount
    }
  }
`;

const updateJob = gql`
  mutation updateJob(
    $guid: ID!
    $status: String
    $timeOnSite: DateTime
    $timeFromSite: DateTime
    $peopleOnSite: Int
    $completedServices: [String]
    $notes: String
    $perCostTypeCost: Float
    $siteChecks: [String]
    $deIcerValue: Float
    $gravelAmount: Float
    $gravelBins: Float
    $startDate: DateTime
    $endDate: DateTime
    $customFields: JSON
    $trackingFlag: String
  ) {
    updateJob(
      guid: $guid
      status: $status
      completedServices: $completedServices
      timeOnSite: $timeOnSite
      timeFromSite: $timeFromSite
      peopleOnSite: $peopleOnSite
      notes: $notes
      perCostTypeCost: $perCostTypeCost
      siteChecks: $siteChecks
      deIcerValue: $deIcerValue
      gravelAmount: $gravelAmount
      gravelBins: $gravelBins
      startDate: $startDate
      endDate: $endDate
      customFields: $customFields
      trackingFlag: $trackingFlag
    ) {
      success
      code
      message
      result
    }
  }
`;

const createJobs = gql`
  mutation createJobs(
    $jobs: [JobInput]
  ) {
    createJobs(
      jobs: $jobs
    ) {
      success
      code
      message
    }
  }
`;

const deleteJobs = gql`
  mutation deleteJobs(
    $jobs: [JobInput]
  ) {
    deleteJobs(
      jobs: $jobs
    ) {
      success
      code
      message
    }
  }
`;

const GET_JOBS_COMPLETION_REPORT = gql`
  query jobsCompletionReport(
    $limit: Int!
    $offset: Int!
    $where: JobsWhere
    $sort: JobsSort
    $order: [JobsSort]
  ) {
    jobsCompletionReport(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      startDate
          status
      count
    }
  }
`;

const GET_JOBS_ONDEMAND_COMPLETION_REPORT = gql`
  query jobsOnDemandCompletionReport(
    $limit: Int!
    $offset: Int!
    $where: JobsWhere
    $sort: JobsSort
    $order: [JobsSort]
  ) {
    jobsOnDemandCompletionReport(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      completed
      remaining
      startDate
    }
  }
`;

const GET_JOBS_SERVICES_COMPLETION_REPORT = gql`
  query jobsServicesCompletionReport(
    $limit: Int!
    $offset: Int!
    $where: JobsWhere
    $sort: JobsSort
    $order: [JobsSort]
  ) {
    jobsServicesCompletionReport(filter: {
      limit: $limit
      offset: $offset
      where: $where
      sort: $sort
      order: $order
    }) {
      service
          status
          count
    }
  }
`;

export {
  createJobs,
  queryJobs,
  queryJobDates,
  queryJobsCalendar,
  updateJob,
  deleteJobs,
  createJobs as CREATE_JOBS,
  queryJobs as GET_JOBS,
  queryJobDates as GET_JOB_DATES,
  queryJobsCalendar as GET_JOBS_CALENDAR,
  GET_JOBS_COMPLETION_REPORT,
  GET_JOBS_ONDEMAND_COMPLETION_REPORT,
  GET_JOBS_SERVICES_COMPLETION_REPORT,
  GET_JOB
}
