import { useState, useEffect, useContext, useRef, cloneElement } from 'react';
import { CustomerLookup, ApplicationContext, DrawerFormWrapper } from 'shared';
import { nanoid } from 'nanoid'

import {
  toaster,
  SelectPicker,
  Input,
  Button,
  Panel,
  Form,
  ButtonToolbar,
  Loader,
  Message,
  CheckPicker,
  DOMHelper,
  Checkbox,
} from 'rsuite';

import LegacyExternalLinkIcon from "@rsuite/icons/legacy/ExternalLink";
import startCase from 'lodash/startCase';
import { v4 as uuidv4 } from 'uuid';
import { useApi } from 'lib';
import { gql, useApolloClient } from '@apollo/client';
import queryString from 'query-string';
import { omit, orderBy } from 'lodash';
import { setHeaderTitle, getEnv, ROLE, FORMAT, COLOR } from 'lib/env';
import { queryWorkOrder, queryWorkOrderCutDays } from 'gql/workOrders';
import { queryDocument } from 'gql/documents';
import { flatten, pick, uniq } from 'lodash';
import { addYears, format, parseISO, addMinutes, isAfter, endOfMonth, addMonths } from 'date-fns';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { CONFIG_APPLICATION_GROUPS } from 'tenant/config';
import INTL from 'tenant/intl';
import { IS_ASSINIBOINE, IS_BUGABOO, IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { useViewport } from 'shared/ViewportProvider';
import InputMoney from 'components/form/InputMoney';
import AttachmentForm from 'components/attachment/AttachmentForm';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { toNearest30Minutes, toLocalTimeZone, getDefaultDate } from 'lib/date';
import { WorkOrderFields } from './components/WorkOrderFields';
import { WorkOrderFields as WorkOrderFieldsKaladiKitchens } from './components/kaladikitchens/WorkOrderFields';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { differenceInYears } from 'date-fns/esm';
import store from 'lib/storage';
import { MdCancel, MdCheckCircle, MdCircle } from 'react-icons/md';
import { Textarea } from 'components/rsuite';
import { getCustomFields, setCustomFields } from 'lib/form';

const defaultForm: any = {
  applicationGroup: '',
  guid: uuidv4(),
  parentGuid: uuidv4(),
  totalCost: '',
  contractType: IS_BUGABOO ? 'unlimited_visits' : 'residential',
  workType: '',
  recurrence: 'onetime',
  startEndDate: [new Date(), new Date()],
  startTime: undefined,
  endingDate: undefined,
  operatorPercentage: '',
  visitsIncluded: '',
  servicesInVisit: [],
  rateAboveIncluded: '',
  occurrence: '',
  perCostTypeCost: '',
  numberOfStrands: '',
  typeOfLights: '',
  numberOfTrees: '',
  houseType: [],
  payoutPercentage: '',
  workDescription: '',
  timeInstructions: '',
  status: IS_KALADI_KITCHENS ? 'approved' : 'not_completed',
  preferredCrew: false,
  deicerRate: '',
  contractValue: '',
  contractNotes: '',
  treeTypes: '',
  modifiedStartEndDate: new Date().toISOString(),
  canceledReason: '',
  specialInstructions: {
    callOnYourWay: false
  },
  deicerLineItem: {
    status: null,
    includedUpToPerMonth: null,
    includedUpToPerSeason: null,
    pricePerKg: null,
    includedEcoTraction: false
  },
  gravelLineItem: {
    status: null,
    includedUpToPerMonth: null,
    includedUpToPerSeason: null,
    pricePerKg: null,
  }
};

interface IWorkOrderForm {
  action?: string,
  guid?: string,
  customerId?: number,
  show?: boolean,
  drawer?: boolean,
  startEndDate?: [Date, Date] | undefined,
  defaultWorkGroup?: string | undefined
  onHide?: () => void,
  onUpdate?: (data: any) => void
}

const WorkOrderForm = ({
  guid,
  customerId,
  action,
  show,
  drawer,
  startEndDate,
  defaultWorkGroup,
  onHide,
  onUpdate
}: IWorkOrderForm) => {
  const { state } = useViewport();
  const client = useApolloClient();
  const match: any = useRouteMatch();
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const formRefs: any = {};
  const history = useHistory();
  const api = useApi();
  const params = queryString.parse(useLocation().search);
  const workGroupTypes = filterFieldsByParentKey(state.fields, 'work_groups');
  const { isRole } = usePrairieAuth();
  const { showError, showNotice } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<any>({});
  const [formValue, setFormValue] = useState<any>({});
  const [jobs, setJobs] = useState<Array<string>>([]);
  const [contractNotes, setContractNotes] = useState<string>('');
  const [contractValue, setContractValue] = useState<string>('');
  const [recommendations, setRecommendations] = useState('');
  const [applicationGroup, setApplicationGroup] = useState(defaultWorkGroup || store.get('default-work-group') || workGroupTypes[0].key);
  const [documentGuid, setDocumentGuid] = useState(null);
  const [showCustomerRequiredTooltip, setShowCustomerRequiredTooltip] = useState(false);
  const [oldCustomer, setOldCustomer] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [customerProfile, setCustomerProfile] = useState<any>({ workCustomerId: 0, billingCustomerId: 0 });
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const [workOrderBookAs, setWorkOrderBookAs] = useState<any>('single_work_order__invoice_per_work_order');
  const [document, setDocument] = useState<any>({});
  const [workOrderConflicts, setWorkOrderConflicts] = useState<any>([]);
  const [ignoreWorkOrderConflicts, setIgnoreWorkOrderConflicts] = useState(false);
  const [defaultGroup, setDefaultGroup] = useState<any>({
    ...CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === CONFIG_APPLICATION_GROUPS[0].value),
    value: workGroupTypes[0].key,
    label: workGroupTypes[0].title
  });
  const workers = state.users.filter((u: any) => u.userlevel === 'worker');
  const crews = state.users.filter((u: any) => u.userlevel === 'franchise');
  guid = match?.params.guid || params?.edit || guid;
  action = match?.params.action || action || (params?.edit && 'edit') || 'add';
  useEffect(() => {
    setWorkOrderBookAs('single_work_order__invoice_per_work_order');

    if (action && guid && ['edit', 'repeat'].includes(action)) {
      setHeaderTitle((action === 'repeat' ? 'Repeat' : 'Edit') + ' ' + INTL.WORK_ORDER);

      (async function getWorkOrder() {
        setLoading(true);
        let data: any;
        let wo: any;

        if (window.location.search.indexOf('multiple=true') > -1) {
          setWorkOrderBookAs('multiple_work_order__invoice_per_work_order');
          const results = await Promise.all((guid || '').split('_').map(async (g: any) =>
            await client.query({ query: queryWorkOrder, variables: { guid: g } })
          ));

          data = results[0];
          wo = data.data.workOrder;
          wo.children = flatten(results.map((r: any) => r.data.workOrder));
        } else {
          data = await client.query({ query: queryWorkOrder, variables: { guid } });
          wo = data.data.workOrder;
        }

        const stateForm: any = {};
        const stateJobs: any = [];
        let woChildren: any = [];
        const formGuid = (wo.children.length === 0 || action === 'repeat') ? uuidv4() : wo.guid;
        let applicationGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === wo.applicationGroup);
        if (!applicationGroup) {
          applicationGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === 'maintenance');
        }
        setDefaultGroup(applicationGroup);

        setForm({
          title: action === 'repeat' ? 'repeat' : 'edit',
          guid: formGuid,
          attachments: wo.attachments
        });
        setCustomerProfile({
          workCustomerId: wo.customerId,
          billCustomerId: wo.billingCustomerId > 0 ? wo.billingCustomerId : wo.customerId
        });
        setContractNotes(wo.contractNotes || '');
        setRecommendations(wo.children[0]?.customFields?.repairsMaterialsRecommendations || '');
        setContractValue(wo.contractValue || '');
        setApplicationGroup(wo.applicationGroup);
        setDocumentGuid(wo.documentGuid);
        woChildren = [].concat(wo.children);

        // legacy won't have children
        if (woChildren.length === 0) {
          const takedown = wo.takedown.map((t: any) => omit(t, 'children', 'attachments', 'takedown'));
          woChildren = [omit(wo, 'children', 'attachments', 'takedown')];

          // takedown is the legacy lookup
          if (takedown.length > 0) {
            woChildren = woChildren.concat({ ...orderBy(takedown, 'id', 'desc')[0], userId: '', username: '', startTime: undefined });
          }
        }

        let woDefaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === 'maintenance');
        if (CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === wo.applicationGroup)) {
          woDefaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === wo.applicationGroup);
        }
        setWorkOrderBookAs(woDefaultGroup.bookAs);

        if (action === 'repeat') {
          // repeat attachments
          await api.get(`/attachments/repeat/${guid}/${formGuid}`);
        }

        orderBy(woChildren, [
          (wo.applicationGroup === 'christmas_lights' ? 'startDate' : 'id'),
          (wo.applicationGroup === 'christmas_lights' ? 'asc' : 'desc')
        ]).forEach((child: any) => {
          const formId = nanoid();
          const newForm: any = {
            ...omit(child, 'props', 'specialInstructions', 'customFields'),
            ...child.props,
            ...setCustomFields(child?.customFields || {})
          };

          newForm.startTime = undefined;
          newForm.specialInstructions = { ...child.specialInstructions };
          newForm.servicesInVisit = typeof (newForm.servicesInVisit) === 'string' || newForm.servicesInVisit === null ? [] : newForm.servicesInVisit;

          // arrays
          const bookingTypes = filterFieldsByParentKey(state.fields, 'work_types')
            .filter((w: any) => w.visibleOnBooking).map((w: any) => w.key);

          if ((applicationGroup.accepter || CheckPicker) === CheckPicker) {
            newForm.workType = newForm?.services || [];
          }

          // if (Array.isArray(newForm.workType)) {
          // newForm.workType = (newForm.workType || []).filter((w: any) => bookingTypes.includes(w));
          // }

          if (wo.applicationGroup === 'christmas_lights') {
            newForm.jobType = newForm.jobType || [];
            newForm.preferredCrew = newForm.preferredCrew ? true : false;
          }

          newForm.daysWorking = newForm.repeatOn;
          newForm.startEndDate = [parseISO(newForm.startDate), parseISO(newForm.endDate)];
          newForm.endingDate = newForm.endingDate ? parseISO(newForm.endingDate) : undefined;

          if (!Array.isArray(newForm.daysWorking)) {
            newForm.daysWorking = [];
          }

          if (applicationGroup.fields.includes('startTime') && child.startTime) {
            newForm.startTime = parseISO(child.startTime);
          }

          if (applicationGroup.fields.includes('endTime')) {
            newForm.endTime = parseISO(newForm.endDate);
          }

          delete newForm.props;
          delete newForm.attachments;
          delete newForm.children;

          Object.keys(newForm)
            .filter((k: string) => !['startTime'].includes(k))
            .forEach((k: string) => (newForm as any)[k] = ((newForm as any)[k] === null) ? '' : (newForm as any)[k]);
          const users = [].concat(crews.filter((c: any) => (c.workGroup || []).includes(wo.applicationGroup))).concat(workers.filter((w: any) => (w.workGroup || []).includes(wo.applicationGroup))).map((u: any) => u.value);

          if (action === 'repeat') {
            let diffInYears = differenceInYears(new Date(), parseISO(newForm.startDate)) || 0;
            diffInYears = diffInYears <= 0 ? 1 : diffInYears;

            newForm.startEndDate = [addYears(parseISO(newForm.startDate), diffInYears), addYears(parseISO(newForm.endDate), diffInYears)];
            newForm.endTime = parseISO(newForm.endDate);

            if (newForm.applicationGroup === 'christmas_lights') {
              if (newForm.workType === 'takedown') {
                newForm.startEndDate = [new Date(new Date().getFullYear() + 1, 0, 1), new Date(new Date().getFullYear() + 1, 0, 1)];
              }
              newForm.totalCost = '';
              newForm.strandOwnership = '';
            }

            if (IS_BUGABOO && newForm.recurrence === 'onetime_fixed') {
              newForm.recurrence = 'onetime';
            }

            delete newForm.id;
            newForm.status = IS_KALADI_KITCHENS ? 'approved' : 'not_completed';
            newForm.guid = uuidv4();

            if (!users.includes(newForm.userId) || (newForm.applicationGroup === 'christmas_lights' && IS_ASSINIBOINE)) {
              delete newForm.userId;
              delete newForm.usersId;
            }
          }

          if (!users.includes(newForm.workerId)) {
            delete newForm.workerId;
          }

          // user_id or worker_id
          newForm.houseType = (Array.isArray(newForm.houseType) ? newForm.houseType.join(';') : (newForm.houseType || '')).split(';');
          newForm.userId = newForm.workerId || newForm.userId || null;
          stateForm[formId] = newForm;
          stateJobs.push(formId);
        });

        setFormValue(stateForm);
        setJobs(stateJobs);
        setLoading(false);
      })();
    } else if (params.document) {
      setHeaderTitle(`New ${INTL.WORK_ORDER} From Document`);

      (async function getDocument() {
        const data = await client.query({ query: queryDocument, variables: { guid: params.document } });
        const services = Array.isArray(params.service) ? params.service : [params.service];
        setDocumentGuid(data.data.document.guid);
        setApplicationGroup((params as any).applicationGroup);

        const documentJobs = data.data.document.services
          .filter((s: any) => services.includes(s.guid))
          .reduce((prev: any, next: any) => {
            const guid = uuidv4();
            prev[guid] = {
              ...defaultForm,
              workDescription: next.workDescription + ' @ ' + next.workCost,
              workType: [next.service],
              cost: next.workCost.replace(/[^\d.-]/g, ''),
              startEndDate: [parseISO(data.data.document.startDate), parseISO(data.data.document.endDate)],
              userId: data.data.document.users ? data.data.document.users[0].id : null
            };
            return prev;
          }, {});

        setFormValue(documentJobs);
        setJobs(Object.keys(documentJobs));
        setForm({
          title: 'new',
          guid: uuidv4(),
          contractNotes: '',
          contractValue: '',
          attachments: [],
          applicationGroup: params.applicationGroup
        });
        setCustomerProfile({
          billCustomerId: customerId || params.customer_id || 0,
          workCustomerId: customerId || params.customer_id || 0
        });
      })();
    } else {
      setHeaderTitle(`New ${INTL.WORK_ORDER}`);

      if (isRole(ROLE.CLIENT, ROLE.CLEANER)) {
        setApplicationGroup((state.profile?.accessGroup || [])[0]);
        resetForm((state.profile?.accessGroup || [])[0]);
      } else {
        resetForm();
      }
    }
  }, [guid, modifiedAt, startEndDate]);

  // useEffect(() => {
  //   (async function getWorkOrderCutDays() {
  //     if(Object.keys(formValue).length > 0 && applicationGroup === 'irrigation__d9d78aaa__1680553175') {
  //       const cutDays: any = await client.query({ query: queryWorkOrderCutDays, variables: { customerId: customerProfile?.workCustomerId, date: new Date() } });
        
  //       const newFormValue = {...formValue};
  //       (newFormValue[Object.keys(newFormValue)[0]] as any)!.customField_cutDays = cutDays?.data?.workOrderCutDays?.repeatOn || [];

  //       setFormValue(newFormValue);
  //     }
  //   })();
  // }, [Object.keys(formValue)[0]]);

  const resetForm = (newApplicationGroup: string | undefined = undefined) => {
    if (typeof newApplicationGroup === 'undefined') {
      newApplicationGroup = applicationGroup;
    }

    const defaultFormId = nanoid();

    let newDefaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === newApplicationGroup);
    if (!newDefaultGroup) {
      newDefaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === 'maintenance');
    }

    let defaultStartEndDate = startEndDate;

    if (IS_KALADI_KITCHENS) {
      const roundedDate = toNearest30Minutes(startEndDate ? new Date(startEndDate[0]) : new Date());
      defaultStartEndDate = [roundedDate, new Date(addMinutes(new Date(+roundedDate), 60))];
    }

    const stateForm: any = {
      [defaultFormId]: {
        ...defaultForm,
        guid: uuidv4(),
        startEndDate: defaultStartEndDate || [new Date(), new Date()],
        applicationGroup: newApplicationGroup || applicationGroup,
        workType: (newDefaultGroup.accepter || CheckPicker) === SelectPicker ? '' : []
      }
    };

    const stateJobs: any = [defaultFormId];

    setForm({
      title: 'new',
      guid: uuidv4(),
      contractNotes: '',
      contractValue: '',
      attachments: []
    });

    setCustomerProfile({
      workCustomerId: +(isRole(ROLE.CLIENT, ROLE.CLEANER) ? state?.profile.customer?.id : (customerId || params.customer_id || 0)),
      billCustomerId: +(isRole(ROLE.CLIENT, ROLE.CLEANER) ? state?.profile.customer?.id : (customerId || params.customer_id || 0)),
      userId: isRole(ROLE.CLIENT, ROLE.CLEANER) ? state?.profile?.id : undefined
    });
    setFormValue(stateForm);
    setJobs(stateJobs);
    setWorkOrderBookAs(newDefaultGroup.bookAs);
  }

  const handleCustomerIdChange = (workCustomerId: number, billCustomerId: number, userId: number | undefined) => {
    if (customerProfile?.workCustomerId !== workCustomerId || customerProfile?.billCustomerId !== billCustomerId || customerProfile?.userId !== userId) {
      setShowCustomerRequiredTooltip(false);

      if (IS_KALADI_KITCHENS && action === 'add') {
        // set the user assigned automatically to user id of the customer
        setCustomerProfile({ workCustomerId, billCustomerId, userId });
      } else {
        setCustomerProfile({ workCustomerId, billCustomerId });
      }
    }
  }

  const handleCustomerFound = (customer: any) => {
    if (!oldCustomer) {
      setOldCustomer(customer);
    }

    setCustomer(customer);
  }

  const handleAddJob = () => {
    const tmp: string[] = [].concat(jobs as any);
    const forms = { ...formValue };
    const newFormId = nanoid();
    let defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === applicationGroup);
    if (!defaultGroup) {
      defaultGroup = CONFIG_APPLICATION_GROUPS.find((g: any) => g.value === 'maintenance');
    }

    forms[newFormId] = {
      ...defaultForm,
      guid: uuidv4(),
      parentGuid: uuidv4(),
      applicationGroup: applicationGroup,
      workType: (defaultGroup.accepter || CheckPicker) === SelectPicker ? '' : []
    };

    tmp.push(newFormId);
    setJobs(tmp);
    setFormValue(forms);
  }

  const handleChangeJob = (id: string, job: string) => {
    if (['setup'].includes(job) && applicationGroup === 'christmas_lights' && jobs.length === 1) {
      const tmp: string[] = [].concat(jobs as any);
      const forms = { ...formValue };
      const newFormId = nanoid();

      forms[newFormId] = {
        ...defaultForm,
        guid: uuidv4(),
        parentGuid: uuidv4(),
        applicationGroup: applicationGroup,
        workType: 'takedown',
        startEndDate: [new Date(new Date().getFullYear() + 1, 0, 1), new Date(new Date().getFullYear() + 1, 0, 1)]
      };

      tmp.push(newFormId);
      setJobs(tmp);
      setFormValue(forms);
    }
  }

  const handleRemoveJob = (id: string) => {
    if (jobs.length === 1) {
      toaster.push(
        <Message type="error" showIcon closable>Cannot remove last item in list</Message>
      );
      return;
    }

    const newFormValue = formValue;
    const tmp: string[] = [].concat(jobs as any);
    delete newFormValue[id];
    tmp.splice(tmp.indexOf(id), 1);
    setJobs(tmp);
    setFormValue(newFormValue);
  }

  const handleDuplicateJob = (id: string) => {
    const newFormValue = { ...formValue };
    const tmp: string[] = [].concat(jobs as any);
    const newJob = { ...formValue[id] };

    const shortId = nanoid();
    newJob.guid = uuidv4();
    newJob.parentGuid = uuidv4();
    tmp.push(shortId);
    newFormValue[shortId] = newJob;
    newFormValue.startEndDate = [new Date(newFormValue.startEndDate), new Date(newFormValue.startEndDate)];

    setJobs(tmp);
    setFormValue(newFormValue);
  }

  const handleUpdateJob = (id: string, data: any) => {
    const newFormValue = { ...formValue };
    newFormValue[id] = { ...data };
    setFormValue(newFormValue);
  }

  const handleSubmit = async (stay: boolean) => {
    const upsertWorkOrder = gql`
      mutation upsertWorkOrder($input: UpsertWorkOrderInput!) {
        upsertWorkOrder(input: $input) {
          success
          code
          message
          result
          operation
        }
      }
    `;
    const validation = Object.keys(formRefs).map((key) => formRefs[key]).map((f: any) => f.check());
    const errorMessage = `Form has errors which need to be corrected`;
    const fields = filterFieldsByParentKey(state.fields, 'work_types');
    const jobFormKeys = Object.keys(formRefs);
    let leaseEndDate = new Date();

    if (IS_KALADI_KITCHENS && isRole(ROLE.CLIENT, ROLE.CLEANER) && state?.profile?.customer?.props?.customLeaseType !== 'undefined') {
      leaseEndDate = state?.profile?.customer?.props?.customLeaseType === 'end_date'
        ? parseISO(state?.profile?.customer?.props?.customLeaseEndsAt)
        : endOfMonth(addMonths(new Date(), 1));
    }

    if (!customerProfile.billCustomerId || !customerProfile.workCustomerId) {
      setShowCustomerRequiredTooltip(true);
      toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
    } else if (IS_KALADI_KITCHENS && isRole(ROLE.CLIENT, ROLE.CLEANER) && isAfter(formValue[jobFormKeys[0]].startEndDate[1], leaseEndDate)) {
      toaster.push(
        <Message type="error" showIcon closable>Cannot book a date after lease end date</Message>
      );
    } else if (validation.includes(false)) {
      toaster.push(<Message type="error" showIcon closable>{errorMessage}</Message>);
    } else {
      setSaving(true);
      const data: any = {};

      jobFormKeys.forEach((key: any) => {
        data[key] = { ...formValue[key] };
        data[key].parentGuid = form.guid;
        data[key]['applicationGroup'] = applicationGroup;
        data[key]['documentGuid'] = documentGuid;
        data[key]['customFields'] = { ...getCustomFields(data[key]), repairsMaterialsRecommendations: recommendations };

        if (!IS_KALADI_KITCHENS && !data[key]['startTime']) {
          data[key]['startEndDate'] = [
            getDefaultDate(data[key]['startEndDate'][0]),
            getDefaultDate(data[key]['startEndDate'][1])
          ];
        }

        const selectedFields = fields.filter((f: any) => data[key]['workType'].includes(f.key));
        selectedFields.forEach((s: any) => {
          if (s?.defaults && s?.defaults !== 'null') {
            (s.defaults || []).map((id: number) => {
              const def = fields.find((f: any) => +f.id === id);
              if (def) {
                data[key]['workType'].push(def.key);
              }
            });
          }
        })

        if (Array.isArray(data[key]['workType'])) {
          data[key]['workType'] = uniq(data[key]['workType']);
        }

        // convert to company timezone should be done server side eventually
        data[key]['startEndDate'] = [
          toLocalTimeZone(data[key]['startEndDate'][0], state.timeZone),
          toLocalTimeZone(data[key]['startEndDate'][1], state.timeZone)
        ];

        data[key].repeatOn = [].concat(data[key].daysWorking).filter((r: any) => r);
        data[key].preferredCrew = data[key].preferredCrew || false;
        data[key].perCostTypeCost = data[key].perCostTypeCost.toString().length > 0 ? +data[key].perCostTypeCost : null;
        data[key].visitsIncluded = data[key].visitsIncluded.toString().length > 0 ? +data[key].visitsIncluded : null;
        data[key].servicesInVisit = data[key].servicesInVisit || [];
        data[key].totalCost = data[key].totalCost.toString().length > 0 ? +data[key].totalCost : null;
        data[key].endingDate = data[key]?.endingDate || undefined;
        data[key].contractValue = data[key].contractValue.toString().length > 0 ? +data[key].contractValue : null;

        if (data[key]?.props?.hasOwnProperty('payoutPercentage')) {
          data[key].props.payoutPercentage = data[key].props.payoutPercentage.toString().length > 0 ? +data[key].props.payoutPercentage : null;
        }

        if (data[key]?.props?.hasOwnProperty('rateAboveIncluded')) {
          data[key].props.rateAboveIncluded = data[key].props.rateAboveIncluded.toString().length > 0 ? +data[key].props.rateAboveIncluded : null;
        }

        if (data[key]?.hasOwnProperty('rateAboveIncluded')) {
          data[key].rateAboveIncluded = data[key].rateAboveIncluded.toString().length > 0 ? +data[key].rateAboveIncluded : null;
        }

        if (data[key]?.hasOwnProperty('occurrence')) {
          data[key].occurrence = data[key].occurrence.toString().length > 0 ? +data[key].occurrence : null;
        }

        if (applicationGroup === 'christmas_lights') {
          data[key].recurrence = 'onetime';
        }
      });

      try {
        const response: any = await client.mutate({
          mutation: upsertWorkOrder, variables: {
            // the array is taken from the input list
            input: pick({
              ...form,
              ...customerProfile,
              attachments: formValue.attachments || form.attachments,
              workOrderBookAs: workOrderBookAs,
              children: Object.values(data).map((d: any) => {
                d.props = pick(d, ['daysWorking', 'payoutPercentage', 'rateAboveIncluded', 'numberOfStrands', 'typeOfLights', 'numberOfTrees', 'deicerRate', 'deicerLineItem', 'gravelLineItem', 'strandOwnership', 'houseType', 'treeTypes'])
                return pick(d, ['props', 'startTime', 'visitsIncluded', 'servicesInVisit', 'endingDate', 'applicationGroup', 'attachments', 'billingCustomerId', 'children', 'contractNotes', 'contractType',
                  'contractValue', 'costType', 'documentGuid', 'guid', 'jobType', 'occurrence', 'otherServices', 'parentGuid', 'perCostTypeCost', 'preferredCrew', 'props', 'rateAboveIncluded',
                  'recurrence', 'repeatOn', 'services', 'startEndDate', 'status', 'timeInstructions', 'totalCost', 'userId', 'workCustomerId', 'workDescription', 'workOrderBookAs',
                  'workType', 'workerId', 'specialInstructions', 'customerComments', 'canceledReason', 'customFields']);
              }),
              contractNotes: contractNotes,
              contractValue: contractValue ? +contractValue : null,
              applicationGroup: applicationGroup,
              documentGuid: documentGuid,
              ignoreWorkOrderConflicts
            },
              ['applicationGroup', 'visitsIncluded', 'servicesInVisit', 'endingDate', 'attachments', 'billingCustomerId', 'children', 'contractNotes', 'contractType',
                'contractValue', 'costType', 'documentGuid', 'guid', 'jobType', 'occurrence', 'otherServices', 'parentGuid', 'perCostTypeCost', 'preferredCrew',
                'props', 'rateAboveIncluded', 'recurrence', 'repeatOn', 'services', 'startEndDate', 'status', 'timeInstructions', 'totalCost', 'userId', 'workCustomerId',
                'workDescription', 'workOrderBookAs', 'workType', 'workerId', 'ignoreWorkOrderConflicts', 'customFields']
            )
          }
        });

        const notice = showNotice(response.data, 'upsertWorkOrder');

        if (notice.operation.success) {
          setSaving(false);

          if (drawer) {
            onUpdate && onUpdate({});
            onHide && onHide();
          } else if (stay) {
            history.push(`/app/${getEnv()}/work-order/edit/${response.data.upsertWorkOrder.result[0].parentGuid}`);
          } else if (!stay) {
            history.push(`/app/${getEnv()}/workbook/explorer/${customerProfile.workCustomerId}/work-orders`);
          }
        } else {
          if (window.document.getElementsByClassName('rs-drawer-body')) {
            DOMHelper.scrollTop(window.document.getElementsByClassName('rs-drawer-body')[0], 0);
          }
          setWorkOrderConflicts((response.data.upsertWorkOrder?.result?.result || []).filter((w: any) => w.overlaps));
          setSaving(false);
        }
      } catch (err) {
        showError(err);
      } finally {
        setSaving(false);
      }
    }
  }

  const handleSetRef = (index: string, ref: any) => {
    if (ref) {
      (formRefs as any)[index] = ref;
    }
  }

  const getCreatedDocuments = () => {
    const list: any = [{ value: null, label: 'Proceed Without', endDate: new Date('3999-01-01T01:01:01') }, { value: '11111111-1111-1111-1111-111111111111', label: 'Automatically Create', endDate: new Date('3998-01-01T01:01:01') }];

    if (customer?.documents) {
      customer.documents.forEach((d: any) => {
        list.push({
          value: d.guid,
          label: `${d.documentNumber ? d.documentNumber : d.id} - ${d.title || ''} ${(d.workType || '').split(';').map((workType: string) => state.services?.[workType]).join(', ')} ${(d && d.entryType) ? startCase(d.entryType) : ''} - ${d.startDate && format(parseISO(d.startDate), FORMAT.MONTH_DATE)} ${d.endDate && ' to ' + format(parseISO(d.endDate), FORMAT.MONTH_DATE)}`,
          endDate: parseISO(d.endDate ? d.endDate : d.startDate)
        });
      });
    }

    list.sort((a: any, b: any) => (typeof (a.endDate) === 'object' && typeof (b.endDate) === 'object') ? b.endDate.getTime() - a.endDate.getTime() : true);

    return list;
  }

  const getDocumentDetails = async (documentGuid: any) => {
    try {
      const result = await client.query({ query: queryDocument, variables: { guid: documentGuid } });
      setDocument(result.data.document);
    } catch (err: any) {
      setDocument({});
    }
  }

  const handleSetAttachments = (attachments: any) => {
    const nextFormValue = { ...formValue, attachments: [].concat(attachments) };
    setFormValue(nextFormValue);
  }

  const Wrapper = show === undefined
    ? <div />
    : <DrawerFormWrapper
      loading={saving}
      show={show}
      title={startCase(action) + INTL.WORK_ORDER}
      guid={guid}
      action={action || 'add'}
      form={`/app/${getEnv()}/work-order/${action}/${guid ? guid : ''}?${customerId ? `customer_id=${customerId}` : ''}`}
      onHide={onHide}
      onSave={handleSubmit}
    />;

  return cloneElement(Wrapper, {
    children: <div>
      {loading
        ? <Loader content="Loading..." />
        : <div ref={drawer ? containerRef : undefined}>

          {(!isRole(ROLE.CLIENT, ROLE.CLEANER) && !IS_KALADI_KITCHENS) &&
            <CustomerLookup
              showBreadcrumb={!drawer}
              breadcrumbTitle={`${startCase(form.title)} ${INTL.WORK_ORDER}`}
              // showAutoCompleted={['add', 'repeat'].includes(action || '')}
              billingCustomerId={customerProfile.billCustomerId}
              workingCustomerId={customerProfile.workCustomerId}
              handleCustomerIdChange={handleCustomerIdChange}
              showRequiredTooltip={showCustomerRequiredTooltip}
              handleCustomerFound={handleCustomerFound}
              showBilling={false}
            />
          }

          {(state.profile?.accessGroup || []).length === 0
            ? <Panel className="content">No work group assigned.</Panel>
            : <Panel className="content">
              <fieldset>
                {getEnv() === 'dev' &&
                  <div className="mb-12">
                    <label>UUID:</label>
                    <p>{form?.guid}</p>
                  </div>
                }

                <div>
                  {/* Work Group */}
                  <label>{INTL.WORK_GROUP}:</label>
                  {(action === 'add')
                    ? <SelectPicker
                      container={() => containerRef.current}
                      name="applicationGroup"
                      searchable={false}
                      cleanable={false}
                      block
                      value={applicationGroup}
                      onChange={(val: any) => {
                        setApplicationGroup(val);
                        resetForm(val);
                      }}
                      data={workGroupTypes}
                      labelKey="title"
                      valueKey="key"
                    />
                    : <span> {state.fields.find((s: any) => s.key === applicationGroup)?.title} - {startCase(workOrderBookAs.split('__')[1])}</span>
                  }
                </div>

                {(!isRole(ROLE.CLIENT, ROLE.CLEANER) && IS_KALADI_KITCHENS) &&
                  <CustomerLookup
                    bordered={false}
                    showTable={false}
                    showBreadcrumb={false}
                    breadcrumbTitle={`${startCase(form.title)} ${INTL.WORK_ORDER}`}
                    showAutoCompleted={action === 'add'}
                    billingCustomerId={customerProfile.billCustomerId}
                    workingCustomerId={customerProfile.workCustomerId}
                    handleCustomerIdChange={handleCustomerIdChange}
                    showRequiredTooltip={showCustomerRequiredTooltip}
                    handleCustomerFound={handleCustomerFound}
                  />
                }

                {(!isRole(ROLE.CLIENT, ROLE.CLEANER) && action !== 'edit' && !IS_KALADI_KITCHENS && !IS_KALADI_PROPERTIES) &&
                  <div className="mt-5">
                    <label>Book As:</label>
                    <div>
                      <SelectPicker
                        searchable={false}
                        cleanable={false}
                        block
                        value={workOrderBookAs}
                        onChange={setWorkOrderBookAs}
                        data={[
                          { value: 'single_work_order__invoice_per_work_order', label: 'Single Work Order - Invoice Per Work Order' },
                          { value: 'single_work_order__invoice_per_job', label: 'Single Work Order - Invoice Per Job' },
                          { value: 'multiple_work_order__invoice_per_work_order', label: 'Multiple Work Order - Invoice Per Work Order' },
                          { value: 'multiple_work_order__invoice_per_job', label: 'Multiple Work Order - Invoice Per Job' }
                        ]}
                      />
                    </div>
                    {/* <HelpBlock>
                      Default is 'Single Work Order', jobs can be created under single or multiple work orders.
                      As single work order jobs will be invoiced together, under multiple they will have separate invoices.
                    </HelpBlock> */}
                  </div>
                }

                {defaultGroup.fields.includes('documentNumber') &&
                  <div className="mt-5">
                    <label>
                      Document: <span> </span>
                      {(documentGuid && documentGuid !== '11111111-1111-1111-1111-111111111111') &&
                        <a href={`/app/${getEnv()}/document/view/${documentGuid}`} target="_blank"><LegacyExternalLinkIcon /></a>
                      }
                    </label>
                    <div>
                      <SelectPicker
                        container={() => containerRef.current}
                        name="documentGuid"
                        placeholder={'Proceed Without'}
                        block
                        value={documentGuid}
                        onChange={(val: any) => {
                          setDocumentGuid(val);
                          getDocumentDetails(val);
                        }}
                        data={getCreatedDocuments()}
                      />
                    </div>
                    <Form.HelpText>Link document to assist with pricing lookup. Linking {(INTL.DOCUMENTS || 'documents').toLowerCase()} allows for {INTL.WORK_ORDERS} to be created from document.</Form.HelpText>

                    {document?.services && <>
                      {(document?.services || []).filter((svc: any) => svc.isVisible && (svc.workDescription || '').trim().length > 0).map((svc: any) => <div key={svc.guid}>
                        {svc.status
                          ? <>
                            {svc.status === 'confirmed'
                              ? <MdCheckCircle color="#5cb85c" />
                              : <MdCancel color="#d9534f" />
                            }
                          </>
                          : <MdCircle />
                        }
                        <span> </span>
                        {svc.workDescription}
                      </div>)}
                    </>}
                  </div>
                }
              </fieldset>

              {(IS_KALADI_KITCHENS && workOrderConflicts.length > 0 && formValue?.[Object.keys(formValue)?.[0]]?.recurrence !== 'onetime') && <fieldset>
                <legend style={{ color: COLOR.DANGER }}>Conflicts Found</legend>
                {workOrderConflicts.map((w: any) => <div style={{ color: COLOR.DANGER }}>
                  {format(parseISO(w.dateEnd), FORMAT.DAY_MONTH_DATE)}: {format(parseISO(w.dateStart), 'hh:mm a')} to {format(addMinutes(parseISO(w.dateEnd), 30), 'hh:mm a')}
                </div>)}
                <p className='mt-12 text-danger'>Click <strong>Book Without Above Dates</strong> to proceed booking remaining days.</p>
                <Checkbox checked={ignoreWorkOrderConflicts} onClick={() => setIgnoreWorkOrderConflicts(!ignoreWorkOrderConflicts)} style={{ marginLeft: '-10px' }}>Book Without Above Dates</Checkbox>
              </fieldset>}

              <fieldset>
                <legend>{INTL.JOBS}</legend>
                {action === 'repeat' &&
                  <Message type="info" className="mb-10">{`This is a repeat ${INTL.WORK_ORDER.toLowerCase()}. Ensure all form values are confirmed with the customer before booking.`}</Message>
                }
                {jobs.map((j: string) =>
                  <Panel className="work-order-job" key={j} bordered style={{ marginBottom: isRole(ROLE.CLIENT, ROLE.CLEANER) ? '0' : '20px' }}>
                    {IS_KALADI_KITCHENS &&
                      <WorkOrderFieldsKaladiKitchens
                        drawer={drawer}
                        container={containerRef}
                        formIndex={j}
                        customer={state?.profile?.customer}
                        formValue={formValue[j]}
                        applicationGroup={applicationGroup}
                        onJobAdd={handleAddJob}
                        onJobRemove={handleRemoveJob}
                        onJobChange={handleChangeJob}
                        onJobDuplicate={handleDuplicateJob}
                        handleSetRef={handleSetRef}
                        onJobUpdate={handleUpdateJob}
                        workOrderBookAs={workOrderBookAs}
                      />
                    }

                    {!IS_KALADI_KITCHENS &&
                      <WorkOrderFields
                        drawer={drawer}
                        container={containerRef}
                        formIndex={j}
                        formValue={formValue[j]}
                        applicationGroup={applicationGroup}
                        onJobAdd={handleAddJob}
                        onJobRemove={handleRemoveJob}
                        onJobChange={handleChangeJob}
                        onJobDuplicate={handleDuplicateJob}
                        handleSetRef={handleSetRef}
                        onJobUpdate={handleUpdateJob}
                        workOrderBookAs={workOrderBookAs}
                      />
                    }
                  </Panel>
                )}
              </fieldset >

              {(defaultGroup.fields.includes('invoicing') && workOrderBookAs.indexOf('single_work_order') > -1) &&
                <fieldset>
                  <legend>Invoicing</legend>
                  <div className="mt-5">
                    <label>Contract Value:</label>
                    <div>
                      <InputMoney
                        value={contractValue}
                        onChange={setContractValue}
                      />
                      <Form.HelpText>Contract values are presumed monthly for duration of invoicing.
                        They will be automatically appended to all. If using contract value it is not required
                        to provide cost per visit.</Form.HelpText>
                    </div>
                  </div>
                  <div className="mt-5">
                    <label>Contract Notes:</label>
                    <div>
                      <Input as={Textarea} value={contractNotes} onChange={(e: any, val: any) => {
                        setContractNotes(val);
                      }} rows={2} />
                      <Form.HelpText>Contract notes will appear on the invoice export for office billing reference.</Form.HelpText>
                    </div>
                  </div>
                </fieldset>
              }

              {!isRole(ROLE.CLIENT, ROLE.CLEANER) &&
                <fieldset>
                  <legend>Attachments</legend>
                  <AttachmentForm
                    referenceGuid={form.guid}
                    attachments={form.attachments || []}
                    onChange={handleSetAttachments}
                    showType={filterFieldsByParentKey(state.fields, 'attachment_types').length > 0}
                  />
                </fieldset>
              }

              {IS_KALADI_KITCHENS &&
                <div className="mb-24">
                  <img src={`https://s3-us-west-2.amazonaws.com/media.prairiehq.com/kaladikitchens/floor_plans/${applicationGroup}.jpg`} />
                </div>
              }

              {!drawer &&
                <ButtonToolbar>
                  <Button appearance="primary" onClick={() => handleSubmit(false)} loading={saving}>Save</Button>
                  <Button appearance="ghost" onClick={() => handleSubmit(true)} loading={saving}>Save &amp; Stay</Button>
                </ButtonToolbar>
              }
            </Panel>
          }
        </div>
      }
    </div >
  });
};

export {
  WorkOrderForm
}