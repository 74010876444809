import { Fragment, useContext, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory, useLocation, Link } from 'react-router-dom';

import {
  ButtonToolbar,
  Button,
  Dropdown,
  IconButton,
  Whisper,
  Tooltip,
  CheckPicker
} from 'rsuite';

import LegacyPrintIcon from "@rsuite/icons/legacy/Print";
import LegacyListIcon from "@rsuite/icons/legacy/List";
import LegacyClockOIcon from "@rsuite/icons/legacy/ClockO";
import LegacyBarChartIcon from "@rsuite/icons/legacy/BarChart";
import LegacyPlusIcon from "@rsuite/icons/legacy/Plus";
import LegacyCheckSquareOIcon from "@rsuite/icons/legacy/CheckSquareO";
import LegacySquareOIcon from "@rsuite/icons/legacy/SquareO";
import LegacyExchangeIcon from "@rsuite/icons/legacy/Exchange";
import ResponsiveNav from '@rsuite/responsive-nav';
import { DRAWER, getEnv, ROLE } from 'lib/env';
import JobsList from './JobsList';
import WorkOrderList from 'containers/workorder/WorkOrderList';
import JobsSchedule from './JobsSchedule';
import { toQueryParams, useFilteredParams } from 'shared/FilterProvider';
import WorkOrderMap from 'containers/workorder/WorkOrderMap';
import intl from 'tenant/intl';
import { IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { startCase } from 'lodash';
import store from 'lib/storage';
import { useViewport } from 'shared/ViewportProvider';
import { isMobileOnly } from 'react-device-detect';
import JobsCalendar from './JobsCalendar';
import { startOfMonth, endOfMonth, endOfWeek, startOfWeek, format } from 'date-fns';
import JobsMap from './JobsMap';
import { ApplicationContext } from 'shared';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useLocalStorage } from 'lib/hooks';
import WorkOrderCompare from 'containers/workorder/WorkOrderCompare';
import { getTotalHours } from 'lib/helpers/job';
import { useApolloClient } from '@apollo/client';

const Jobs = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();
  const { params } = useFilteredParams('jobs');
  const { isRole } = usePrairieAuth();
  const { state } = useViewport();
  const { showDrawer } = useContext(ApplicationContext);
  const [activeTab, setActiveTab] = useState('');
  const calendarParams: any = useFilteredParams('jobCalendar');
  const [modifiedAt, setModifiedAt] = useState(new Date().toISOString());
  const { can } = usePrairieAuth();
  const [reports, setReports] = useLocalStorage('jobReports', []);
  const [showMyBookings, setShowMyBookings] = useLocalStorage('show-my-bookings', false);
  const [showColorCodes, setShowColorCodes] = useLocalStorage('show-color-codings', false);
  const [jobHoursTotal, setJobHoursTotal] = useState(state.jobHoursTotal);
  const colors = state.fields.filter((f: any) => f.color);

  const handleNavSelect = (val: any) => {
    switch (val) {
      case 'jobs':
        history.push(`/app/${getEnv()}/jobs/list`);
        break;
      case 'calendar':
        history.push(`/app/${getEnv()}/jobs/calendar`);
        break;
      case 'work-orders':
        history.push(`/app/${getEnv()}/jobs/work-orders/list`);
        break;
      case 'schedule':
        history.push(`/app/${getEnv()}/jobs/schedule`);
        break;
    }
  };

  useEffect(() => {
    (async function getJobHoursTotal() {
      if (IS_KALADI_KITCHENS) {
        (async function getJobsHoursBooked() {
          const start = startOfMonth(params.range[0] || new Date());
          const end = endOfMonth(params.range[0] || new Date());
          const data: any = await getTotalHours(client, start, end);
          setJobHoursTotal(data.data.jobsHoursTotal.total);
        })();
      }
    })();
  }, [params.range]);

  useEffect(() => {
    const path = window.location.pathname.replace(`/app/${getEnv()}/`, '');

    switch (path) {
      case 'jobs/list':
        setActiveTab('jobs');
        break;
      case 'jobs/schedule':
        setActiveTab('schedule');
        break;
      case 'jobs/calendar':
        setActiveTab('calendar');
        break;
      case 'jobs/work-orders/map':
      case 'jobs/work-orders/list':
      case 'jobs/work-orders':
        setActiveTab('work-orders');
        break;
    }
  }, [match]);

  return (
    <Fragment>
      <Fragment>
        {!IS_KALADI_PROPERTIES &&
          <div className='mb-8'>
            <ButtonToolbar>
              <Fragment>
                {!IS_KALADI_KITCHENS &&
                  <IconButton size='xs' icon={<LegacyPrintIcon />} as={Link} to={`/app/${getEnv()}/jobs/print${window.location.search || toQueryParams('jobs')}`} target="_blank">
                    {isMobileOnly ? 'Print' : 'Print List'}
                  </IconButton>
                }

                {(can('job:order') && !IS_KALADI_KITCHENS) &&
                  <IconButton size="xs" icon={<LegacyListIcon />} as={Link} to={`/app/${getEnv()}/workorder/order-list`} >
                    {isMobileOnly ? 'Order' : 'Order List'}
                  </IconButton>
                }


                {(!IS_KALADI_KITCHENS && can('clock-in-out:create')) &&
                  <IconButton size="xs" icon={<LegacyClockOIcon />} onClick={() => showDrawer(DRAWER.CLOCK_IN_OUT, {})} target="_blank">
                    {isMobileOnly ? 'Clock I/O' : 'Clock In/Out'}
                  </IconButton>
                }

                {!IS_KALADI_KITCHENS &&
                  <CheckPicker
                    size="xs"
                    className='button-check-picker'
                    placeholder={<IconButton className="btn-custom" size="xs" icon={<LegacyBarChartIcon />}>Report <MdOutlineKeyboardArrowDown /></IconButton>}
                    searchable={false}
                    value={reports}
                    renderValue={() => <IconButton className="btn-custom" size="xs" icon={<LegacyBarChartIcon />}>Report <MdOutlineKeyboardArrowDown /></IconButton>}
                    onChange={setReports}
                    data={[
                      { label: 'Job Completion', value: 'job-completion' },
                      { label: 'On Demand Completion', value: 'job-on-demand-completion' },
                      { label: 'Service Completion', value: 'job-service-completion' }
                    ]}
                  />
                }

                {(IS_KALADI_KITCHENS && isRole(ROLE.CLIENT, ROLE.CLEANER)) &&
                  <IconButton size="xs" icon={<LegacyPlusIcon />} onClick={() => showDrawer(DRAWER.WORK_ORDER_FORM, {}, () => setModifiedAt(new Date().toISOString()))}>
                    New
                  </IconButton>
                }

                {location.pathname.indexOf('/calendar') > -1 &&
                  <Dropdown size='xs' title={`Calendar View: ${startCase(calendarParams?.params?.calendarView || 'auto')}`}
                    onSelect={(val: any) => {
                      store.session.set(`jobCalendar-calendarView-filter`, val);

                      if (val === 'month') {
                        window.location.href = `/app/${getEnv()}/jobs/calendar?view=${val}&startDate=${startOfMonth(calendarParams.params.range[0]).toISOString()}&endDate=${endOfMonth(calendarParams.params.range[0]).toISOString()}`;
                      } else if (val === 'week') {
                        window.location.href = `/app/${getEnv()}/jobs/calendar?view=${val}&startDate=${startOfWeek(calendarParams.params.range[0]).toISOString()}&endDate=${endOfWeek(calendarParams.params.range[0]).toISOString()}`;
                      } else {
                        window.location.href = `/app/${getEnv()}/jobs/calendar?view=${val}`;
                      }
                    }}>
                    <Dropdown.Item eventKey='month'>Month</Dropdown.Item>
                    <Dropdown.Item eventKey='week'>Week</Dropdown.Item>
                    <Dropdown.Item eventKey='list'>List</Dropdown.Item>
                    <Dropdown.Item eventKey='auto'>Auto</Dropdown.Item>
                  </Dropdown>
                }

                {((!IS_KALADI_KITCHENS || !IS_KALADI_PROPERTIES) && location.pathname.indexOf('/calendar') > -1) &&
                  <>
                    {colors.length === 0
                      ? <Whisper placement="top" trigger="hover" speaker={<Tooltip>No colors set for work groups</Tooltip>}>
                        <Button size='xs' disabled><LegacySquareOIcon /> &nbsp;Show Color Codes</Button>
                      </Whisper>
                      : <Button size='xs' onClick={() => setShowColorCodes(!showColorCodes)}>
                        {showColorCodes ? <LegacyCheckSquareOIcon /> : <LegacySquareOIcon />} &nbsp;Show Color Codes
                      </Button>
                    }
                  </>
                }
              </Fragment>

              {(IS_KALADI_KITCHENS && isRole(ROLE.CLIENT, ROLE.CLEANER) && isMobileOnly) &&
                <Button size="xs" onClick={() => showDrawer(DRAWER.WORK_ORDER_FORM, {}, () => setModifiedAt(new Date().toISOString()))}>
                  <LegacyPlusIcon />
                </Button>
              }

              {(IS_KALADI_KITCHENS && isRole(ROLE.CLIENT, ROLE.CLEANER)) &&
                <Button size='xs' onClick={() => setShowMyBookings(!showMyBookings)}>
                  {showMyBookings ? <LegacyCheckSquareOIcon /> : <LegacySquareOIcon />} &nbsp;Show My Booking Only
                </Button>
              }

              {(IS_KALADI_KITCHENS && isRole(ROLE.CLIENT, ROLE.CLEANER)) &&
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>Hours Booked for {format(params.range[0] || new Date(), 'MMMM, yyyy')}</Tooltip>}>
                  <Button size="xs">
                    Leased: {(state.profile?.customer?.props?.customLeaseHours || 0) === 0 ? 'Unlimited' : state.profile?.customer?.props?.customLeaseHours}
                    {(state.profile?.customer?.props?.customLeaseExtraHours && state.profile?.customer?.props?.customLeaseExtraHours > 0) ? (isMobileOnly ? ' + ' : ' + Extra: ') + state.profile?.customer?.props?.customLeaseExtraHours : ''}
                    <span> / </span>
                    Booked: {jobHoursTotal}
                  </Button>
                </Whisper>
              }

              {(!IS_KALADI_KITCHENS && can('work-order:compare') && window.location.pathname.includes('/work-orders/') && !isMobileOnly) &&
                <IconButton size="xs" icon={<LegacyExchangeIcon />} onClick={() => showDrawer(DRAWER.WORK_ORDER_COMPARE, {}, (data: any) => {
                  history.push(`/app/${getEnv()}/jobs/work-orders/compare?compareDateColumn=${data.compareDateColumn}&compareFirstRangeStart=${data.firstRange[0].toISOString()}&compareFirstRangeEnd=${data.firstRange[1].toISOString()}&compareSecondRangeStart=${data.secondRange[0].toISOString()}&compareSecondRangeEnd=${data.secondRange[1].toISOString()}`);
                  setModifiedAt(new Date().toISOString());
                })} target="_blank">
                  Compare
                </IconButton>
              }
            </ButtonToolbar>
          </div>
        }
      </Fragment>

      {/* || !expandMenu */}
      {isMobileOnly &&
        <ResponsiveNav appearance="subtle" activeKey={activeTab} onSelect={handleNavSelect} style={{ marginTop: 6 }}>
          {(can('job:schedule') && !IS_KALADI_KITCHENS) &&
            <ResponsiveNav.Item eventKey="schedule">{intl.WORK_SCHEDULE}</ResponsiveNav.Item>
          }
          {can('job:list') &&
            <ResponsiveNav.Item eventKey="jobs">{intl.CREW_LIST}</ResponsiveNav.Item>
          }
          <ResponsiveNav.Item eventKey="calendar">{intl.WORK_CALENDAR}</ResponsiveNav.Item>
          {can('work-order:list') &&
            <ResponsiveNav.Item eventKey="work-orders">{intl.WORK_ORDERS}</ResponsiveNav.Item>
          }
        </ResponsiveNav>
      }

      <Switch>
        <Route path={match.path}>
          <Fragment>
            <Switch>
              <Route path={`${match.path}/schedule/:group?`}>
                <JobsSchedule key={modifiedAt} />
              </Route>
              <Route path={`${match.path}/work-orders/compare`}>
                <WorkOrderCompare key={modifiedAt} showBreadCrumb={false} />
              </Route>
              <Route path={`${match.path}/work-orders/list`}>
                <WorkOrderList key={modifiedAt} showBreadCrumb={false} />
              </Route>
              <Route path={`${match.path}/work-orders/map`} component={WorkOrderMap} />
              <Route path={`${match.path}/calendar`}>
                <JobsCalendar showMyBookings={showMyBookings} showColorCodes={showColorCodes} key={modifiedAt} />
              </Route>
              <Route path={`${match.path}/map`}>
                <JobsMap key={modifiedAt} />
              </Route>
              <Route>
                <JobsList reports={reports} />
              </Route>
            </Switch>
          </Fragment>
        </Route>
      </Switch>
    </Fragment>
  );
}

export default Jobs;
