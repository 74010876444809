import { CheckPicker, SelectPicker } from "rsuite";
import { TENANT } from "../lib/tenant";

const CONFIG_NOTIFICATION_DURATION = 5000;

const _APPLICATION_ROLES: any = {
  default: [
    { value: 'admin', label: 'Administrator' },
    { value: 'franchise', label: 'Crew' },
    { value: 'manager', label: 'Manager' },
    { value: 'worker', label: 'Worker' },
    { value: 'employee', label: 'Employee' }
  ],
  kaladikitchens: [
    { value: 'admin', label: 'Administrator' },
    { value: 'viewer', label: 'Health Inspector', description: 'View only role for calendar' },
    { value: 'manager', label: 'Manager' },
    { value: 'client', label: 'Client' },
    { value: 'cleaner', label: 'Cleaner' }
  ]
};

const _APPLICATION_FIEDS: any = {
  default: {
    customer: ['propertyManager'],
    document: ['customerComments']
  },
  kaladikitchens: {
    customer: [],
    document: [],
  },
  kaladiproperties: {
    customer: [],
    document: [],
  },
  assiniboine: {
    customer: ['propertyManager'],
    document: []
  }
}

const _APPLICATION_DOCUMENT_TYPES: any = {
  default: [
    { label: 'Quote', value: 'quote' },
    { label: 'Estimate', value: 'estimate' },
    { label: 'Concern', value: 'concern' },
    { label: 'Contract', value: 'contract' }
  ],
  assiniboine: [
    { label: 'Estimate', value: 'estimate' },
    { label: 'Concern', value: 'concern' },
    { label: 'Contract', value: 'contract' }
  ],
  kaladikitchens: [
    { label: 'Lease Agreement', value: 'lease_agreement_94tuhd5mcx_document' }
  ]
}


const _APPLICATION_GROUPS: any = {
  default: [
    {
      value: 'maintenance',
      label: 'Maintenance',
      accepter: CheckPicker,
      fields: ['costType', 'repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'construction',
      label: 'Construction',
      fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'roofing__59634106__1710128534',
      label: 'Roofing',
      fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'christmas_lights',
      label: 'Christmas Lights',
      accepter: SelectPicker,
      fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'startTime'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'startTime', 'numberOfStrands', 'strandOwnership', 'numberOfTrees', 'typeOfLights', 'perCostTypeCost'],
      bookAs: 'single_work_order__invoice_per_job'
    },
    {
      value: 'snow_removal',
      label: 'Snow Removal',
      accepter: CheckPicker,
      // fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'contractType'],
      fields: ['costType', 'documentNumber', 'crew', 'invoicing', 'startEndDate', 'contractType', 'timeInstructions'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    }
  ],
  assiniboine: [
    {
      value: 'maintenance',
      label: 'Maintenance',
      accepter: CheckPicker,
      fields: ['costType', 'repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'construction',
      label: 'Construction',
      fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'roofing__59634106__1710128534',
      label: 'Roofing',
      fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    }
    // {
    //   value: 'christmas_lights',
    //   label: 'Christmas Lights',
    //   accepter: SelectPicker,
    //   fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'startTime'],
    //   required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'startTime', 'numberOfStrands', 'strandOwnership', 'numberOfTrees', 'typeOfLights', 'perCostTypeCost'],
    //   bookAs: 'single_work_order__invoice_per_job'
    // },
    // {
    //   value: 'snow_removal',
    //   label: 'Snow Removal',
    //   accepter: CheckPicker,
    //   fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'contractType'],
    //   required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
    //   bookAs: 'single_work_order__invoice_per_work_order'
    // }
  ],
  bugaboo: [
    {
      value: 'maintenance',
      label: 'Maintenance',
      accepter: CheckPicker,
      fields: ['costType', 'documentNumber', 'repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'construction',
      label: 'Construction',
      fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'christmas_lights',
      label: 'Christmas Lights',
      accepter: SelectPicker,
      fields: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'startTime'],
      required: ['crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate', 'startTime'],
      bookAs: 'single_work_order__invoice_per_job'
    },
    {
      value: 'snow_removal',
      label: 'Snow Removal',
      accepter: CheckPicker,
      fields: ['costType', 'documentNumber', 'crew', 'invoicing', 'startEndDate', 'contractType'],
      required: ['crew', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'arborcare__242153b6__1644606571',
      label: 'Arbor Care',
      accepter: CheckPicker,
      fields: ['costType', 'documentNumber', 'repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    }
  ],
  kaladikitchens: [
    {
      value: 'CalgarySEKitchens',
      label: 'Calgary SE',
      accepter: SelectPicker,
      fields: ['startTime', 'endTime', 'repeatPeriod'],
      required: ['startTime', 'endTime', 'repeatPeriod'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'calgaryse1212744streetsecalgaryalbertat2z4h3__1fc63080__1699843709',
      label: 'Calgary SE',
      accepter: SelectPicker,
      fields: ['startTime', 'endTime', 'repeatPeriod'],
      required: ['startTime', 'endTime', 'repeatPeriod'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },    
    {
      value: 'CalgaryNEKitchens',
      label: 'Calgary NE',
      accepter: SelectPicker,
      fields: ['startTime', 'endTime', 'repeatPeriod'],
      required: ['startTime', 'endTime', 'repeatPeriod'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'EdmontonKitchens',
      label: 'Edmonton 1',
      accepter: SelectPicker,
      fields: ['startTime', 'endTime', 'repeatPeriod'],
      required: ['startTime', 'endTime', 'repeatPeriod'],
      bookAs: 'single_work_order__invoice_per_work_order'
    },
    {
      value: 'EdmontonKitchens2',
      label: 'Edmonton 2',
      accepter: SelectPicker,
      fields: ['startTime', 'endTime', 'repeatPeriod'],
      required: ['startTime', 'endTime', 'repeatPeriod'],
      bookAs: 'single_work_order__invoice_per_work_order'
    }
  ],
  app: [
    {
      value: 'work_order',
      label: 'Work Order',
      accepter: CheckPicker,
      fields: ['costType', 'repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      required: ['repeatPeriod', 'crew', 'documentNumber', 'timeInstructions', 'invoicing', 'startEndDate'],
      bookAs: 'single_work_order__invoice_per_work_order'
    }
  ]
};

const _STATUS: any = {
  default: [
    {
      value: 'completed',
      label: 'Completed',
      color: '#5cb85c'
    },
    {
      value: 'not_completed',
      label: 'Not Completed',
      color: '#d9534f'
    },
    {
      value: 'canceled',
      label: 'Canceled',
      color: '#f0ad4e'
    }
    // {
    //   value: 'inactive',
    //   label: 'Inactive',
    //   color: '#4d646c'
    // }
  ],
  assiniboine: [
    {
      value: 'completed',
      label: 'Completed',
      color: '#5cb85c'
    },
    {
      value: 'not_completed',
      label: 'Not Completed',
      color: '#d9534f'
    },
    {
      value: 'canceled',
      label: 'Canceled',
      color: '#f0ad4e'
    },
    {
      value: 'inactive',
      label: 'Inactive',
      color: '#4d646c'
    }
  ],
  kaladikitchens: [
    {
      value: 'approved',
      label: 'Approved',
      color: '#5cb85c'
    },
    {
      value: 'not_approved',
      label: 'Not Approved',
      color: '#d9534f'
    },
    {
      value: 'canceled',
      label: 'Canceled',
      color: '#f0ad4e'
    },
    {
      value: 'inactive',
      label: 'Inactive',
      color: '#4d646c'
    }
  ],
  app: [
    {
      value: 'completed',
      label: 'Completed',
      color: '#5cb85c'
    },
    {
      value: 'not_completed',
      label: 'Not Completed',
      color: '#d9534f'
    },
    {
      value: 'canceled',
      label: 'Canceled',
      color: '#f0ad4e'
    }
  ],
};

const _COLOR_SCHEME: any = {
  default: 'status',
  kaladikitchens: 'work_type'
}

// in minutes
const _TIME_PADDING: any = {
  default: {
    start: 0,
    end: 0
  },
  kaladikitchens: {
    start: 0,
    end: 30
  }
}


const _COST_TYPE: any = {
  default: [
    { label: 'Per Hour', value: 'per_hour' },
    { label: 'Per Visit', value: 'per_visit' },
    { label: 'Per Day', value: 'per_day' },
    { label: 'Per Week', value: 'per_week' },
    { label: 'Per Month', value: 'per_month' }
  ]
};

const CONFIG_APPLICATION_FIELDS = _APPLICATION_FIEDS[_APPLICATION_FIEDS.hasOwnProperty(TENANT) ? TENANT : 'default'];
const CONFIG_APPLICATION_GROUPS = _APPLICATION_GROUPS[_APPLICATION_GROUPS.hasOwnProperty(TENANT) ? TENANT : 'default'];
const CONFIG_STATUS = _STATUS[_STATUS.hasOwnProperty(TENANT) ? TENANT : 'default'];
const CONFIG_COLOR_SCHEME = _COLOR_SCHEME[_COLOR_SCHEME.hasOwnProperty(TENANT) ? TENANT : 'default'];
const CONFIG_TIME_PADDING = _TIME_PADDING[_TIME_PADDING.hasOwnProperty(TENANT) ? TENANT : 'default'];
const CONFIG_APPLICATION_DOCUMENT_TYPES = _APPLICATION_DOCUMENT_TYPES[_APPLICATION_DOCUMENT_TYPES.hasOwnProperty(TENANT) ? TENANT : 'default'];
const CONFIG_APPLICATION_ROLES = _APPLICATION_ROLES[_APPLICATION_ROLES.hasOwnProperty(TENANT) ? TENANT : 'default'];
const CONFIG_APPLICATION_WORK_GROUPS = CONFIG_APPLICATION_GROUPS;
const CONFIG_COST_TYPE = _COST_TYPE[_COST_TYPE.hasOwnProperty(TENANT) ? TENANT : 'default'];

export {
  CONFIG_NOTIFICATION_DURATION,
  CONFIG_APPLICATION_GROUPS,
  CONFIG_COLOR_SCHEME,
  CONFIG_STATUS,
  CONFIG_TIME_PADDING,
  CONFIG_APPLICATION_FIELDS,
  CONFIG_APPLICATION_DOCUMENT_TYPES,
  CONFIG_APPLICATION_ROLES,
  CONFIG_APPLICATION_WORK_GROUPS,
  CONFIG_COST_TYPE,
}
