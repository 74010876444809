import { Fragment } from 'react';
import { CustomerName, WorkDate, JobStatus, JobActionsDesktop as JobActions, People, WorkTimePicker } from '.';
import startCase from 'lodash/startCase';
import { SelectPicker, Table } from 'rsuite';
import { IS_KALADI_KITCHENS, IS_KALADI_PROPERTIES } from 'lib/tenant';
import { ClockInOutButton } from './ClockInOut';
import INTL from 'tenant/intl';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { ResponsiveTable } from 'shared';
import JobStatusSimple from './JobStatusSimple';

const { Column, HeaderCell, Cell } = Table;

interface IViewDesktop {
  jobs: any,
  sort: any,
  handleJobUpdate: (guid: string, data: any) => void,
  handleJobDoneUpdate: (guid: string) => void,
  handleShowDrawer: (guid: string, drawer: string) => void,
  handleJobReschedule: (guid: string, date: Date | Date[]) => void,
  onSort: (key: string) => void,
  loading: boolean,
  handleSummaryShow: (customerId: number, workOrderGuid: string) => void,
  handleClockInOutShow: (mode: string, job: any) => void,
  handleJobRefresh: (guid: string) => void,
  handleWorkOrderSelect: (guid: string | undefined, startEndDate: [Date, Date] | undefined) => void,
  tableDataRef: any,
  handleTimeEntryAdd: (jobGuid: string) => void,
  handleTimeEntryRemove: (jobGuid: string, timeCardGuid: string) => void,
  handleTimeEntryUpdate: (guid: string, data: any) => void
}

const ViewDesktop = ({
  sort,
  jobs,
  handleJobUpdate,
  handleJobDoneUpdate,
  handleShowDrawer,
  handleJobReschedule,
  onSort,
  loading,
  handleSummaryShow,
  handleClockInOutShow,
  handleWorkOrderSelect,
  handleJobRefresh,
  handleTimeEntryAdd,
  handleTimeEntryRemove,
  handleTimeEntryUpdate,
  tableDataRef
}: IViewDesktop) => {
  const { state } = useViewport();
  const filterKey: any = 'jobs' + (window.location.pathname.includes('on-demand') ? 'ondemand' : '');

  return <div className='list'>
    <ResponsiveTable
      loading={loading}
      data={jobs}
      autoHeight={true}
      wordWrap={true}
      sortColumn={sort.sortColumn}
      sortType={sort.sortType}
      onSortColumn={onSort}
      rowClassName={(data: any) => data?.guid ? (data?.status || 'not_completed') : 'undefined'}
      tableDataRef={tableDataRef}
      filterKey={filterKey}
    >
      <Column flexGrow={2} sortable>
        <HeaderCell>{INTL.CUSTOMER}</HeaderCell>
        <Cell dataKey="customerName">
          {(j: any) => <CustomerName
            job={j}
            handleSummaryShow={handleSummaryShow}
            handleShowDrawer={handleShowDrawer}
            handleWorkOrderSelect={handleWorkOrderSelect}
            handleJobRefresh={handleJobRefresh}
            handleJobUpdate={handleJobUpdate}
          />}
        </Cell>
      </Column>
      <Column width={140} sortable>
        <HeaderCell>Date</HeaderCell>
        <Cell dataKey="sortDate">
          {(j: any) => <WorkDate job={j} handleJobReschedule={handleJobReschedule} />}
        </Cell>
      </Column>
      <Column>
        <HeaderCell>{INTL.CREW}</HeaderCell>
        <Cell>
          {(j: any) => <div>
            <div style={{ padding: '5px 4px' }}>{j.operatorName}</div>
            {j.timeCard.map((t: any) =>
              <div className='mt-4' key={`user_on_site_${t.guid}`}>
                <SelectPicker
                  data={state.users}
                  size="sm"
                  value={t.userGuid}
                  cleanable={false}
                  valueKey='guid'
                  onChange={(val: any) => {
                    handleTimeEntryUpdate(t.guid, { ...t, userGuid: val });
                  }}
                />
              </div>
            )}
          </div>}
        </Cell>
      </Column>
      <Column sortable width={120}>
        <HeaderCell>Completed By</HeaderCell>
        <Cell data-column="Completed By" dataKey='completedByCrewName'>
          {(j: any) => <div style={{ padding: '5px 4px' }}>{j.completedByCrewName}</div>}
        </Cell>
      </Column>
      <Column sortable>
        <HeaderCell>Onsite</HeaderCell>
        <Cell dataKey="timeOnSite">
          {(j: any) =>
            <Fragment>
              {IS_KALADI_KITCHENS
                ? <ClockInOutButton job={j} mode="in" onClick={handleClockInOutShow} />
                : <div>
                  {['construction', 'roofing__59634106__1710128534'].indexOf(j.applicationGroup) === -1 &&
                    <div>
                      <WorkTimePicker
                        disabled={false}
                        job={j}
                        time={j.timeOnSite}
                        handleJobUpdate={handleJobUpdate}
                        column={'time_on_site'}
                      />
                      {j.timeCard.map((t: any) =>
                        <div className='mt-4' key={`time_on_site_${t.guid}`}>
                          <WorkTimePicker
                            disabled={false}
                            job={j}
                            timeCard={t}
                            time={t.timeOnSite}
                            handleJobUpdate={handleJobUpdate}
                            handleTimeEntryRemove={handleTimeEntryRemove}
                            handleTimeEntryUpdate={handleTimeEntryUpdate}
                            column={'time_on_site'}
                          />
                        </div>
                      )}
                    </div>
                  }
                </div>
              }
            </Fragment>
          }
        </Cell>
      </Column>
      <Column sortable width={130}>
        <HeaderCell>Offsite</HeaderCell>
        <Cell dataKey="timeFromSite">
          {(j: any) => <Fragment>
            {IS_KALADI_KITCHENS
              ? <ClockInOutButton job={j} mode="out" onClick={handleClockInOutShow} />
              : <>
                {['construction', 'roofing__59634106__1710128534'].indexOf(j.applicationGroup) === -1 &&
                  <div>
                    <WorkTimePicker
                      disabled={false}
                      job={j}
                      time={j.timeFromSite}
                      handleJobUpdate={handleJobUpdate}
                      column={'time_from_site'}
                      handleTimeEntryAdd={handleTimeEntryAdd}
                    />
                    {j.timeCard.map((t: any) =>
                      <div className='mt-4' key={`time_from_site_${t.guid}`}>
                        <WorkTimePicker
                          key={`time_from_site_${t.guid}`}
                          disabled={false}
                          job={j}
                          time={j.timeOnSite}
                          handleJobUpdate={handleJobUpdate}
                          handleTimeEntryRemove={handleTimeEntryRemove}
                          handleTimeEntryUpdate={handleTimeEntryUpdate}
                          column={'time_from_site'}
                          timeCard={t}
                        />
                      </div>
                    )}
                  </div>
                }
              </>
            }
          </Fragment>}
        </Cell>
      </Column>

      {!IS_KALADI_KITCHENS &&
        <Column width={90}>
          <HeaderCell>People</HeaderCell>
          <Cell>
            {(j: any) => <div>
              {['construction', 'roofing__59634106__1710128534'].indexOf(j.applicationGroup) === -1 &&
                <>
                  <People
                    jobGuid={j.guid}
                    peopleOnSite={j.peopleOnSite}
                    handleJobUpdate={handleJobUpdate}
                  />
                  {j.timeCard.map((t: any) =>
                    <div className='mt-4' key={`user_${t.guid}`}>
                      <People
                        jobGuid={j.guid}
                        peopleOnSite={t.peopleOnSite || 1}
                        handleJobUpdate={handleJobUpdate}
                        handleTimeEntryUpdate={handleTimeEntryUpdate}
                        timeCard={t}
                      />
                    </div>
                  )}
                </>
              }
            </div>}
          </Cell>
        </Column>
      }

      <Column flexGrow={1} sortable>
        <HeaderCell>Status &amp; Services</HeaderCell>
        <Cell dataKey='completedServices'>
          {(j: any) => <div>
            {IS_KALADI_PROPERTIES &&
              <JobStatusSimple
                job={j}
                applicationGroup={j.applicationGroup}
                jobGuid={j.guid}
                services={j.services}
                deIcer={j.props.de_icer}
                options={j.props}
                handleJobUpdate={handleJobUpdate}
                handleJobDoneUpdate={handleJobDoneUpdate}
                siteChecks={j.siteChecks || []}
                status={j.status}
                defaultSiteChecks={filterFieldsByParentKey(state.fields, 'site_checks')}
                completedServices={j.completedServices || []}
              />
            }

            {((IS_KALADI_KITCHENS || !['construction', 'roofing__59634106__1710128534'].includes(j.applicationGroup)) && !IS_KALADI_PROPERTIES) &&
              <JobStatus
                job={j}
                applicationGroup={j.applicationGroup}
                jobGuid={j.guid}
                services={j.services}
                deIcer={j.props.de_icer}
                options={j.props}
                handleJobUpdate={handleJobUpdate}
                handleJobDoneUpdate={handleJobDoneUpdate}
                siteChecks={j.siteChecks || []}
                status={j.status}
                defaultSiteChecks={filterFieldsByParentKey(state.fields, 'site_checks')}
                completedServices={j.completedServices || []}
              />
            }

            {['construction', 'roofing__59634106__1710128534'].includes(j.applicationGroup) &&
              <span className={j.status === 'completed' ? 'text-success' : 'text-danger'}>{startCase(j.status || 'not_completed')}</span>
            }
          </div>}
        </Cell>
      </Column>

      {!IS_KALADI_KITCHENS &&
        <Column width={140}>
          <HeaderCell>&nbsp;</HeaderCell>
          <Cell>
            {(j: any) => <JobActions job={j} handleShowDrawer={handleShowDrawer} handleJobRefresh={handleJobRefresh} />
            }
          </Cell>
        </Column>
      }
    </ResponsiveTable>
  </div>
}

export default ViewDesktop;
