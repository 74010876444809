import { useState, Fragment } from 'react';
import {
  CheckPicker,
  InputGroup,
  Input,
  toaster,
  Message,
  DatePicker,
  Whisper,
  Tooltip,
} from 'rsuite';
import { ROLE, parseNumber, FORMAT } from 'lib/env';
import { startCase, without } from 'lodash';
import { IS_ASSINIBOINE, IS_BUGABOO, IS_KALADI_KITCHENS, moneyFormatter } from 'lib/tenant';
import { CONFIG_STATUS } from 'tenant/config';
import { useViewport } from 'shared/ViewportProvider';
import { filterFieldsByParentKey } from 'lib/helpers/field';
import { usePrairieAuth } from 'contexts/AuthPrairieProvider';
import { InputNumber } from 'components/form';
import { format, parseISO } from 'date-fns';
import { useCompany } from 'lib/hooks';
import { toTimeZone } from 'lib/date';
import INTL from 'tenant/intl';
import store from 'lib/storage';

const getData = (workTypes: any, services: [string], otherServices: string, defaultSiteChecks: any, applicationGroup: string, completedServices: Array<string>) => {
  let data: any = [];

  // if (applicationGroup !== 'snow_removal') {
  //   data = data.concat([
  //     {
  //       value: 'completed',
  //       label: 'Completed',
  //       role: 'Status',
  //       group: 'completions'
  //     },
  //     {
  //       value: 'not_completed',
  //       label: 'Not Completed',
  //       role: 'Status',
  //       group: 'completions'
  //     },
  //     {
  //       value: 'canceled',
  //       label: 'Canceled',
  //       role: 'Status',
  //       group: 'completions'
  //     }
  //   ]);
  // }

  if (services.length > 0) {
    services.forEach((w: any) => {
      const service = workTypes.find((f: any) => f.key === w);

      if (service) {
        data.push({
          value: service.key,
          label: service.title,
          role: 'Services',
          group: 'services',
          disabled: !service.visibleOnJob
        })
      }
    });
  }

  if (defaultSiteChecks && applicationGroup === 'maintenance') {
    defaultSiteChecks.forEach((sc: any) => {
      data.push({
        value: sc.key,
        label: sc.title,
        role: 'Site Check List',
        group: 'site_checks'
      })
    });
  }

  return data;
}

const getCostLabel = (value: string) => {
  // switch (value) {
  //   case 'per_hour':
  //     return 'Per Hour';
  //   default:
  //     return startCase(value);
  // }
  return 'Per Visit';
}

let timeout: NodeJS.Timeout;

interface IJobStatus {
  services: string[],
  deIcer: string,
  handleJobUpdate: (guid: string, data: any) => void,
  handleJobDoneUpdate: (guid: string) => void,
  options: any,
  jobGuid: string,
  applicationGroup: string,
  defaultSiteChecks: any,
  siteChecks: any,
  status: string,
  completedServices: string[],
  job: any
};

const JobStatus = ({
  deIcer,
  handleJobUpdate,
  handleJobDoneUpdate,
  jobGuid,
  applicationGroup,
  defaultSiteChecks,
  siteChecks,
  status,
  completedServices,
  job
}: IJobStatus) => {
  const company = useCompany();
  const { isRole } = usePrairieAuth();
  const { state } = useViewport();
  const [deIcerVal, setDeIcerVal] = useState(job.props.deIcer || 0);
  const [gravelAmount, setGravelAmount] = useState(job.props?.gravelAmount || 0);
  const [gravelBins, setGravelBins] = useState(job.props?.gravelBins || 0);
  const [numberOfTrees, setNumberOfTrees] = useState(job.customFields?.numberOfTrees || 0);
  const [numberOfShurbs, setNumberOfShrubs] = useState(job.customFields?.numberOfShrubs || 0);
  const [costPerTypeCost, setCostPerTypeCost] = useState<any>(job.perCostTypeCost || job.perCostTypeCost === 0 ? job.perCostTypeCost : job.workOrderPerCostTypeCost);
  const defaultWorkTypes = filterFieldsByParentKey(state.fields, 'work_types');
  const jobsColumns = store.session.get('jobsColumns', []);

  const handleDeIcerUpdate = (val: string) => {
    setDeIcerVal(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, deIcerValue: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onGravelAmountUpdate = (val: string) => {
    setGravelAmount(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, gravelAmount: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onNumberOfTreesUpdate = (val: string) => {
    setNumberOfTrees(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, numberOfTrees: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onNumberOfShrubsUpdate = (val: string) => {
    setNumberOfShrubs(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, numberOfShrubs: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const onGravelBinsUpdate = (val: string) => {
    setGravelBins(val.replace(/[^0-9.]+/g, ''));
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, { applicationGroup, gravelBins: isNaN(parseFloat(val)) ? 0 : parseFloat(val) })
    }, 500);
  };

  const handleCostUpdate = (val: number) => {
    setCostPerTypeCost(val);
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleJobUpdate(jobGuid, {
        perCostTypeCost: val,
        group: 'maintenance_value'
      })
    }, 500);
  };

  const data = getData(defaultWorkTypes, job.services, job.otherServices, defaultSiteChecks, applicationGroup, completedServices);

  return (
    <div>
      {IS_KALADI_KITCHENS
        ? <Fragment>
          <span style={{ color: CONFIG_STATUS.find((c: any) => c.value === status)?.color }}>{startCase(status)}</span>
        </Fragment>
        : <Fragment>
          <CheckPicker
            size="sm"
            className={status || 'not_completed'}
            data={data.filter((d: any) => {
              return !d.disabled && !([status || 'not_completed'].concat(completedServices).concat(siteChecks).filter((f) => f)).includes(d.key);
            })}
            placeholder={<span style={{ color: completedServices.length > 0 ? '#5cb85c' : (status === 'canceled' ? '#f0ad4e' : '#d9534f') }}>{startCase(status || 'not_completed')}</span>}
            groupBy={'role'}
            value={[status || 'not_completed'].concat(completedServices).concat(siteChecks).filter((f) => f)}
            searchable={false}
            cleanable={false}
            block
            disabledItemValues={data.filter((d: any) => d.disabled).map((d: any) => d.value)}
            style={{ width: '100%' }}
            onClose={() => handleJobDoneUpdate(job.guid)}
            onSelect={(val: any, item: any) => {
              if (item.value === 'completed'
                && applicationGroup === 'maintenance'
                && job.costType === 'per_hour'
                && (!job.timeOnSite || !job.timeFromSite)
              ) {
                toaster.push(
                  <Message type="error" showIcon closable duration={3000}>Please provide time on site and off site in order to complete this job</Message>
                );
                return;
              }

              if (['completed', 'not_completed', 'canceled'].includes(item.value)) {
                handleJobUpdate(jobGuid, { applicationGroup, status: item.value });
                return;
              }

              if (item.group === 'services') {
                if (applicationGroup === 'snow_removal') {
                  if (IS_BUGABOO) {
                    if (completedServices.includes(item.value)) {
                      completedServices = [];
                    } else {
                      completedServices = [item.value];
                    }
                  } else {
                    if (completedServices.includes(item.value)) {
                      completedServices = without(completedServices, item.value);
                    } else {
                      completedServices.push(item.value);
                    }
                  }
                } else {
                  if ((completedServices || []).includes(item.value)) {
                    completedServices = without(completedServices, item.value);
                  } else {
                    completedServices.push(item.value);
                  }
                }
              }

              if (item.group === 'site_checks') {
                if (siteChecks.includes(item.value)) {
                  siteChecks = without(siteChecks, item.value);
                } else {
                  siteChecks.push(item.value);
                }
              }

              handleJobUpdate(jobGuid, { applicationGroup, completedServices, siteChecks });
            }}
          />

          {job.canceledReason && <div className="pl-12 mt-6 text-danger">Reason: {job.canceledReason}</div>}


          {(applicationGroup === 'snow_removal' && (+deIcerVal > 0 || job.services.includes('deicer') || job.services.includes('icescraping__a943fd24__1640738746.86085') || job.services.includes('icemelt'))) &&
            <div className="rs-input-wrap">
              <InputGroup inside style={{ marginTop: 5 }} size="sm">
                <InputGroup.Addon
                  onClick={(e: any) => e.target.parentNode.querySelector('input').focus()}
                >Deicer (kg):</InputGroup.Addon>
                <InputNumber
                  value={deIcerVal.toString()}
                  onChange={(val) => handleDeIcerUpdate(val)}
                />
              </InputGroup>
            </div>
          }

          {(IS_BUGABOO && applicationGroup === 'snow_removal' && (job.services.includes('deicer') || job.services.includes('icemelt') || job.services.includes('icescraping__a943fd24__1640738746.86085'))) &&
            <div className="rs-input-wrap">
              <InputGroup inside style={{ marginTop: 5 }} size="sm">
                <InputGroup.Addon
                  onClick={(e: any) => e.target.parentNode.querySelector('input').focus()}
                >Gravel (kg):</InputGroup.Addon>
                <InputNumber
                  value={gravelAmount.toString()}
                  onChange={(val) => onGravelAmountUpdate(val)}
                />
              </InputGroup>
            </div>
          }

          {(IS_BUGABOO && applicationGroup === 'snow_removal' && (job.services.filter((s: any) => s.indexOf('bin') > -1).length > 0)) &&
            <div className="rs-input-wrap">
              <InputGroup inside style={{ marginTop: 5 }} size="sm">
                <InputGroup.Addon
                  onClick={(e: any) => e.target.parentNode.querySelector('input').focus()}
                >Bins Filled:</InputGroup.Addon>
                <InputNumber
                  style={{ paddingLeft: '75px', width: '100%' }}
                  value={gravelBins.toString()}
                  onChange={(val) => onGravelBinsUpdate(val)}
                  onFocus={(e: any) => {
                    e.target.style.paddingLeft = '10px';
                    e.target.parentNode.querySelector('span').innerHTML = '';
                  }}
                  onBlur={(e: any) => {
                    e.target.parentNode.querySelector('span').innerHTML = 'Bins Filled:';
                    e.target.style.paddingLeft = '75px';
                  }}
                />
              </InputGroup>
            </div>
          }

          {(!['christmas_lights', 'construction', 'snow_removal', 'roofing__59634106__1710128534'].includes(applicationGroup) && !isRole(ROLE.WORKER) && job.costType) &&
            <Fragment>
              {['per_hour', 'per_visit'].includes(job.costType)
                ? <div className="rs-input-wrap">

                  <InputGroup inside style={{ marginTop: 5 }} size="sm">
                    <InputGroup.Addon
                      onClick={(e: any) => e.target.parentNode.querySelector('input').focus()}
                    >{getCostLabel(job.costType)}:</InputGroup.Addon>
                    <Input
                      defaultValue={moneyFormatter.format((costPerTypeCost || 0).toString())}
                      // onChange={(val) => handleCostUpdate(val)}
                      onFocus={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9.]+/g, '');
                        e.target.style.paddingLeft = '10px';
                        e.target.parentNode.querySelector('span').style.display = 'none';
                      }}
                      onBlur={(e: any) => {
                        handleCostUpdate(parseNumber(e.target.value));
                        e.target.value = moneyFormatter.format(parseNumber(e.target.value));
                        e.target.parentNode.querySelector('span').style.display = 'block'
                        e.target.style.paddingLeft = '0';
                      }}
                    />
                  </InputGroup>
                </div>
                : <div>
                  <div className="pt-6 pl-12">
                    {getCostLabel(job.costType)}: {moneyFormatter.format(job.workOrderPerCostTypeCost || job.workOrderTotalCost)}
                  </div>
                </div>
              }
            </Fragment>
          }

          {(IS_BUGABOO && applicationGroup === 'arborcare__242153b6__1644606571' && job.services.includes('arbor care') > -1) &&
            <>
              <div className="rs-input-wrap">
                <InputGroup inside style={{ marginTop: 5 }} size="sm">
                  <InputGroup.Addon
                    onClick={(e: any) => e.target.parentNode.querySelector('input').focus()}
                  >Trees:</InputGroup.Addon>
                  <InputNumber
                    value={numberOfTrees.toString()}
                    onFocus={(e: any) => {
                      e.target.style.paddingLeft = '10px';
                      e.target.parentNode.querySelector('span').style.display = 'none';
                    }}
                    onBlur={(e: any) => {
                      onNumberOfTreesUpdate(e.target.value);
                      e.target.parentNode.querySelector('span').style.display = 'block'
                      e.target.style.paddingLeft = '0';
                    }}
                  />
                </InputGroup>
              </div>
              <div className="rs-input-wrap">
                <InputGroup inside style={{ marginTop: 5 }} size="sm">
                  <InputGroup.Addon
                    onClick={(e: any) => e.target.parentNode.querySelector('input').focus()}
                  >Shrubs:</InputGroup.Addon>
                  <InputNumber
                    value={numberOfShurbs.toString()}
                    onFocus={(e: any) => {
                      e.target.style.paddingLeft = '10px';
                      e.target.parentNode.querySelector('span').style.display = 'none';
                    }}
                    onBlur={(e: any) => {
                      onNumberOfShrubsUpdate(e.target.value);
                      e.target.parentNode.querySelector('span').style.display = 'block'
                      e.target.style.paddingLeft = '0';
                    }}
                  />
                </InputGroup>
              </div>
            </>
          }

          {(!['christmas_lights', 'construction', 'roofing__59634106__1710128534'].includes(applicationGroup) && (state?.profile?.company?.salesPerManHourTarget || 0) > 0) &&
            <div className="mt-6 pl-12">
              {['maintenance'].includes(applicationGroup) &&
                <div>
                  Man hours:
                  <Whisper placement='top' speaker={<Tooltip>Target: {state.profile.company.salesPerManHourTarget}</Tooltip>}>
                    {job.costType === 'per_hour'
                      ? <span> {(job.workOrderTotalCost / state.profile.company.salesPerManHourTarget).toFixed(2)}</span>
                      : <span> {(job.workOrderPerCostTypeCost / state.profile.company.salesPerManHourTarget).toFixed(2)}</span>
                    }
                  </Whisper>
                </div>
              }
              <div>
                <div>Time spent (Hrs): {((job.timeCard.reduce((p: any, n: any) => p += isNaN(n.timeSpent) ? 0 : +n.timeSpent, 0) + (job.timeSpent || 0)) / 60.0).toFixed(2)}</div>
                {(job.completedServicesCount && (IS_BUGABOO || IS_ASSINIBOINE)) &&
                  <div>
                    Progress: {job.completedServicesCount.filter((service: any) => !Object.keys(service)[0].includes('site_check')).map((service: any) => state.fields.find((f: any) => f.key === Object.keys(service)[0])?.title + ' (' + Object.values(service)[0] + ')').join(', ')}
                  </div>
                }
              </div>
            </div>
          }

          {/* Track when the job was completed and if it needs changing */}
          {(!['christmas_lights', 'construction', 'roofing__59634106__1710128534'].includes(applicationGroup)
            && job.recurrence === 'onetime' && job.completedAt && job.status === 'completed') &&
            <div className="pl-12">
              <DatePicker
                size="sm"
                className='completed-date-picker'
                placement='bottomEnd'
                appearance='subtle'
                cleanable={false}
                renderValue={(date: Date) => 'Completed: ' + format(toTimeZone(date, company.timezone), FORMAT.MONTH_DATE)}
                value={parseISO(job.completedAt)}
                onOk={(date: Date) => {
                  handleJobUpdate(jobGuid, { applicationGroup, completedAt: date })
                }}
              />
            </div>
          }

          {job.notes && <div className="pl-12">{INTL.CREW} Notes: {job.notes}</div>}

          {jobsColumns.includes('Completed At') &&
            <div className="pl-12">Completed At: {job?.completedAt ? format(parseISO(job?.completedAt), FORMAT.DATE_TIME) : ''}</div>
          }
        </Fragment>
      }
    </div>
  );
};

export default JobStatus;
