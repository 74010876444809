import { gql } from "@apollo/client";

const QUERY_ATTACHMENTS = gql`
  query attachments($resourceGuid: ID!) {
    attachments(resourceGuid: $resourceGuid) {
      edges {
        node {
          guid
          fileName
          filePath
          fileId
          applicationGroup
          description
        }
      },
      totalCount
    }
  }
`;

const UPSERT_ATTACHMENTS = gql`
  mutation upsertAttachment($input: [UpsertAttachmentInput]) {
    upsertAttachment(input: $input) {
      success
      code
      message
    }
  }
`;

export {
  QUERY_ATTACHMENTS,
  UPSERT_ATTACHMENTS
}